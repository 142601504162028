import { IAccessPointSyncItem, IAccessPointSyncItemValue } from '../stores/IAccessPointSyncItem';
import { by, inject, keys } from '../../injectDecorators';
import { IAuthorizer, IAuthorizerKey } from '../../api/IAuthorizer';
import axios, { AxiosInstance } from 'axios';

export const IAccessPointSyncRepoKey = Symbol('IAccessPointSyncRepo');

export interface IAccessPointSyncRepo {
  findManyByCommunityId(communityId: string): Promise<IAccessPointSyncItem[]>;

  syncStateItem(communityId: string, gsmId: string): Promise<IAccessPointSyncItemValue>;
}

@keys(IAccessPointSyncRepoKey)
export class AccessPointSyncRepo implements IAccessPointSyncRepo {
  constructor(@inject(by(IAuthorizerKey)) private authorizer: IAuthorizer) {}

  async findManyByCommunityId(communityId: string): Promise<IAccessPointSyncItem[]> {
    const httpClient = await this.createHttpClient();
    const response = await httpClient.get(`community/${communityId}/keypad-sync`);
    return response.data;
  }

  async syncStateItem(communityId: string, id: string): Promise<IAccessPointSyncItemValue> {
    const httpClient = await this.createHttpClient();
    const response = await httpClient.get(`community/${communityId}/keypad-sync/${id}/trigger`);
    return response.data;
  }

  private async createHttpClient(): Promise<AxiosInstance> {
    return axios.create({
      baseURL: process.env.API_URL_CLUSTER,
      headers: {
        Authorization: `Bearer ${await this.authorizer.getIdToken()}`,
      },
    });
  }
}
