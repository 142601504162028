import { ICameraRpi } from './ICameraRpi';

export enum ECameraRpiEventLabel {
  PEOPLE = 'person',
  CARS = 'car',
  DELIVERY = 'delivery',
  POLICE = 'police',
  DOGS = 'dog',
  CATS = 'cat',
  PACKAGE = 'package',
}

export const cameraRpiActions = {
  [ECameraRpiEventLabel.PEOPLE]: 'People',
  [ECameraRpiEventLabel.CARS]: 'Vehicles',
  [ECameraRpiEventLabel.DELIVERY]: 'Delivery',
  [ECameraRpiEventLabel.POLICE]: 'Police',
  [ECameraRpiEventLabel.DOGS]: 'Dogs',
  [ECameraRpiEventLabel.CATS]: 'Cats',
  [ECameraRpiEventLabel.PACKAGE]: 'Package',
};

export enum ECameraRpiEventTag {
  SEEN = 'seen',
  IMPORTANT = 'important',
}

export interface ICameraRpiEvent {
  id: number;
  eventId: string;
  cameraRpi: ICameraRpi;
  label: string;
  subLabel: string;
  beforeStartTime: number;
  afterEndTime: number;
  createdAt: string;
  updatedAt: string;
  tags: {
    seen?: boolean;
    important?: boolean;
  };
  snapshot: string;
}

export interface ICameraRpiEventPaginationQuery {
  communityId: number;
  limit: number;
  offset: number;
  search: string;
  cameraIds: number[];
  labels: ECameraRpiEventLabel[];
  tags: ECameraRpiEventTag[];
  timeFrom: number;
  timeTo: number;
}

export interface ICameraRpiEventPaginationResponse {
  items: ICameraRpiEvent[];
  totalCount: number;
}

export interface ICameraRpiEventStreamResponse {
  url: string;
  beforeStartTime: number;
  afterEndTime: number;
}
