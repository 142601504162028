import React, { useEffect, useRef, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Page } from '../../../components/Page';
import { Container, Grid } from '@material-ui/core';
import { Header, HeaderButton } from '../../../components/Header';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useRouteMatch } from 'react-router';
import CameraRpiCard from './CameraRpiCard';
import CameraRpiCardActions from './CameraRpiCardActions';
import { ICameraRpi } from '../../../app/domain/ICameraRpi';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import {
  closeCameraRpiFormDialog,
  closeCameraRpiHotspotDialog,
  createCameraRpi,
  loadCamerasRpiByCommunityId,
  openCameraRpiFormDialog,
  removeCameraRpi,
  setCameraRpiForRemove,
  startCameraRpiLiveStream,
  stopCameraRpiLiveStream,
  turnOnCameraRpiHotspot,
  updateCameraRpi,
} from './camerasRpiSlice';
import CameraRpiLiveStreamDialog from './CameraRpiLiveStreamDialog';
import CameraRpiFormDialog from './CameraRpiFormDialog';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import NotAvailableFeatureMessage from '../../../components/NotAvailableFeatureMessage';
import { isAvailableForCommunity } from './is-cameras-rpi-enabled';
import { loadAccessPointByCommunityId } from '../access_point/accessPointSlice';
import CameraRpiHotspotDialog from './CameraRpiHotspotDialog';

const CamerasRpiPage = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();

  const match = useRouteMatch<{ id: string }>();
  const communityId = +match.params.id;

  const camerasRpiState = useAppSelector((state) => state.camerasRpi);
  const accessPointState = useAppSelector((state) => state.accessPointV3);

  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState<Element | null>(null);

  const isAvailableRef = useRef<boolean>(isAvailableForCommunity(communityId)); // Available for current community
  const selectedCameraRpiRef = useRef<ICameraRpi | null>(null);

  useEffect(() => {
    if (isAvailableRef.current) {
      dispatch(loadCamerasRpiByCommunityId(communityId));
      dispatch(loadAccessPointByCommunityId(communityId));
    }
    // eslint-disable-next-line
  }, [communityId]);

  const onAddHandle = () => {
    dispatch(openCameraRpiFormDialog());
  };

  const onCloseFormDialogHandle = () => {
    dispatch(closeCameraRpiFormDialog());
  };

  const onOpenCameraRpiActionMenuHandle = (currentAnchorEl: Element, currentRpiCamera: ICameraRpi) => {
    selectedCameraRpiRef.current = currentRpiCamera;
    setActionsMenuAnchorEl(currentAnchorEl);
  };

  const onCloseActionMenuHandle = () => {
    setActionsMenuAnchorEl(null);
    selectedCameraRpiRef.current = null;
  };

  const onStartLiveStreamHandle = (item: ICameraRpi) => {
    dispatch(startCameraRpiLiveStream(item));
  };

  const onStopLiveStreamHandle = () => {
    dispatch(stopCameraRpiLiveStream());
  };

  const onEditHandle = (item: ICameraRpi) => {
    dispatch(openCameraRpiFormDialog(item));
    onCloseActionMenuHandle();
  };

  const onRemoveHandle = (item: ICameraRpi) => {
    dispatch(setCameraRpiForRemove(item));
    onCloseActionMenuHandle();
  };

  const onViewCameraEventsHandle = (item: ICameraRpi) => {
    history.push(`/managements/communities/${communityId}/camera-rpi-events?cameraId=${item.id}`);
  };

  const onSubmitCameraRpiHandle = (item: Partial<ICameraRpi>) => {
    if (item.id) {
      dispatch(updateCameraRpi(communityId, item));
    } else {
      dispatch(createCameraRpi(communityId, item));
    }
  };

  const onTurnOnHotspotHandle = (cameraRpi: ICameraRpi) => {
    dispatch(turnOnCameraRpiHotspot(communityId, cameraRpi));
  };

  const onCloseHotspotDialogHandle = () => {
    dispatch(closeCameraRpiHotspotDialog());
  };

  if (!isAvailableRef.current) {
    return (
      <NotAvailableFeatureMessage
        title={'Unlock AI-Based Monitoring'}
        description={
          'Gatewise Smart Cameras provide real-time monitoring and event logging to make your community safer.'
        }
        textButton={'Book a Demo'}
        onClickButton={() => window.open('https://calendly.com/d/cp4y-5pz-8v6/gatewise-demo-smart-cameras', '_blank')}
        textInsteadButton={'Coming soon'}
      />
    );
  }

  const pageTitle = 'Camera Settings';
  return (
    <LoadingOverlay
      className={classes.loadingOverlay}
      active={camerasRpiState.camerasRpiLoading}
      spinner
      text="Loading..."
      fadeSpeed={250}
    >
      <Page title={pageTitle} className={classes.root}>
        <Container maxWidth={false}>
          <Header title={pageTitle}>
            <HeaderButton onClick={onAddHandle}>Add</HeaderButton>
          </Header>

          <Grid container spacing={3} style={{ marginTop: 30 }}>
            {camerasRpiState.camerasRpi.map((item, index) => (
              <Grid key={`${item.id}-${index}`} item xl={2} lg={4} md={6} sm={6}>
                <CameraRpiCard
                  cameraRpi={item}
                  onOpenActionMenu={onOpenCameraRpiActionMenuHandle}
                  onStartLiveStream={onStartLiveStreamHandle}
                  onViewCameraEventsHandle={onViewCameraEventsHandle}
                />
              </Grid>
            ))}
          </Grid>
          <CameraRpiCardActions
            anchorEl={actionsMenuAnchorEl}
            onClose={onCloseActionMenuHandle}
            cameraRpi={selectedCameraRpiRef.current as ICameraRpi}
            onTurnOnHotspot={onTurnOnHotspotHandle}
            onEdit={onEditHandle}
            onRemove={onRemoveHandle}
          />
          <CameraRpiLiveStreamDialog
            url={camerasRpiState.liveStreamDialog.url}
            open={camerasRpiState.liveStreamDialog.open}
            cameraRpi={camerasRpiState.liveStreamDialog.cameraRpi}
            onClose={onStopLiveStreamHandle}
          />
          {camerasRpiState.formDialog.open && (
            <CameraRpiFormDialog
              open={camerasRpiState.formDialog.open}
              onSubmit={onSubmitCameraRpiHandle}
              onClose={onCloseFormDialogHandle}
              cameraRpi={camerasRpiState.formDialog.cameraRpi}
              accessPoints={accessPointState.accessPoints}
            />
          )}
          {!!camerasRpiState?.cameraRpiForRemove?.id && (
            <ConfirmationDialog
              title={`Remove camera with id ${camerasRpiState.cameraRpiForRemove.cameraId}?`}
              message="Are you sure you want to remove this camera?"
              confirmText="Remove"
              cancelText="Cancel"
              onConfirm={() => dispatch(removeCameraRpi(communityId))}
              onCancel={() => dispatch(setCameraRpiForRemove(null))}
            />
          )}
          {camerasRpiState.hotspotDialog.open && !!camerasRpiState.hotspotDialog.cameraRpi && (
            <CameraRpiHotspotDialog
              open={camerasRpiState.hotspotDialog.open}
              onClose={onCloseHotspotDialogHandle}
              cameraRpi={camerasRpiState.hotspotDialog.cameraRpi}
              hotspotPassword={camerasRpiState.hotspotDialog.hotspotPassword}
            />
          )}
        </Container>
      </Page>
    </LoadingOverlay>
  );
};

export default CamerasRpiPage;

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    flexGrow: 1,
  },
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  barContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 0),
  },
  filter: {
    height: 48,
    padding: theme.spacing(0, 2),
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    minWidth: 100,
  },
}));
