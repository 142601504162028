import React, { FC } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import { ICameraRpi } from '../../../app/domain/ICameraRpi';

interface IProps {
  anchorEl: Element | null;
  onClose: () => void;
  cameraRpi: ICameraRpi;
  onTurnOnHotspot: (item: ICameraRpi) => void;
  onEdit: (item: ICameraRpi) => void;
  onRemove: (item: ICameraRpi) => void;
}

const CameraRpiCardActions: FC<IProps> = (props) => {
  const { anchorEl, onClose, cameraRpi, onTurnOnHotspot, onEdit, onRemove } = props;

  return (
    <Menu
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MenuItem onClick={() => onTurnOnHotspot(cameraRpi)}>
        <Typography>Turn on Wi-Fi</Typography>
      </MenuItem>
      <MenuItem onClick={() => onEdit(cameraRpi)}>
        <Typography>Edit</Typography>
      </MenuItem>
      <MenuItem onClick={() => onRemove(cameraRpi)}>
        <Typography>Remove</Typography>
      </MenuItem>
    </Menu>
  );
};

export default CameraRpiCardActions;
