import { IAccessPointSyncItem } from '../../../app/stores/IAccessPointSyncItem';
import { ProgressStatus } from '../../../components/ProgressStatus';
import { isEqual } from 'lodash';

export interface IGateSyncStateViewItem extends ProgressStatus {
  message?: string;
  gateName: string;
  id: string;
}

interface AccessPoint {
  gsm_id: string;
  name: string;
}

export class GateSyncStateViewItem implements IGateSyncStateViewItem {
  static fromAccessPointAndSyncItems(
    syncItem: IAccessPointSyncItem,
    accessPoints: AccessPoint[],
    isLoading: boolean,
  ): GateSyncStateViewItem | null {
    const ap = accessPoints.find((ap) => ap.gsm_id === syncItem.id);
    if (!ap) {
      return null;
    }

    return new GateSyncStateViewItem(syncItem, ap, isLoading);
  }

  constructor(syncStatus: IAccessPointSyncItem, ap: AccessPoint, isLoading: boolean) {
    this.id = syncStatus.id;
    this.gateName = ap.name;
    this.message = syncStatus.message;
    this.processing = isLoading;
    this.fail = !!syncStatus.message;
    this.success = !isLoading && isEqual(syncStatus.codes, syncStatus.committedCode);
  }

  gateName: string;
  id: string;
  message?: string;
  processing: boolean;
  fail: boolean;
  success: boolean;
}
