import React, { FC } from 'react';
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  CardActions,
  Button,
  makeStyles,
  Tooltip,
  withStyles,
  IconButton,
  Box,
  TooltipProps,
} from '@material-ui/core';
import { VideocamOutlined, Warning, MoreVert, PlayCircleOutline, OfflineBolt } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import { ICameraRpi } from '../../../app/domain/ICameraRpi';

const PICTURE_BLANK = '/images/camera-rpi/camera-rpi-blank-snapshot.jpeg';

interface BootstrapTooltipProps extends TooltipProps {
  backgroundColor?: string;
}

const BootstrapTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: (props: BootstrapTooltipProps) => props.backgroundColor || '#ffffff',
    boxShadow: theme.shadows[1],
    color: theme.palette.common.black,
    fontSize: '0.875rem',
    borderRadius: '0.5rem',
    maxWidth: '16rem',
    padding: '0.5rem',
    margin: '0.5rem',
  },
  // eslint-disable-next-line react/no-children-prop
}))((props: BootstrapTooltipProps) => <Tooltip {...props} children={props.children} />);

interface IProps {
  cameraRpi: ICameraRpi;
  onOpenActionMenu: (anchorEl: Element, currentItem: ICameraRpi) => void;
  onStartLiveStream: (item: ICameraRpi) => void;
  onViewCameraEventsHandle: (item: ICameraRpi) => void;
}

const CameraRpiCard: FC<IProps> = (props) => {
  const { cameraRpi, onOpenActionMenu, onStartLiveStream, onViewCameraEventsHandle } = props;

  const classes = useStyles();

  const onOpenActionMenuHandle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onOpenActionMenu(event.currentTarget, cameraRpi);
  };

  return (
    <Card className={`${classes.root} ${!cameraRpi.hasPower ? classes.rootError : ''}`}>
      <CardContent className={classes.cardContent}>
        <VideocamOutlined className={classes.headerCameraIcon} />
        <Box className={classes.headerTextWrapper}>
          <Box className={classes.headerTitleWrapper}>
            <Typography variant="h3" component="div" className={classes.headerTitle}>
              {cameraRpi.name}
            </Typography>
            {!cameraRpi.hasPower ? (
              <BootstrapTooltip title={'Power outage'} backgroundColor={'#faf0f0'} placement="top">
                <Warning className={classes.headerWarningIcon} />
              </BootstrapTooltip>
            ) : (
              <BootstrapTooltip title={'Camera is working fine'} backgroundColor={'#f6fff2'} placement="top">
                <OfflineBolt className={classes.headerPowerIcon} />
              </BootstrapTooltip>
            )}
          </Box>
          <Typography className={classes.headerDescription} component="p">
            {cameraRpi.cameraId}
          </Typography>
        </Box>
      </CardContent>
      <Box className={classes.mediaSection}>
        <Box className={classes.mediaWrapper} onClick={() => onStartLiveStream(cameraRpi)}>
          <CardMedia
            className={classes.media}
            image={
              cameraRpi.latestEventId
                ? `${process.env.REACT_APP_IMAGE_RESIZER_URL}/snapshots/${cameraRpi.cameraId}/${cameraRpi.latestEventId}?aspectRatio=16:9&width=500`
                : PICTURE_BLANK
            }
            title={cameraRpi.name}
          >
            <Box className={classes.overlay}>
              <PlayCircleOutline className={classes.overlayIcon} />
              <Typography className={classes.overlayText}>Show live recording</Typography>
            </Box>
          </CardMedia>
        </Box>
      </Box>
      <CardActions className={classes.cardActions}>
        <Button variant="contained" fullWidth color="primary" onClick={() => onViewCameraEventsHandle(cameraRpi)}>
          View camera events
        </Button>
        <IconButton className={classes.moreActionButton} onClick={onOpenActionMenuHandle}>
          <MoreVert />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default CameraRpiCard;

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '0.5rem',
  },
  rootError: {
    borderStyle: 'solid',
    borderWidth: '1.5px',
    borderColor: red[500],
  },
  cardContent: {
    padding: '1.5rem',
    display: 'flex',
    gap: '1rem',
  },
  headerCameraIcon: {
    width: '3.5rem',
    height: '3.5rem',
    padding: '0.5rem',
    borderRadius: '0.5rem',
    backgroundColor: '#f8fafc',
  },
  headerWarningIcon: {
    color: red[500],
    width: '20px',
    height: '20px',
    cursor: 'pointer',
  },
  headerPowerIcon: {
    color: '#1c9e00',
    width: '20px',
    height: '20px',
    cursor: 'pointer',
  },
  headerTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  headerTitleWrapper: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
  },
  headerTitle: {
    letterSpacing: '-0.025rem',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
  },
  headerDescription: {
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: '#6b7280',
  },
  mediaSection: {
    padding: '1.5rem',
    borderBlock: '1px solid #e5e7eb',
  },
  mediaWrapper: {
    aspectRatio: '16/9',
    overflow: 'hidden',
    borderRadius: '0.5rem',
    '&:hover $media': {
      transform: 'scale(1.1)',
    },
    '&:hover $overlay': {
      opacity: 1,
    },
  },
  media: {
    aspectRatio: '16/9',
    position: 'relative',
    backgroundSize: 'cover',
    transition: 'transform 0.3s ease-in-out',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '0.5rem',
    cursor: 'pointer',
  },
  overlayIcon: {
    color: 'white',
    height: '48px',
    width: '48px',
  },
  overlayText: {
    color: 'white',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  cardActions: {
    padding: '1.5rem',
  },
  moreActionButton: {
    width: '40px',
    height: '40px',
    borderRadius: '0.5rem',
    border: '1px solid #e5e7eb',
  },
}));
