import { Hidden, Drawer as MUIDrawer } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/styles';

export function Drawer({
  children,
  onClose,
  openMobile,
}: PropsWithChildren<{ onClose: () => void; openMobile: boolean }>): JSX.Element {
  const classes = useStyles();
  return (
    <>
      <Hidden lgUp>
        <MUIDrawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onClose}
          open={openMobile}
          variant="temporary"
        >
          {children}
        </MUIDrawer>
      </Hidden>
      <Hidden mdDown>
        <MUIDrawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer,
          }}
          open
          variant="persistent"
        >
          {children}
        </MUIDrawer>
      </Hidden>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
}));
