import React, { Suspense, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { loadCommunityDetails, resetCommunityDetails } from 'src/features/community_details/communityDetailsSlice';
import { useIntercom } from 'react-use-intercom';
import { NavBar } from './NavBar';
import TopBar from './TopBar';
import { getCurrentCommunity, getProfile } from '../../selectors';
import {
  loadAccessPointByCommunityId,
  loadGateTypesByCommunityId,
} from '../../features/access_points/accessPointsSlice';
import { useLocation, useRouteMatch } from 'react-router';
import { UsersViewType } from '../../models/UsersViewType';
import { Scope } from '../../Scope';
import { isSuperAdmin } from '../../features/user/userSlice';
import { useAppSelector } from '../../app/store';
import { getItems } from '../../features/access_points/selectors';
import { AccessPointAlertsBanner } from './AccessPointAlertsBanner';
import CamerasRpiPage from '../../features/community/cameras_rpi/CamerasRpiPage';
import CameraRpiEventsPage from '../../features/community/camera_rpi_events/CameraRpiEventsPage';
import asyncComponent from '../../AsyncComponent';
import { CommunityReminderBanner } from './CommunityReminderBanner';
import { io } from 'socket.io-client';
import { notifySuccess } from '../../features/notifications/notificationsSlice';

// Community
const Members = asyncComponent(() => import('src/features/members'));
const AccessPoints = asyncComponent(() => import('src/features/access_points/AccessPointsPage'));
const Groups = asyncComponent(() => import('src/features/groups'));
const AccessLogs = asyncComponent(() => import('src/features/access_log'));
const ActivityAnalyticPage = asyncComponent(() => import('src/features/community/dashboard/ActivityAnalyticPage'));
const Remotes = asyncComponent(() => import('src/features/community/remotes/RemotesPage'));
const KeypadsPage = asyncComponent(() => import('src/features/community/keypads/KeypadsPage'));
const ProspectsAccess = asyncComponent(() => import('src/features/prospects_access'));
const Tours = asyncComponent(() => import('src/features/tours'));
const TourManagement = asyncComponent(() => import('src/features/tours/TourManagement'));
const VendorsAccess = asyncComponent(() => import('src/features/vendors_access'));
const Invoices = asyncComponent(() => import('src/features/invoices'));
const SyncLogs = asyncComponent(() => import('src/features/sync_log'));
const CommunitySettingsPage = asyncComponent(() => import('src/features/community/settings/CommunitySettingsPage'));
const UnitLocksPage = asyncComponent(() => import('src/features/community/unit_locks/UnitLocksPage'));

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0, // IE11 fix
    },
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56,
    },
  },
}));

export function CommunityDashboard() {
  const match = useRouteMatch();
  const classes = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { boot, shutdown } = useIntercom();
  const profile = useSelector(getProfile);
  const user = useSelector((state) => state.user);
  const community = useSelector(getCurrentCommunity);
  const accessPoints = useAppSelector(getItems);
  const { id } = match.params;

  const socketRef = useRef(null);

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(`${process.env.API_V3_URL}/camera-rpi-alerts`, {
        transports: ['websocket', 'polling'],
        secure: true,
        withCredentials: true,
        forceNew: true,
        multiplex: false,
        timeout: 2000,
      });

      socketRef.current?.on('connect_error', (e) => {
        console.error(`Socket connection error: ${e.name} - ${e.message}`);
      });

      socketRef.current?.on('error', (e) => {
        console.error(`Socket error: ${e}`);
      });

      socketRef.current.on('newCameraAlert', (newAlert) => {
        if (newAlert.camera.community_id === id) {
          dispatch(
            notifySuccess(`New camera alert: ${newAlert.response} at gate ${newAlert.camera.access_point_name}`),
          );
        }
      });
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(loadAccessPointByCommunityId(id));
    const interval = setInterval(() => {
      // disable request with inactive tab
      if (!document.hidden) {
        dispatch(loadAccessPointByCommunityId(id));
      }
    }, 300000); // 5 minutes
    return () => clearInterval(interval);
  }, [id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetCommunityDetails());
    };
  }, [dispatch]);

  useEffect(() => {
    if (community && profile) {
      let memberships = '';
      profile.memberships.forEach((element, i) => {
        if (i < 5) {
          memberships = `${memberships}${element.community_id}(${element.role}) `;
        }
      });

      const member = profile.memberships.filter((i) => i.community_id === community.id)[0];
      boot({
        name: (member && member.name) || profile.email,
        actionColor: '#3047EC',
        email: profile.email,
        userId: profile.id,
        customAttributes: {
          communityId: community.id,
          communityName: community.name,
          memberships,
        },
        alignment: 'right',
        hideDefaultLauncher: false,
      });
    }
    return () => {
      shutdown();
    };
  }, [community, boot, profile, shutdown]);

  useEffect(() => {
    dispatch(loadCommunityDetails(match.params.id));
    dispatch(loadGateTypesByCommunityId(match.params.id));
  }, [dispatch, match.params.id]);

  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  if (!user.profile) {
    return <Redirect to="/auth/login" />;
  }
  if (!community) {
    return <LinearProgress />;
  }
  return (
    <>
      <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
      <NavBar
        user={user}
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
        community={community}
      />

      <div className={classes.container}>
        <div className={classes.content}>
          {!pathname.endsWith('dashboard') && <AccessPointAlertsBanner items={accessPoints} />}
          <Suspense fallback={<LinearProgress />}>
            <Switch>
              <Route path="/managements/communities/:id" exact>
                {community.show_dashboard ? (
                  <Redirect to={`/managements/communities/${id}/dashboard`} />
                ) : (
                  <Redirect to={`/managements/communities/${id}/users`} />
                )}
              </Route>
              {(community.show_dashboard || isSuperAdmin(user.profile)) && (
                <Route path="/managements/communities/:id/dashboard" exact>
                  <ActivityAnalyticPage />
                </Route>
              )}
              <Route path="/managements/communities/:id/users" exact>
                <Members key={'member_resident_1'} type={UsersViewType.Residents} />
              </Route>
              <Route path="/managements/communities/:id/flagged_users" exact>
                <Members key={'member_flagged_2'} type={UsersViewType.Flagged} />
              </Route>
              <Route path="/managements/communities/:id/staff_users" exact>
                <Members key={'member_staff_3'} type={UsersViewType.Staff} />
              </Route>
              <Route path="/managements/communities/:id/archived_users" exact>
                <Members key={'member_archived_4'} archived />
              </Route>
              <Route path="/managements/communities/:id/gates" exact>
                <AccessPoints />
              </Route>
              <Route path="/managements/communities/:id/groups" exact>
                <Groups />
              </Route>
              <Route path="/managements/communities/:id/access_log" exact>
                <AccessLogs />
              </Route>
              <Route path="/managements/communities/:id/detailed_access_log" exact>
                <AccessLogs isLogDetailed />
              </Route>
              <Route path="/managements/communities/:id/cameras-rpi" exact>
                <CamerasRpiPage />
              </Route>
              <Route path="/managements/communities/:id/camera-rpi-events" exact>
                <CameraRpiEventsPage />
              </Route>

              <Route path="/managements/communities/:id/remotes" exact>
                <Remotes />
              </Route>
              <Route path="/managements/communities/:id/keypads">
                <Scope tags={['page']}>
                  <KeypadsPage />
                </Scope>
              </Route>
              <Route path="/managements/communities/:id/unit_locks" exact>
                <UnitLocksPage />
              </Route>
              <Route path="/managements/communities/:id/prospects_access" exact>
                <ProspectsAccess />
              </Route>
              <Route path="/managements/communities/:id/tours/booked" exact>
                <Tours />
              </Route>
              <Route path="/managements/communities/:id/tours/manage/:tourId" exact>
                <TourManagement />
              </Route>
              <Route path="/managements/communities/:id/vendors_access" exact>
                <VendorsAccess />
              </Route>
              <Route path="/managements/communities/:id/billing" exact>
                <Invoices />
              </Route>
              <Route path="/managements/communities/:id/sync_log" exact>
                <SyncLogs />
              </Route>
              <Route path="/managements/communities/:id/settings" exact>
                <CommunitySettingsPage />
              </Route>
              <Route path="*">
                <Redirect to="/errors/error-404" />
              </Route>
            </Switch>
          </Suspense>

          {community.show_reminder && community.reminder_message && (
            <CommunityReminderBanner name={community.name} message={community.reminder_message} />
          )}
        </div>
      </div>
    </>
  );
}
