/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  listVendorsAccess,
  addVendorAccess as addVendorAccessAPI,
  updateVendorAccess as updateVendorAccessAPI,
  activateVendorAccess as activateVendorsAccessAPI,
  deactivateVendorAccess as deactivateVendorsAccessAPI,
  deleteVendorAccess as deleteVendorsAccessAPI,
} from '../../api/gatewise_api';
import { notifyError, notifySuccess } from '../notifications/notificationsSlice';

const initialState = {
  communityID: null,
  page: {
    items: [],
    page: 0,
    limit: 10,
    total: 0,
  },
  modalStatus: {
    loading: false,
    open: false,
  },
  search: '',
  filter: {
    status: 'all',
  },
  inProgressActions: {},
  activeVendorAccess: null,
  loading: true,
  loaded: false,
  error: null,
};

const vendorsAccessSlice = createSlice({
  name: 'vendorsAccess',
  initialState,
  reducers: {
    setCommunity: (state, action) => {
      const { page, error, search, loaded } = initialState;
      state.page = page;
      state.search = search;
      state.error = error;
      state.loaded = loaded;

      const id = action.payload;
      state.communityID = id;
    },
    setPage: (state, action) => {
      const { page, error, loaded } = action.payload;
      state.page = page;
      state.error = error;
      state.loaded = loaded;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
    updateModalStatus: (state, action) => {
      state.modalStatus = { ...state.modalStatus, ...action.payload };
    },
    setActiveVendorAccess: (state, action) => {
      state.activeVendorAccess = action.payload;
    },
    updateFilter: (state, action) => {
      state.filter = action.payload;
    },
    startVendorAccessAction: (state, action) => {
      const { remoteID, actionType } = action.payload;
      state.inProgressActions[remoteID] = actionType;
    },
    finishVendorAccessAction: (state, action) => {
      const { remoteID } = action.payload;
      delete state.inProgressActions[remoteID];
    },
  },
});

export const {
  updateSearch,
  setCommunity,
  setPage,
  setLoading,
  updateModalStatus,
  setActiveVendorAccess,
  updateFilter,
  finishVendorAccessAction,
  startVendorAccessAction,
} = vendorsAccessSlice.actions;

export default vendorsAccessSlice.reducer;

export const loadPage = (page, limit) => (dispatch, getState) => {
  const {
    communityID,
    search,
    filter: { status },
  } = getState().vendorsAccess;
  dispatch(setLoading(true));
  listVendorsAccess(communityID, search, status, page * limit, limit, search)
    .then(({ items, total }) => {
      dispatch(setLoading(false));
      dispatch(
        setPage({
          page: {
            items,
            page,
            limit,
            total,
          },
          loaded: true,
          error: null,
        }),
      );
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(setPage({ page: { items: [], limit: 10 }, error: error.toString() }));
    });
};

export const reloadWithSearch = (search) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().vendorsAccess;
  dispatch(updateSearch(search));
  dispatch(loadPage(0, limit));
};

export const reloadWithFilter = (filter) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().vendorsAccess;
  dispatch(updateFilter(filter));
  dispatch(loadPage(0, limit));
};

export const loadCommunity = (id) => (dispatch, getState) => {
  dispatch(setCommunity(id));
  const {
    page: { limit },
  } = getState().vendorsAccess;
  dispatch(loadPage(0, limit));
};

export const reload = () => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().vendorsAccess;
  dispatch(loadPage(0, limit));
};

export const addVendorAccess = (params) => (dispatch, getState) => {
  const { communityID } = getState().vendorsAccess;
  dispatch(updateModalStatus({ loading: true }));
  addVendorAccessAPI(communityID, params)
    .then(() => {
      dispatch(updateModalStatus({ success: true, loading: false, open: false }));
      dispatch(reload());
      dispatch(notifySuccess('Vendor access added'));
    })
    .catch(() => {
      dispatch(notifyError('Create Vendor access failed'));
    })
    .finally(() => {
      dispatch(updateModalStatus({ success: false, loading: false }));
    });
};

export const updateVendorAccess = (params) => (dispatch, getState) => {
  const { communityID } = getState().vendorsAccess;
  dispatch(updateModalStatus({ loading: true }));
  updateVendorAccessAPI(communityID, params)
    .then(() => {
      dispatch(updateModalStatus({ success: true, loading: false, open: false }));
      dispatch(setActiveVendorAccess(null));
      dispatch(reload());
      dispatch(notifySuccess('Vendor access updated'));
    })
    .catch(() => {
      dispatch(notifyError('Update Vendor access failed'));
    })
    .finally(() => {
      dispatch(updateModalStatus({ success: false, loading: false }));
    });
};

export const activateVendorAccess = (id) => (dispatch, getState) => {
  const { communityID } = getState().vendorsAccess;
  dispatch(startVendorAccessAction({ remoteID: id, actionType: 'activate' }));
  activateVendorsAccessAPI(communityID, id)
    .then(() => {
      dispatch(reload());
      dispatch(notifySuccess('Vendor access activated'));
    })
    .catch(() => {
      dispatch(notifyError('Vendor access activation failed'));
    })
    .finally(() => {
      dispatch(finishVendorAccessAction({ remoteID: id, actionType: 'activate' }));
    });
};

export const deactivateVendorAccess = (id) => (dispatch, getState) => {
  const { communityID } = getState().vendorsAccess;
  dispatch(startVendorAccessAction({ remoteID: id, actionType: 'deactivate' }));
  deactivateVendorsAccessAPI(communityID, id)
    .then(() => {
      dispatch(reload());
      dispatch(notifySuccess('Vendor access deactivated'));
    })
    .catch(() => {
      dispatch(notifyError('Vendor access deactivation failed'));
    })
    .finally(() => {
      dispatch(finishVendorAccessAction({ remoteID: id, actionType: 'deactivate' }));
    });
};

export const deleteVendorAccess = (communityID, id) => (dispatch) => {
  dispatch(startVendorAccessAction({ remoteID: id, actionType: 'delete' }));
  deleteVendorsAccessAPI(communityID, id)
    .then(() => {
      dispatch(reload());
      dispatch(notifySuccess('Vendor access deletion succeeded'));
    })
    .catch(() => {
      dispatch(notifyError('Vendor access deletion failed'));
    })
    .finally(() => {
      dispatch(finishVendorAccessAction({ remoteID: id, actionType: 'delete' }));
    });
};
