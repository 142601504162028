import React, { FC } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {
  title: string;
  description: string;
  textButton: string;
  onClickButton: () => void;
  textInsteadButton?: string;
}

const NotAvailableFeatureMessage: FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.notAvailableMessageContainer}>
      <Box className={classes.notAvailableSection}>
        <Typography variant={'h3'}>{props.title}</Typography>
      </Box>
      <Box className={classes.notAvailableSection}>
        <Typography variant={'body1'}>{props.description}</Typography>
      </Box>
      <Box className={classes.notAvailableSection}>
        {props.textInsteadButton ? (
          <Typography variant={'h4'}>{props.textInsteadButton}</Typography>
        ) : (
          <Button variant="contained" color="primary" onClick={props.onClickButton}>
            {props.textButton}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default NotAvailableFeatureMessage;

const useStyles = makeStyles((theme) => ({
  notAvailableMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    gap: theme.spacing(2),
  },
  notAvailableSection: {
    width: '700px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    textWrap: 'balance',
  },
}));
