import { AnySchema } from 'joi';
import { Write } from 'ts-constructor-injector';

export function isBlank<T>(value: T | undefined | null): value is null | undefined {
  return value === null || value === undefined;
}

export function isPresent<T>(value: T | undefined | null): value is T {
  return !isBlank(value);
}

export const validate =
  (schema: AnySchema) =>
  <T>([data, logs]: Write<T>): Write<T> => {
    const { value, error } = schema.validate(data);
    if (error) {
      throw new Error(`${logs.join(', ')} ${JSON.stringify(error)}`);
    }
    return [value, logs];
  };
