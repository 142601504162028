import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { isSuperAdmin } from './features/user/userSlice';
import { getProfile } from './selectors';
import { getCustomToken } from './features/user/selectors';

export function Root() {
  const profile = useSelector(getProfile);
  const usersCustomToken = useSelector(getCustomToken);

  if (profile == null) {
    return <Redirect to="/managements/communities" />;
  }
  const superAdmin = isSuperAdmin(profile);
  if (superAdmin) {
    return <Redirect to="/admin/communities" />;
  }

  const managementCompanyId = (profile as any).management_company_id;
  if (Number.isInteger(managementCompanyId) && process.env.PORTAL_V3_HOST) {
    window.location.href = `${
      process.env.PORTAL_V3_HOST
    }/management-company/${managementCompanyId}/communities?token=${encodeURIComponent(usersCustomToken)}`;

    return null;
  }

  return <Redirect to="/managements/communities" />;
}
