import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash-es';
import UpdateCard from './UpdateCard';

const useStyles = makeStyles(() => ({
  avatar: {
    height: 100,
    width: 100,
  },
  container: {
    maxWidth: '500px',
  },
}));

const Notifications = (props) => {
  const { productUpdatesViewDate } = props;
  const classes = useStyles();
  const productUpdates = useSelector((state) => state.user.productUpdates);
  const sorted = productUpdates ? orderBy(productUpdates, [(obj) => new Date(obj.release_date)], ['desc']) : [];
  return (
    <div className={classes.container}>
      {sorted.map((update) => (
        <UpdateCard key={update.id} update={update} unseen={productUpdatesViewDate < update.created_at} />
      ))}
    </div>
  );
};

export default Notifications;
