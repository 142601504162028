import React, { PropsWithChildren } from 'react';
import { AccessPoint, hasPower } from '../../app/domain/AccessPoint';
import Alert from '@material-ui/lab/Alert';

export function AccessPointAlertsBanner({ items }: PropsWithChildren<{ items: AccessPoint[] }>): JSX.Element {
  const noPowerItems = items.filter((it) => !hasPower(it));

  if (noPowerItems.length) {
    return (
      <Alert severity="error">
        The power to the Gatewise equipment is off at gates: {noPowerItems.map((it) => it.name).join(', ')}. Please have
        your maintenance team check the power and email support@gatewise.com if you need assistance.
      </Alert>
    );
  }

  return <></>;
}
