import React, { FC, useState } from 'react';
import {
  Button,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core';
import { TextField } from '../../../components/TextField';
import { ICameraRpi } from '../../../app/domain/ICameraRpi';
import { IAccessPointV3 } from '../../../app/domain/AccessPoint';
import { Close } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import InputLabel from '@material-ui/core/InputLabel';

interface IProps {
  open: boolean;
  onSubmit: (data: Partial<ICameraRpi>) => void;
  onClose: () => void;
  cameraRpi?: ICameraRpi | null;
  accessPoints?: IAccessPointV3[] | null;
}

const CameraRpiFormDialog: FC<IProps> = (props) => {
  const { open, onSubmit, onClose, cameraRpi, accessPoints } = props;
  const classes = useStyles();

  const [cameraRpiId, setCameraRpiId] = useState(cameraRpi?.cameraId || '');
  const [name, setName] = useState(cameraRpi?.name || '');
  const [isLogPeople, setIsLogPeople] = useState(cameraRpi?.isLogPeople || false);
  const [isLogVehicles, setIsLogVehicles] = useState(cameraRpi?.isLogVehicles || false);
  const [isLogCouriers, setIsLogCouriers] = useState(cameraRpi?.isLogCouriers || false);
  const [isLogDogs, setIsLogDogs] = useState(cameraRpi?.isLogDogs || false);

  const [selectedAccessPointId, setSelectedAccessPointId] = useState<number | null>(cameraRpi?.accessPointId || null);

  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open}>
      <DialogTitle className={classes.title}>{`${cameraRpi?.id ? 'Edit' : 'Add'} camera`}</DialogTitle>
      <IconButton className={classes.closeIconBtn} onClick={onClose}>
        <Close />
      </IconButton>
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
          const requestDto: Partial<ICameraRpi> = {
            ...cameraRpi,
            cameraId: cameraRpiId,
            name,
            isLogPeople,
            isLogVehicles,
            isLogCouriers,
            isLogDogs,
            accessPointId: selectedAccessPointId,
          };
          onSubmit(requestDto);
        }}
      >
        <Grid className={classes.gridContainer}>
          <Grid item md={12} xs={12}>
            <TextField
              label="Camera ID"
              required
              fullWidth
              value={cameraRpiId}
              disabled={!!cameraRpi?.cameraId}
              onChange={(value) => setCameraRpiId(value)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField label="Name" required fullWidth value={name} onChange={(value) => setName(value)} />
          </Grid>
          <Grid item md={12} xs={12}>
            <InputLabel>Access Points</InputLabel>
            <Select
              fullWidth
              name="access_point_id"
              value={selectedAccessPointId || 'none'}
              onChange={(event) => {
                const selected = event.target.value === 'none' ? null : (event.target.value as number);
                setSelectedAccessPointId(selected);
              }}
            >
              <MenuItem key={'none'} value={'none'}>
                None
              </MenuItem>
              {accessPoints?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={isLogPeople}
                  onChange={(event) => setIsLogPeople(event.target.checked)}
                  name="isLogPeops"
                  color="primary"
                />
              }
              label="Detect & log people"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={isLogVehicles}
                  onChange={(event) => setIsLogVehicles(event.target.checked)}
                  name="isLogVehicles"
                  color="primary"
                />
              }
              label="Detect & log vehicles"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={isLogCouriers}
                  onChange={(event) => setIsLogCouriers(event.target.checked)}
                  name="isLogCouriers"
                  color="primary"
                />
              }
              label="Detect & log Amazon, UPS, Fedex"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={isLogDogs}
                  onChange={(event) => setIsLogDogs(event.target.checked)}
                  name="isLogDogs"
                  color="primary"
                />
              }
              label="Detect & log dogs"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <DialogActions style={{ padding: 0 }}>
              <Button variant={'outlined'} onClick={onClose}>
                Close
              </Button>
              <Button type="submit" variant={'contained'} color="primary">{`${
                cameraRpi?.id ? 'Save changes' : 'Create'
              }`}</Button>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default CameraRpiFormDialog;

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 425,
    gap: '1rem',
  },
  title: {
    padding: '1.5rem',
    '& h2': {
      letterSpacing: '-0.025rem',
      lineHeight: '1rem',
      textTransform: 'capitalize',
      fontSize: '1.125rem',
    },
  },
  closeIconBtn: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
  form: {
    paddingInline: '1.5rem',
    paddingBottom: '1.5rem',
  },
}));
