import React, { PropsWithChildren } from 'react';
import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/ArrowDownward';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { fetchVersion, yardiVersionSelector } from './yardiSlice';

export type YardiCreds = {
  username: string;
  password: string;
  database: string;
  serverName: string;
  url: string;
  property_id: string;
};

export function YardiForm({
  username,
  password,
  serverName,
  property_id,
  database,
  url,
  onChange,
}: PropsWithChildren<
  {
    onChange: (data: Partial<YardiCreds>) => void;
  } & YardiCreds
>): JSX.Element {
  const dispatch = useAppDispatch();
  const version = useAppSelector(yardiVersionSelector);
  return (
    <>
      <Grid item md={6} xs={6}>
        <TextField
          fullWidth
          label="Username"
          onChange={({ target }) => onChange({ username: target.value })}
          value={username}
          variant="outlined"
          required
        />
      </Grid>
      <Grid item md={6} xs={6}>
        <TextField
          fullWidth
          label="Password"
          onChange={({ target }) => onChange({ password: target.value })}
          value={password}
          variant="outlined"
          required
        />
      </Grid>
      <Grid item md={6} xs={6}>
        <TextField
          fullWidth
          label="Server name"
          onChange={({ target }) => onChange({ serverName: target.value })}
          value={serverName}
          variant="outlined"
          required
        />
      </Grid>
      <Grid item md={6} xs={6}>
        <TextField
          fullWidth
          label="Database name"
          onChange={({ target }) => onChange({ database: target.value })}
          value={database}
          variant="outlined"
          required
        />
      </Grid>
      <Grid item md={6} xs={6}>
        <TextField
          fullWidth
          label="URL"
          onChange={({ target }) => onChange({ url: target.value })}
          value={url}
          variant="outlined"
          helperText={version}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton title="Get version" disabled={!url} onClick={() => dispatch(fetchVersion(url))}>
                  <DoubleArrowIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item md={6} xs={6}>
        <TextField
          fullWidth
          label="Property ID"
          onChange={({ target }) => onChange({ property_id: target.value })}
          value={property_id}
          variant="outlined"
          required
        />
      </Grid>
    </>
  );
}
