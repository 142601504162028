import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getApiKey,
  createApiKey,
  updateApiKey,
  deleteApiKey,
  getApiKeyCompany,
  createApiKeyCompany,
  updateApiKeyCompany,
  deleteApiKeyCompany,
} from '../../../api/gatewise_api';
import { AppDispatch } from '../../../app/store';
import { notifyError, notifySuccess } from '../../notifications/notificationsSlice';
import { errorToString } from '../../../utils/errors';

export interface IApiKeyMessage {
  type: 'error' | 'success';
  message: string;
}

interface ICommunityApiKeyState {
  apiKey?: string;
  error: unknown | null;
  message: IApiKeyMessage | null;
}

const initailState: ICommunityApiKeyState = {
  apiKey: '',
  error: null,
  message: null,
};

const { reducer, actions } = createSlice({
  name: 'apiKeySlice',
  initialState: initailState,
  reducers: {
    setApiKey: (state, action: PayloadAction<string>) => {
      state.apiKey = action.payload;
    },
    setError: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload;
    },
    setMessage: (state, action: PayloadAction<IApiKeyMessage>) => {
      state.message = action.payload;
    },
  },
});

const { setApiKey, setError, setMessage } = actions;

export default reducer;

export const getCommunityApiKey = (communityId: string) => async (dispatch: AppDispatch) => {
  try {
    const data = await getApiKey(communityId);
    dispatch(setApiKey(data.apiKey));
  } catch (e) {
    dispatch(setError(errorToString(e)));
    notifyError('Failed to load community details');
  }
};

export const getCompanyApiKey = (companyId: string) => async (dispatch: AppDispatch) => {
  try {
    const data = await getApiKeyCompany(companyId);
    dispatch(setApiKey(data.apiKey));
  } catch (e) {
    dispatch(setError(errorToString(e)));
    notifyError('Failed to load community details');
  }
};

export const createCommunityApiKey = (communityId: string) => async (dispatch: AppDispatch) => {
  try {
    const data = await createApiKey(communityId);
    dispatch(setApiKey(data.apiKey));
    dispatch(setMessage({ type: 'success', message: 'New API key created' }));
    notifySuccess('New API key created');
  } catch (e) {
    if (e instanceof Error) {
      if (e.message === 'Api key is manged by company') {
        dispatch(setMessage({ type: 'error', message: 'API key is managed by company' }));
        notifyError('API key is managed by company');
        return;
      }
    }
    dispatch(setError(errorToString(e)));
    dispatch(setMessage({ type: 'error', message: 'Failed to create new API key' }));
    notifyError('Failed to create new API key');
  }
};

export const createCompanyApiKey = (companyId: string) => async (dispatch: AppDispatch) => {
  try {
    const data = await createApiKeyCompany(companyId);
    dispatch(setApiKey(data.apiKey));
    dispatch(setMessage({ type: 'success', message: 'New API key created' }));
    notifySuccess('New API key created');
  } catch (e) {
    dispatch(setError(errorToString(e)));
    dispatch(setMessage({ type: 'error', message: 'Failed to create new API key' }));
    notifyError('Failed to create new API key');
  }
};

export const updateCommunityApiKey = (communityId: string) => async (dispatch: AppDispatch) => {
  try {
    const data = await updateApiKey(communityId);
    dispatch(setApiKey(data.apiKey));
    dispatch(setMessage({ type: 'success', message: 'API key updated' }));
    notifySuccess('API key updated');
  } catch (e) {
    if (e instanceof Error) {
      if (e.message === 'Api key is manged by company') {
        dispatch(setMessage({ type: 'error', message: 'API key is managed by company' }));
        notifyError('API key is managed by company');
        return;
      }
    }
    dispatch(setError(errorToString(e)));
    dispatch(setMessage({ type: 'error', message: 'Failed to update API key' }));
    notifyError('Failed to update API key');
  }
};

export const updateCompanyApiKey = (companyId: string) => async (dispatch: AppDispatch) => {
  try {
    const data = await updateApiKeyCompany(companyId);
    dispatch(setApiKey(data.apiKey));
    dispatch(setMessage({ type: 'success', message: 'API key updated' }));
    notifySuccess('API key updated');
  } catch (e) {
    dispatch(setError(errorToString(e)));
    dispatch(setMessage({ type: 'error', message: 'Failed to update API key' }));
    notifyError('Failed to update API key');
  }
};

export const deleteCommunityApiKey = (communityId: string) => async (dispatch: AppDispatch) => {
  try {
    await deleteApiKey(communityId);
    dispatch(setApiKey(''));
    dispatch(setMessage({ type: 'success', message: 'API key deleted' }));
    notifySuccess('API key deleted');
  } catch (e) {
    if (e instanceof Error) {
      if (e.message === 'Api key is manged by company') {
        dispatch(setMessage({ type: 'error', message: 'API key is managed by company' }));
        notifyError('API key is managed by company');
        return;
      }
    }
    dispatch(setError(errorToString(e)));
    dispatch(setMessage({ type: 'error', message: 'Failed to delete API key' }));
    notifyError('Failed to delete API key');
  }
};

export const deleteCompanyApiKey = (companyId: string) => async (dispatch: AppDispatch) => {
  try {
    await deleteApiKeyCompany(companyId);
    dispatch(setApiKey(''));
    dispatch(setMessage({ type: 'success', message: 'API key deleted' }));
    notifySuccess('API key deleted');
  } catch (e) {
    dispatch(setError(errorToString(e)));
    dispatch(setMessage({ type: 'error', message: 'Failed to delete API key' }));
    notifyError('Failed to delete API key');
  }
};
