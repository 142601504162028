import { IResident } from '../../../app/domain/IResident';
import { IAccessPointSyncItem } from '../../../app/stores/IAccessPointSyncItem';
import { ProgressStatus } from '../../../components/ProgressStatus';

export interface IKeypadViewItemValue {
  residentId: string;
  code: string;
}

export interface IKeypadViewItem extends IKeypadViewItemValue, ProgressStatus {
  pending?: boolean;
  fail: boolean;
  processing: boolean;
  residentName: string;
  id: string;
}

export class KeypadViewItem implements IKeypadViewItem {
  code: string;
  id: string;
  residentId: string;
  fail: boolean;
  processing: boolean;
  success: boolean;
  residentName: string;

  static fromResidentsAndSyncItems(
    resident: IResident,
    syncItems: IAccessPointSyncItem[],
    loadingSyncItems: string[],
    pending?: boolean,
  ): KeypadViewItem | null {
    return new KeypadViewItem(resident, syncItems, loadingSyncItems, pending);
  }

  constructor(
    resident: IResident,
    syncItems: IAccessPointSyncItem[],
    loadingSyncItems: string[],
    public pending?: boolean,
  ) {
    this.id = resident.id;
    this.residentId = resident.id;
    this.code = resident.keypad!;
    this.residentName = resident.name;
    this.processing = syncItems.some((s) => s.codes.includes(resident.keypad!) && loadingSyncItems.includes(s.id));
    this.fail = syncItems.some((s) => s.codes.includes(resident.keypad!) && s.message);
    this.success = syncItems
      .filter((s) => s.codes.includes(resident.keypad!))
      .every((s) => s.committedCode.includes(resident.keypad!));
  }
}
