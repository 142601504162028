import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  addCompany as apiAdd,
  listCompanies,
  updateCompany as apiUpdate,
  deleteCompany as apiDelete,
  IListCompaniesQuery,
} from '../../api/gatewise_api';
import { notifyError, notifySuccess } from '../notifications/notificationsSlice';

const userSlice = createSlice({
  name: 'companies',
  initialState: {
    page: {
      items: [],
      offset: 0,
      limit: 300,
      total: 0,
      totalItemsCount: 0,
    },
    search: null,
    modal: {
      company: null,
      open: false,
      companyImage: null,
      uploadingImage: false,
      submitting: false,
    },
    loading: true,
    error: null,
  },
  reducers: {
    setPage: (state, action) => {
      const { page, error } = action.payload;
      state.page = page;
      state.error = error;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    closeModal: (state) => {
      state.modal = { ...state.modal, open: false, company: null, companyImage: null };
    },
    openCompanyModal: (state, action) => {
      state.modal = { ...state.modal, open: true, company: action.payload };
    },
    setCompanyImage: (state, action) => {
      state.modal.companyImage = action.payload;
    },
    setUploadingImage: (state, action) => {
      state.modal.uploadingImage = action.payload;
    },
    setSubmitting: (state, action) => {
      state.modal.submitting = action.payload;
    },
  },
});

export const { setSubmitting, setUploadingImage, setCompanyImage, setPage, openCompanyModal, setLoading, closeModal } =
  userSlice.actions;

export default userSlice.reducer;

export const loadPage =
  (query: IListCompaniesQuery = { search: '', offset: 0, limit: 100 }) =>
  async (dispatch: any) => {
    const { search, offset, limit, signal, includeId } = query;
    try {
      dispatch(setLoading(true));
      const { items, total: totalItemsCount } = await listCompanies({ search, offset, limit, signal, includeId });
      dispatch(setLoading(false));
      dispatch(setPage({ page: { items, offset, limit, totalItemsCount }, error: null }));
    } catch (error) {
      if (axios.isCancel(error)) return;

      dispatch(setLoading(false));
      dispatch(
        setPage({
          page: {
            items: [],
            offset: 0,
            limit: 300,
            total: 0,
          },
          error: (error as Error).toString(),
        }),
      );
    }
  };

export const addCompany = (company: any, search?: string, offset?: number, limit?: number) => async (dispatch: any) => {
  try {
    dispatch(setSubmitting(true));
    await apiAdd(company);
    dispatch(closeModal());
    dispatch(loadPage({ search, offset, limit }));
    dispatch(notifySuccess('Company added'));
  } catch (err) {
    dispatch(notifyError('Failed to add company'));
  } finally {
    dispatch(setSubmitting(false));
  }
};

export const updateCompany =
  (company: any, search?: string, offset?: number, limit?: number) => async (dispatch: any) => {
    try {
      dispatch(setSubmitting(true));
      await apiUpdate(company.id, company);
      dispatch(closeModal());
      dispatch(loadPage({ search, offset, limit }));
      dispatch(notifySuccess('Company updated'));
    } catch (err) {
      dispatch(notifyError('Failed to update company'));
    } finally {
      dispatch(setSubmitting(false));
    }
  };

export const uploadCompanyImage =
  (file: any) =>
  async (dispatch: any, getState: any, { imageManager }: any) => {
    try {
      dispatch(setUploadingImage(true));
      const url = await imageManager.uploadImage(file);
      dispatch(setCompanyImage(url));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      dispatch(notifyError('Failed to upload image'));
    } finally {
      dispatch(setUploadingImage(false));
    }
  };

export const reloadWithSearch =
  (search?: string, offset?: number, limit?: number, signal?: AbortSignal) => (dispatch: any) => {
    dispatch(loadPage({ search, offset, limit, signal }));
  };

export const deleteCompany = (company: any) => async (dispatch: any, getState: any) => {
  const { page, search } = getState().companies;
  dispatch(setSubmitting(true));
  try {
    const action = await apiDelete(company.id);
    if (action.status === 'ok') {
      dispatch(notifySuccess('management company is deleted'));
      dispatch(loadPage({ search, offset: page.offset, limit: page.limit }));
    } else {
      const errorMessage =
        action.error ===
        'update or delete on table "management_company" violates foreign key constraint "community_management_company_id_fkey" on table "community"'
          ? 'Management company has one or more associated communities'
          : `Failed to delete management company ${company.name}`;
      dispatch(notifyError(errorMessage));
    }
  } catch (error) {
    dispatch(notifyError(`Failed to delete management company ${company.name}`));
  } finally {
    dispatch(setSubmitting(false));
  }
};
