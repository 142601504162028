import { createSlice } from '@reduxjs/toolkit';
import { getAppSettings, updateAppSettings } from '../../api/gatewise_api';
import { notifyError } from '../notifications/notificationsSlice';

const userSlice = createSlice({
  name: 'settings',
  initialState: {
    settings: {},
    loading: true,
    error: null,
  },
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setSettings, setError, setLoading } = userSlice.actions;

export default userSlice.reducer;

export const loadSettings = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const settings = await getAppSettings();
    dispatch(setSettings(settings));
  } catch (error) {
    dispatch(notifyError(error.toString()));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateSettings = (settings) => async (dispatch) => {
  try {
    await updateAppSettings(settings);
  } catch (error) {
    dispatch(notifyError(error.toString()));
  }
};
