import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  checkUploadedCameraVideo,
  fetchAccessLogReportByCommunityId,
  listAccessLog,
  startCameraVideoClip,
  triggerCreateAccessLogReport,
} from '../../api/gatewise_api';
import {
  hasNext,
  initialPagination,
  Paginable,
  Pagination,
  paginationReducers,
} from '../pagination/paginationStateHelpers';
import { AppDispatch, AppThunk, RootState } from '../../app/store';
import { notifyError, notifySuccess } from '../notifications/notificationsSlice';
import { generateCommunityAccessLogExcel } from '../global_access_log/utils';
import { AccessLogItem, AccessLogsFilter } from '../global_access_log/accessLogsSlice';
import { DAY, durationInMonth, TimeFrame } from '../../utils/time';
import { getProfile } from '../../selectors';
import { isSuperAdmin } from '../user/userSlice';

export interface IAccessLogReport {
  id: string;
  dateFrom: string;
  dateTo: string;
  createdAt: string;
}

interface AccessLogState extends Paginable {
  items: AccessLogItem[];
  filter: AccessLogsFilter;
  loaded: boolean;
  videoModal: {
    loading: boolean;
    open: boolean;
    url: string | null;
  };
  isTimeFrameOutOfLimitDialogVisible: boolean;
  reports: IAccessLogReport[];
  isReportLoading: boolean;
}

const DEFAULT_FROM = Date.now() - 30 * DAY;

const initialState: AccessLogState = {
  ...initialPagination,
  items: [],
  filter: {
    timeFrame: { from: DEFAULT_FROM },
  },
  loaded: false,
  videoModal: {
    loading: false,
    open: false,
    url: null,
  },
  isTimeFrameOutOfLimitDialogVisible: false,
  reports: [],
  isReportLoading: false,
};

const { actions, reducer } = createSlice({
  name: 'accessLog',
  initialState,
  reducers: {
    ...paginationReducers,
    setItems: (state, action: PayloadAction<AccessLogItem[]>) => {
      state.items = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loaded = !action.payload;
    },
    updateFilter: (state, action: PayloadAction<Partial<AccessLogsFilter>>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    setReportLoading: (state, action: PayloadAction<boolean>) => {
      state.isReportLoading = action.payload;
    },
    updateTimeFrame: (state, action: PayloadAction<Partial<TimeFrame>>) => {
      const timeFrame = { ...state.filter.timeFrame, ...action.payload };
      const { from = DEFAULT_FROM, to = Date.now() } = timeFrame;
      if (durationInMonth({ from, to }) > 30) {
        state.isTimeFrameOutOfLimitDialogVisible = true;
      }
      state.filter.timeFrame = timeFrame;
    },
    updateVideoModal: (state, action) => {
      state.videoModal = { ...state.videoModal, ...action.payload };
    },
    hideTimeFrameOutOfLimitDialog: (state) => {
      state.isTimeFrameOutOfLimitDialogVisible = false;
    },
    setReports: (state, action: PayloadAction<IAccessLogReport[]>) => {
      state.reports = action.payload;
    },
  },
});

export const { updateFilter, updatePagination, updateTimeFrame, hideTimeFrameOutOfLimitDialog } = actions;

export const accessLogsState = (state: RootState) => state.accessLog;
export const isLoaded = (state: RootState) => accessLogsState(state).loaded;
export const getAccessLogs = (state: RootState) => accessLogsState(state).items;
export const filter = (state: RootState) => accessLogsState(state).filter;
export const reportsSelector = (state: RootState) => accessLogsState(state).reports;
export const isReportLoadingSelector = (state: RootState) => accessLogsState(state).isReportLoading;
export const pagination = (state: RootState) => accessLogsState(state).pagination;
export const isTimeFrameOutOfLimitDialogVisibleSelector = (state: RootState) =>
  accessLogsState(state).isTimeFrameOutOfLimitDialogVisible;
export const hasNextPage = (state: RootState) => {
  const { pagination, items } = accessLogsState(state);
  return hasNext(pagination, items);
};

export default reducer;

export const loadAccessLogsByCommunityId =
  (communityId: string, pagination: Pagination, filter: AccessLogsFilter, isDetailed: boolean): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const profile = getProfile(getState());
    const isTimeRangeOutOfLimit = getState().accessLog.isTimeFrameOutOfLimitDialogVisible;
    if (isSuperAdmin(profile) && isTimeRangeOutOfLimit) {
      return;
    }
    dispatch(actions.setLoading(true));
    try {
      const { items } = await listAccessLog(
        communityId,
        pagination.current * pagination.perPage,
        pagination.perPage,
        filter.timeFrame,
        filter.search,
        isDetailed,
      );
      dispatch(actions.setItems(items));
    } catch (e) {
      dispatch(notifyError(e));
      dispatch(actions.resetPagination());
    } finally {
      dispatch(actions.setLoading(false));
    }
  };

export const loadAccessLogReportsByCommunityId =
  (communityId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(actions.setReportLoading(true));
      const data = await fetchAccessLogReportByCommunityId(communityId);
      dispatch(actions.setReports(data));
    } catch (e) {
      dispatch(notifyError(e));
    } finally {
      dispatch(actions.setReportLoading(false));
    }
  };

export const updateVideoModal = (val: { open?: boolean; loading?: boolean }) => async (dispatch: AppDispatch) => {
  dispatch(actions.updateVideoModal(val));
};

export const openVideoDialog = (communityId: string, sn: string, ts: number) => async (dispatch: AppDispatch) => {
  try {
    const result = await startCameraVideoClip(communityId, sn, ts, 'access_log');
    if (result) {
      dispatch(actions.updateVideoModal({ open: true, loading: true, url: null }));
    } else dispatch(notifyError('No Video Found!'));
  } catch (e) {
    dispatch(notifyError(e));
  }
};

export const getVideoUrl = (communityId: string, sn: string, ts: number) => async (dispatch: AppDispatch) => {
  try {
    const result = await checkUploadedCameraVideo(communityId, sn, ts);
    if (result.result) {
      dispatch(actions.updateVideoModal({ loading: false, url: result.url }));
    } else {
      dispatch(getVideoUrl(communityId, sn, ts));
    }
  } catch (e) {
    dispatch(notifyError(`Failed to get video url - ${e}`));
  }
};

export const exportToExcel =
  (communityId: string, communityName: string, items: AccessLogItem[], onDone: any) =>
  async (dispatch: AppDispatch) => {
    try {
      generateCommunityAccessLogExcel(items, communityName);
      dispatch(notifySuccess('Export completed'));
    } catch (err) {
      dispatch(notifyError(`Failed to export - ${err}`));
    } finally {
      if (onDone) {
        onDone();
      }
    }
  };

export const scheduleCreateAccessLogReport =
  (communityId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { timeFrame } = filter(getState());
      dispatch(actions.hideTimeFrameOutOfLimitDialog());
      await triggerCreateAccessLogReport(communityId, { to: Date.now(), from: DEFAULT_FROM, ...timeFrame });
      dispatch(notifySuccess('Report is scheduled'));
    } catch (e) {
      dispatch(notifyError(e));
    }
  };
