import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '../../app/store';
import { notifyError } from '../notifications/notificationsSlice';
import { listTours, listDemoApartments } from '../../api/gatewise_api';
import { IThirdPartyUnit } from '../../models/IThirdPartyUnit';
import { ITour } from '../../app/domain/ITour';

type State = {
  communityId: string | null;
  loading: boolean;
  toursLoading: boolean;
  demoApartmentsLoading: boolean;
  error: unknown | null;
  tours: ITour[];
  demoApartments: IThirdPartyUnit[];
};

const initialState: State = {
  communityId: null,
  loading: false,
  toursLoading: true,
  demoApartmentsLoading: false,
  error: null,
  tours: [],
  demoApartments: [],
};

const toursSlice = createSlice({
  name: 'tours',
  initialState,
  reducers: {
    setCommunity: (state, action) => {
      const { error } = initialState;
      state.error = error;

      state.communityId = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setToursLoading: (state, action) => {
      state.toursLoading = action.payload;
    },
    setDemoApartmentsLoading: (state, action) => {
      state.demoApartmentsLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setTours: (state, action) => {
      state.tours = action.payload;
    },
    setDemoApartments: (state, action) => {
      state.demoApartments = action.payload;
    },
  },
});

export const {
  setCommunity,
  setLoading,
  setToursLoading,
  setTours,
  setError,
  setDemoApartmentsLoading,
  setDemoApartments,
} = toursSlice.actions;

export default toursSlice.reducer;

export const loadCommunity = (id: string) => (dispatch: AppDispatch) => {
  dispatch(setCommunity(id));
};

export const loadTours = (communityId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setToursLoading(true));
    const tours = await listTours(communityId);
    dispatch(setTours(tours));
  } catch (e) {
    dispatch(setError((e as Error).message));
    notifyError('Failed to load community tours');
  } finally {
    dispatch(setToursLoading(false));
  }
};

export const loadDemoApartments = (communityId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setDemoApartmentsLoading(true));
    const demoApartments = await listDemoApartments(communityId);
    dispatch(setDemoApartments(demoApartments));
  } catch (e) {
    dispatch(setError((e as Error).message));
    notifyError('Failed to load demo apartments');
  } finally {
    dispatch(setDemoApartmentsLoading(false));
  }
};
