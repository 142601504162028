import React, { PropsWithChildren } from 'react';
import { AccessPoint, hasPower } from '../../app/domain/AccessPoint';
import Alert from '@material-ui/lab/Alert';

export function CommunityReminderBanner({
  message,
  name,
}: PropsWithChildren<{ message?: string; name: string }>): JSX.Element {
  const createMessage = (name: string, message?: string) => {
    if (!message) {
      return '';
    }
    return message.replace(/{{community_name}}/g, name);
  };
  return (
    <div style={{ position: 'relative', width: '100%', height: '64px' }}>
      <Alert
        style={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          paddingTop: '12px',
          paddingBottom: '12px',
          paddingRight: '8%',
          width: 'calc(100% - 255px)',
        }}
        // variant="filled"
        severity="error"
      >
        {createMessage(name, message)}
      </Alert>
    </div>
  );
}
