import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';

export function SidebarNavigation({ children }: PropsWithChildren<{}>): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <nav className={classes.navigation}>
        <List>{children}</List>
      </nav>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
}));
