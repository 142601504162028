/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  activateRemote as activateRemoteAPI,
  addRemote as addRemoteAPI,
  deactivateRemote as deactivateRemoteAPI,
  deleteRemote as deleteRemoteAPI,
  listGates,
  listRemotes,
} from '../../../api/gatewise_api';
import { notifyError, notifySuccess } from '../../notifications/notificationsSlice';

const initialState = {
  communityID: null,
  page: {
    items: [],
    page: 0,
    limit: 10,
    total: 0,
    active: 0,
  },
  accessPoints: [],
  modalStatus: {
    loading: false,
    open: false,
  },
  search: '',
  filter: {
    status: 'all',
    deviceStatus: 'all',
  },
  inProgressActions: {},
  loading: true,
  loaded: false,
  error: null,
};

const remotesSlice = createSlice({
  name: 'remotes',
  initialState,
  reducers: {
    setCommunity: (state, action) => {
      const { page, error, search, loaded } = initialState;
      state.page = page;
      state.search = search;
      state.error = error;
      state.loaded = loaded;

      state.communityID = action.payload;
    },
    setPage: (state, action) => {
      const { page, error, loaded } = action.payload;
      state.page = page;
      state.error = error;
      state.loaded = loaded;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
    updateModalStatus: (state, action) => {
      state.modalStatus = { ...state.modalStatus, ...action.payload };
    },
    updateFilter: (state, action) => {
      state.filter = action.payload;
    },
    startRemoteAction: (state, action) => {
      const { remoteID, actionType } = action.payload;
      state.inProgressActions[remoteID] = actionType;
    },
    finishRemoteAction: (state, action) => {
      const { remoteID } = action.payload;
      delete state.inProgressActions[remoteID];
    },
    setAccessPoints: (state, action) => {
      state.accessPoints = action.payload;
    },
  },
});

export const {
  updateSearch,
  setCommunity,
  setPage,
  setLoading,
  updateModalStatus,
  updateFilter,
  finishRemoteAction,
  startRemoteAction,
  setAccessPoints,
} = remotesSlice.actions;

export default remotesSlice.reducer;

export const loadPage = (page, limit) => (dispatch, getState) => {
  const {
    communityID,
    search,
    filter: { status, deviceStatus },
  } = getState().remotes;
  dispatch(setLoading(true));
  listRemotes(communityID, search, status, deviceStatus, page * limit, limit, search)
    .then(({ items, total, active }) => {
      items.sort((a, b) => parseInt(a.serial_number) - parseInt(b.serial_number));
      dispatch(setLoading(false));
      dispatch(
        setPage({
          page: {
            items,
            page,
            limit,
            total,
            active,
          },
          loaded: true,
          error: null,
        }),
      );
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(notifyError(error));
      dispatch(setPage({ page: { items: [], limit: 10 }, error: error.toString() }));
    });
};

export const reloadWithSearch = (search) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().remotes;
  dispatch(updateSearch(search));
  dispatch(loadPage(0, limit));
};

export const reloadWithFilter = (filter) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().remotes;
  dispatch(updateFilter(filter));
  dispatch(loadPage(0, limit));
};

export const loadCommunity = (id) => (dispatch, getState) => {
  dispatch(setCommunity(id));
  const {
    page: { limit },
  } = getState().remotes;
  dispatch(loadPage(0, limit));
};

export const reload = () => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().remotes;
  dispatch(loadPage(0, limit));
};

export const addRemote = (serial) => (dispatch, getState) => {
  const { communityID } = getState().remotes;
  dispatch(updateModalStatus({ loading: true }));
  addRemoteAPI(communityID, serial)
    .then(() => {
      dispatch(updateModalStatus({ success: true, loading: false, open: false }));
      dispatch(reload());
      dispatch(notifySuccess('Remote added'));
    })
    .catch((e) => {
      dispatch(notifyError(e));
    })
    .finally(() => {
      dispatch(updateModalStatus({ success: false, loading: false }));
    });
};

export const activateRemote = (serial) => (dispatch, getState) => {
  const { communityID } = getState().remotes;
  dispatch(startRemoteAction({ remoteID: serial, actionType: 'activate' }));
  activateRemoteAPI(communityID, serial)
    .then(() => {
      dispatch(reload());
      dispatch(notifySuccess('Remote activated'));
    })
    .catch(() => {
      dispatch(notifyError('Remote activation failed'));
    })
    .finally(() => {
      dispatch(finishRemoteAction({ remoteID: serial, actionType: 'activate' }));
    });
};

export const deactivateRemote = (serial) => (dispatch, getState) => {
  const { communityID } = getState().remotes;
  dispatch(startRemoteAction({ remoteID: serial, actionType: 'deactivate' }));
  deactivateRemoteAPI(communityID, serial)
    .then(() => {
      dispatch(reload());
      dispatch(notifySuccess('Remote deactivated'));
    })
    .catch(() => {
      dispatch(notifyError('Remote deactivation failed'));
    })
    .finally(() => {
      dispatch(finishRemoteAction({ remoteID: serial, actionType: 'deactivate' }));
    });
};

export const deleteRemote = (communityID, serial) => (dispatch) => {
  dispatch(startRemoteAction({ remoteID: serial, actionType: 'delete' }));
  deleteRemoteAPI(communityID, serial)
    .then(() => {
      dispatch(reload());
      dispatch(notifySuccess('Remote deletion succeeded'));
    })
    .catch(() => {
      dispatch(notifyError('Remote deletion failed'));
    })
    .finally(() => {
      dispatch(finishRemoteAction({ remoteID: serial, actionType: 'delete' }));
    });
};

export const listAccessPoints = (communityId) => async (dispatch) => {
  try {
    const accessPoints = await listGates(communityId);
    dispatch(setAccessPoints(accessPoints));
  } catch (err) {
    dispatch(notifyError('Load gates failed'));
  }
};
