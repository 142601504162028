import { ICameraRpi } from '../domain/ICameraRpi';
import { by, inject, keys } from '../../injectDecorators';
import { IAuthorizer, IAuthorizerKey } from '../../api/IAuthorizer';
import axios, { AxiosInstance } from 'axios';

export const ICamerasRpiRepoKey = Symbol('ICamerasRpiRepo');

export interface ICamerasRpiRepo {
  fetchCamerasRpi(communityId: number): Promise<ICameraRpi[]>;

  startCameraRpiLiveStream(communityId: number, cameraRpiSn: string): Promise<string>;

  stopCameraRpiLiveStream(communityId: number, cameraRpiSn: string): Promise<void>;

  createCameraRpi(communityId: number, cameraRpi: Partial<ICameraRpi>): Promise<ICameraRpi>;

  updateCameraRpi(communityId: number, cameraRpi: Partial<ICameraRpi>): Promise<void>;

  removeCameraRpi(communityId: number, cameraRpiSn: string): Promise<void>;

  turnOnCameraRpiHotspot(communityId: number, cameraRpiSn: string, hotspotPassword: string): Promise<void>;
}

@keys(ICamerasRpiRepoKey)
export class CamerasRpiRepo implements ICamerasRpiRepo {
  constructor(@inject(by(IAuthorizerKey)) private authorizer: IAuthorizer) {}

  async fetchCamerasRpi(communityId: number): Promise<ICameraRpi[]> {
    const httpClient = await this.createHttpClient();
    const response = await httpClient.get(`community/${communityId}/camera-rpi`);
    return response.data;
  }

  async startCameraRpiLiveStream(communityId: number, cameraRpiSn: string): Promise<string> {
    const httpClient = await this.createHttpClient();
    const response = await httpClient.post(`community/${communityId}/camera-rpi/${cameraRpiSn}/start-live-stream`);
    return response.data.url;
  }

  async stopCameraRpiLiveStream(communityId: number, cameraRpiSn: string): Promise<void> {
    const httpClient = await this.createHttpClient();
    await httpClient.post(`community/${communityId}/camera-rpi/${cameraRpiSn}/stop-live-stream`);
  }

  async createCameraRpi(communityId: number, cameraRpi: ICameraRpi): Promise<ICameraRpi> {
    const httpClient = await this.createHttpClient();
    const response = await httpClient.post(`community/${communityId}/camera-rpi`, cameraRpi);
    return response.data;
  }

  async updateCameraRpi(communityId: number, cameraRpi: ICameraRpi): Promise<void> {
    const httpClient = await this.createHttpClient();
    await httpClient.patch(`community/${communityId}/camera-rpi/${cameraRpi.cameraId}`, cameraRpi);
  }

  async removeCameraRpi(communityId: number, cameraRpiSn: string): Promise<void> {
    const httpClient = await this.createHttpClient();
    await httpClient.delete(`community/${communityId}/camera-rpi/${cameraRpiSn}`);
    return;
  }

  async turnOnCameraRpiHotspot(communityId: number, cameraRpiSn: string, hotspotPassword: string): Promise<void> {
    const httpClient = await this.createHttpClient();
    await httpClient.post(`community/${communityId}/camera-rpi/${cameraRpiSn}/turn-on-hotspot`, { hotspotPassword });
  }

  private async createHttpClient(): Promise<AxiosInstance> {
    return axios.create({
      baseURL: process.env.API_V3_URL,
      headers: {
        Authorization: `Bearer ${await this.authorizer.getIdToken()}`,
      },
    });
  }
}
