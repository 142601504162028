import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { notifySuccess, notifyError } from '../notifications/notificationsSlice';
import {
  listMembers,
  inviteMember as apiInvite,
  updateMember as apiUpdate,
  updatePhoneNumber as apiUpdatePhoneNumber,
  activateMembers,
  deactivateMembers,
  deleteMembers,
  deleteInvites,
  resetMemberTokensCount,
  updateInvite,
  inviteMembers,
  sendSmsToPendingMembers,
  updateCommunity as updateCommunityAPI,
} from '../../api/gatewise_api';
import { checkUserWithRetries, generateExcel, isAdminOrManager, parseFile } from './utils';
import { LastSeenMode } from './LastSeenMode';
import { calcIsStaff } from '../global_members/utils';
import { Role } from '../../models/Role';
import { loadCommunityDetails } from '../community_details/communityDetailsSlice';
import * as authorizer from '@firebase/auth';
import { getIsSuperAdmin } from '../../selectors';

const initialState = {
  filter: 'all',
  invitationStatusFilter: 'all',
  lastSeenFilter: LastSeenMode.AnyTime,
  search: '',
  page: {
    items: [],
    page: 0,
    limit: 10,
    total: 0,
  },
  order: {
    field: 'name',
    direction: 'asc',
  },
  selectedMembers: [],
  modal: {
    editedMember: null,
    isNew: false,
    submitting: false,
  },
  inviteModal: {
    editedMember: null,
    isNew: false,
    submitting: false,
  },
  changePhoneModal: {
    visible: false,
  },
  memberAction: {},
  loading: true,
  notifications: {
    success: null,
    error: null,
  },
  error: null,
  invitationFailedMembers: [],
};

const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    setPage: (state, action) => {
      const { page, error } = action.payload;
      state.page = page;
      state.error = error;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    closeModal: (state) => {
      state.modal = { isNew: false, editedMember: null };
      state.inviteModal = { isNew: false, editedMember: null };
    },
    openNewMemberModal: (state, action) => {
      const role = action.payload || Role.Resident;
      state.modal = { isNew: true, editedMember: { role, groups: [] } };
    },
    openNewStaffModal: (state, action) => {
      const role = action.payload || Role.Worker;
      state.modal = { isNew: true, editedMember: { role, groups: [] } };
    },
    openEditMemberModal: (state, action) => {
      const member = action.payload;
      state.modal = { isNew: false, editedMember: member, isStaff: calcIsStaff(member.role) };
    },
    openEditInvitationModal: (state, action) => {
      const member = action.payload;
      state.inviteModal = { isNew: false, editedMember: member, isStaff: calcIsStaff(member.role) };
    },
    setSubmitting: (state, action) => {
      state.modal.submitting = action.payload;
    },
    setSelectedMembers: (state, action) => {
      state.selectedMembers = action.payload;
    },
    startMemberAction: (state, _action) => {
      const { action, ids } = _action.payload;
      state.memberAction = { action, ids };
    },
    cancelMemberAction: (state) => {
      state.memberAction = {};
    },
    finishMemberAction: (state, action) => {
      const { success, error } = action.payload;
      state.memberAction.error = error;
      state.memberAction.status = success ? 'success' : 'failure';
      if (success) {
        state.selectedMembers = [];
      }
    },
    setMemberActionInProgress: (state) => {
      state.memberAction.status = 'inprogress';
    },
    setChangePhoneModalvisibility: (state, action) => {
      state.changePhoneModal.visible = action.payload;
    },
    updateFilter: (state, action) => {
      state.filter = action.payload;
    },
    updateInvitationStatusFilter: (state, action) => {
      state.invitationStatusFilter = action.payload;
    },
    updateLastSeenFilter: (state, action) => {
      state.lastSeenFilter = action.payload;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
    clearNotifications: (state) => {
      state.notifications = {};
    },
    setErrorNotifications: (state, action) => {
      state.notifications = {
        error: action.payload,
      };
    },
    setInvitationFailedMembers: (state, action) => {
      state.invitationFailedMembers = action.payload;
    },
  },
});

export const {
  openNewStaffModal,
  clearNotifications,
  updateSearch,
  updateFilter,
  updateInvitationStatusFilter,
  updateLastSeenFilter,
  setMemberActionInProgress,
  finishMemberAction,
  startMemberAction,
  cancelMemberAction,
  setSelectedMembers,
  setSubmitting,
  openEditMemberModal,
  openEditInvitationModal,
  setPage,
  setOrder,
  openNewMemberModal,
  setLoading,
  closeModal,
  setErrorNotifications,
  setChangePhoneModalvisibility,
  setInvitationFailedMembers,
} = membersSlice.actions;

export default membersSlice.reducer;

export const loadPage =
  (
    communityID,
    currentPage,
    perPage,
    filter,
    search,
    archived,
    invitationStatusFilter,
    lastSeenFilter,
    order,
    isLoadingPaginationRef = null,
    signal,
  ) =>
  (dispatch) => {
    dispatch(setLoading(true));

    let lastSeen;
    switch (lastSeenFilter) {
      case LastSeenMode.AnyTime:
        lastSeen = null;
        break;
      case LastSeenMode.MoreThanAWeek:
        lastSeen = moment().subtract(7, 'days').format();
        break;
      case LastSeenMode.MoreThanAMonth:
        lastSeen = moment().subtract(1, 'months').format();
        break;
      default:
        break;
    }
    const orderBy = order.field;
    const orderDirection = order.direction;

    listMembers(
      communityID,
      currentPage * perPage,
      perPage,
      filter,
      invitationStatusFilter,
      lastSeen,
      search,
      !archived,
      orderBy,
      orderDirection,
      signal,
    )
      .then(({ items, total }) => {
        if (items) {
          dispatch(setLoading(false));
        }
        dispatch(
          setPage({
            page: {
              items: items || [],
              page: currentPage,
              limit: perPage,
              total,
            },
            error: null,
          }),
        );
      })
      .catch((error) => {
        dispatch(setLoading(false));
        dispatch(setPage({ page: { items: [], limit: 10 }, error: error.toString() }));
      })
      .finally(() => {
        if (isLoadingPaginationRef) {
          isLoadingPaginationRef.current = false;
        }
      });
  };

export const reloadWithFilter = (filter) => (dispatch) => {
  dispatch(updateFilter(filter));
};

export const reloadWithSearch = (search) => (dispatch) => {
  dispatch(setLoading(true));
  dispatch(updateSearch(search));
};

export const reloadWithInvitationStatusFilter = (filter) => (dispatch) => {
  dispatch(updateInvitationStatusFilter(filter));
};

export const reloadWithLastSeenFilter = (filter) => (dispatch) => {
  dispatch(updateLastSeenFilter(filter));
};

const shouldSendWelcomeEmail = (member) => isAdminOrManager(member.role);

export const inviteMember = (member, communityID, archived) => async (dispatch, getState) => {
  dispatch(setSubmitting(true));
  try {
    const data = await apiInvite(communityID, member);
    if (data.error) {
      dispatch(notifyError(data.error));
      return;
    }
    dispatch(closeModal());
    const { page, filter, search, invitationStatusFilter, lastSeenFilter, order } = getState().members;
    dispatch(
      loadPage(
        communityID,
        page.page,
        page.limit,
        filter,
        search,
        archived,
        invitationStatusFilter,
        lastSeenFilter,
        order,
      ),
    );
    dispatch(checkAndSendInvite(communityID, member));
  } catch (err) {
    dispatch(notifyError(`Invite failed with: ${err.toString()}`));
  } finally {
    dispatch(setSubmitting(false));
  }
};

/**
 * Check if user exists in Firebase and send invite if needed
 * @param communityID
 * @param member
 * @param fromResend
 * @returns {(function(*, *, {authorizer: *}): Promise<void>)|*}
 */
export const checkAndSendInvite = (communityID, member, fromResend = false) => {
  return async (dispatch, _, { authorizer }) => {
    try {
      if (shouldSendWelcomeEmail(member)) {
        await checkUserWithRetries(communityID, member.email, 3, 1500);
        await authorizer.sendPasswordResetEmail(member.email);
        dispatch(notifySuccess('Invitation sent'));
      }
    } catch (err) {
      let errorMessage = `Firebase is unavailable now. Please try resend invite later.`;
      if (fromResend) {
        errorMessage = getIsSuperAdmin()
          ? `Firebase is unavailable now. Please try resend invite later. Please contact to R&D team.`
          : 'Firebase is unavailable now. Please try resend invite later. Please contact to support team.';
      }
      dispatch(notifyError(errorMessage));
    }
  };
};

export const updateMember = (communityID, id, member, archived) => (dispatch, getState) => {
  dispatch(setSubmitting(true));
  apiUpdate(communityID, id, member)
    .then(() => {
      dispatch(closeModal());
      const { page, filter, search, invitationStatusFilter, lastSeenFilter, order } = getState().members;
      dispatch(
        loadPage(
          communityID,
          page.page,
          page.limit,
          filter,
          search,
          archived,
          invitationStatusFilter,
          lastSeenFilter,
          order,
        ),
      );
      dispatch(notifySuccess('Member updated'));
    })
    .catch(() => {
      dispatch(notifyError('Update failed'));
    })
    .finally(() => {
      dispatch(setSubmitting(false));
    });
};

export const updateInvitation = (communityID, id, invite, archived) => (dispatch, getState) => {
  dispatch(setSubmitting(true));
  updateInvite(communityID, id, invite)
    .then(() => {
      dispatch(closeModal());
      const { page, filter, search, invitationStatusFilter, lastSeenFilter, order } = getState().members;
      dispatch(
        loadPage(
          communityID,
          page.page,
          page.limit,
          filter,
          search,
          archived,
          invitationStatusFilter,
          lastSeenFilter,
          order,
        ),
      );
      dispatch(notifySuccess('Invitation updated'));
    })
    .catch(() => {
      dispatch(notifyError('Update failed'));
    })
    .finally(() => {
      dispatch(setSubmitting(false));
    });
};

export const updatePhoneNumber = (communityID, id, phone, archived) => async (dispatch, getState) => {
  dispatch(setSubmitting(true));
  try {
    const data = await apiUpdatePhoneNumber(communityID, id, phone);
    if (data.error) {
      dispatch(notifyError(data.error));
      return;
    }
    const { page, filter, search, invitationStatusFilter, lastSeenFilter, order } = getState().members;
    dispatch(
      loadPage(
        communityID,
        page.page,
        page.limit,
        filter,
        search,
        archived,
        invitationStatusFilter,
        lastSeenFilter,
        order,
      ),
    );
    dispatch(notifySuccess('member phone updated'));
    dispatch(setChangePhoneModalvisibility(false));
  } catch (err) {
    dispatch(notifyError(`Phone update failed with: ${err.toString()}`));
  } finally {
    dispatch(setSubmitting(false));
  }
};

export const archiveAction = 'archive';
export const unarchiveAction = 'unarchive';
export const resetAction = 'reset flagged status';
export const deleteAction = 'delete';
export const deleteInvitationAction = 'cancel invitation';

export const executeMemberAction = (cid, archived) => (dispatch, getState) => {
  const { action, ids } = getState().members.memberAction;
  let currentAction = null;
  dispatch(setMemberActionInProgress());
  if (action === archiveAction) {
    currentAction = deactivateMembers(cid, ids);
  } else if (action === unarchiveAction) {
    currentAction = activateMembers(cid, ids);
  } else if (action === deleteAction) {
    currentAction = deleteMembers(cid, ids);
  } else if (action === deleteInvitationAction) {
    currentAction = deleteInvites(cid, ids);
  } else if (action === resetAction) {
    currentAction = resetMemberTokensCount(cid, ids);
  }
  currentAction
    .then(() => {
      dispatch(finishMemberAction({ success: true }));
      const { page, filter, search, invitationStatusFilter, lastSeenFilter, order } = getState().members;
      dispatch(
        loadPage(cid, page.page, page.limit, filter, search, archived, invitationStatusFilter, lastSeenFilter, order),
      );
    })
    .catch((e) => {
      dispatch(finishMemberAction({ success: false, error: e.toString() }));
      dispatch(notifyError('Operation failed'));
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const inviteMembersAction = (file, communityID, onDone, archived) => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));

    const members = await parseFile(file);

    const { data: items } = await inviteMembers(communityID, members);
    const { page, filter, search, invitationStatusFilter, lastSeenFilter, order } = getState().members;
    dispatch(
      loadPage(
        communityID,
        page.page,
        page.limit,
        filter,
        search,
        archived,
        invitationStatusFilter,
        lastSeenFilter,
        order,
      ),
    );
    if (items.length) {
      dispatch(setInvitationFailedMembers(items));
    } else {
      dispatch(notifySuccess('Invitations sent'));
    }
  } catch (err) {
    dispatch(setErrorNotifications(err.toString()));
  } finally {
    dispatch(setLoading(false));
    if (onDone) {
      onDone();
    }
  }
};

export const exportToExcel = (communityId, communityName, onDone) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const { items } = await listMembers(communityId, 0, 99999, 'all');
    generateExcel(items, communityName);
    dispatch(notifySuccess('Export completed'));
  } catch (err) {
    dispatch(setErrorNotifications(err.toString()));
  } finally {
    dispatch(setLoading(false));
    if (onDone) {
      onDone();
    }
  }
};

export const updateCommunity = (community) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    await updateCommunityAPI(community.id, community);
    dispatch(loadCommunityDetails(community.id));
  } catch (err) {
    dispatch(notifyError('Failed to update allow invites'));
  } finally {
    dispatch(setLoading(false));
  }
};

export const sendSmsToPendingMembersAction =
  ({ communityId, smsBody, considerIfAllowed }) =>
  async (dispatch) => {
    try {
      const { data } = await sendSmsToPendingMembers(communityId, smsBody, considerIfAllowed);
      dispatch(notifySuccess(data));
    } catch (err) {
      dispatch(setErrorNotifications(err.toString()));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const allowSendingInvites = (community) => async (dispatch) => {
  try {
    const updatedCommunity = { ...community, allow_send_invite: true };
    await updateCommunityAPI(updatedCommunity.id, updatedCommunity);
    dispatch(loadCommunityDetails(updatedCommunity.id));

    dispatch(sendSmsToPendingMembersAction({ communityId: updatedCommunity.id, considerIfAllowed: true }));
  } catch (err) {
    dispatch(setErrorNotifications(err.toString()));
  }
};
