import React, { PropsWithChildren } from 'react';
import { Grid, TextField } from '@material-ui/core';

export type RentManagerCreds = {
  username: string;
  password: string;
  url: string;
  propertyId: string;
};

export function RentManagerForm({
  username,
  password,
  url,
  propertyId,
  onChange,
}: PropsWithChildren<
  {
    onChange: (data: Partial<RentManagerCreds>) => void;
  } & RentManagerCreds
>): JSX.Element {
  return (
    <>
      <Grid item md={6} xs={6}>
        <TextField
          fullWidth
          label="URL"
          onChange={({ target }) => onChange({ url: target.value })}
          value={url}
          variant="outlined"
          required
        />
      </Grid>
      <Grid item md={6} xs={6}>
        <TextField
          fullWidth
          label="Username"
          onChange={({ target }) => onChange({ username: target.value })}
          value={username}
          variant="outlined"
          required
        />
      </Grid>
      <Grid item md={6} xs={6}>
        <TextField
          fullWidth
          label="Password"
          onChange={({ target }) => onChange({ password: target.value })}
          value={password}
          variant="outlined"
          required
        />
      </Grid>
      <Grid item md={6} xs={6}>
        <TextField
          fullWidth
          label="Property ID"
          onChange={({ target }) => onChange({ propertyId: target.value })}
          value={propertyId}
          variant="outlined"
          required
        />
      </Grid>
    </>
  );
}
