import { createContainer, fromClass } from '../injectDecorators';
import { Authorizer } from '../api/Authorizer';
import { firebaseApp } from '../app/di';
import { InvitationRepo } from '../app/repos/IInvitationRepo';
import { AccessPointSyncRepo } from '../app/repos/IAccessPointSyncRepo';

export function common() {
  return createContainer()
    .register(
      fromClass(Authorizer)
        .withArgs(firebaseApp, process.env.REACT_APP_DEPLOYMENT_URL as string)
        .build(),
    )
    .register(fromClass(AccessPointSyncRepo).build())
    .register(fromClass(InvitationRepo).build());
}
