import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppSelector, AppThunk } from '../../../app/store';
import { notifyError } from '../../notifications/notificationsSlice';
import { getYardiVersion } from '../../../api/gatewise_api';

type YardiState = {
  version?: string;
};

const initialState: YardiState = {};

const { reducer, actions } = createSlice({
  name: 'yardi',
  initialState,
  reducers: {
    setVersion: (state, action: PayloadAction<string>) => {
      state.version = action.payload;
    },
  },
});

export const yardi = reducer;

export const fetchVersion =
  (url: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(actions.setVersion(await getYardiVersion(url)));
    } catch (e) {
      dispatch(notifyError(e));
    }
  };

export const yardiVersionSelector: AppSelector<string | undefined> = (state) => state.yardi.version;
