import {
  IUnitApartmentItem,
  IUnitLockItem,
  IUnitLockPaginationQuery,
  IUnitLockPaginationResponse,
} from '../domain/IUnitLock';
import { by, inject, keys } from '../../injectDecorators';
import axios, { AxiosInstance } from 'axios';
import { IAuthorizer, IAuthorizerKey } from '../../api/IAuthorizer';

export const IUnitLocksRepoKey = Symbol('IUnitLocksRepo');

export interface IUnitLocksRepo {
  fetchUnitLocksByCommunityId(query: IUnitLockPaginationQuery): Promise<IUnitLockPaginationResponse>;

  fetchUnitApartmentsByCommunityId(communityId: string): Promise<IUnitApartmentItem[]>;

  createUnitLock(communityId: string, data: Partial<IUnitLockItem>): Promise<IUnitLockItem>;

  updateUnitLock(communityId: string, unitLockId: number, data: Partial<IUnitLockItem>): Promise<IUnitLockItem>;

  activateUnitLock(communityId: string, unitLockId: number): Promise<IUnitLockItem>;

  deactivateUnitLock(communityId: string, unitLockId: number): Promise<IUnitLockItem>;

  checkPinCode(communityId: string, unitLockId: number): Promise<IUnitLockItem>;
}

@keys(IUnitLocksRepoKey)
export class UnitLocksRepo implements IUnitLocksRepo {
  constructor(@inject(by(IAuthorizerKey)) private authorizer: IAuthorizer) {}

  async fetchUnitLocksByCommunityId(query: IUnitLockPaginationQuery): Promise<IUnitLockPaginationResponse> {
    const httpClient = await this.createHttpClient();
    const response = await httpClient.get(`community/${query.communityId}/unit_locks`, {
      params: { limit: query.limit, offset: query.offset, search: query.search, filterByActive: query.filterByActive },
    });
    return response.data;
  }

  async fetchUnitApartmentsByCommunityId(communityId: string): Promise<IUnitApartmentItem[]> {
    const httpClient = await this.createHttpClient();
    const response = await httpClient.get(`community/${communityId}/unit_locks/apartments`);
    return response.data;
  }

  async createUnitLock(communityId: string, data: Partial<IUnitLockItem>): Promise<IUnitLockItem> {
    const httpClient = await this.createHttpClient();
    const result = await httpClient.post(`community/${communityId}/unit_locks`, data);
    return result.data;
  }

  async updateUnitLock(communityId: string, unitLockId: number, data: Partial<IUnitLockItem>): Promise<IUnitLockItem> {
    const httpClient = await this.createHttpClient();
    const result = await httpClient.patch(`community/${communityId}/unit_locks/${unitLockId}`, data);
    return result.data;
  }

  async activateUnitLock(communityId: string, unitLockId: number): Promise<IUnitLockItem> {
    const httpClient = await this.createHttpClient();
    const result = await httpClient.patch(`community/${communityId}/unit_locks/${unitLockId}/activate`);
    return result.data;
  }

  async deactivateUnitLock(communityId: string, unitLockId: number): Promise<IUnitLockItem> {
    const httpClient = await this.createHttpClient();
    const result = await httpClient.patch(`community/${communityId}/unit_locks/${unitLockId}/deactivate`);
    return result.data;
  }

  async checkPinCode(communityId: string, unitLockId: number): Promise<IUnitLockItem> {
    const httpClient = await this.createHttpClient();
    const result = await httpClient.post(`community/${communityId}/unit_locks/${unitLockId}/check-pin`);
    return result.data;
  }

  private async createHttpClient(): Promise<AxiosInstance> {
    return axios.create({
      baseURL: process.env.API_URL_CLUSTER,
      headers: {
        Authorization: `Bearer ${await this.authorizer.getIdToken()}`,
      },
    });
  }
}
