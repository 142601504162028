import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, colors, ListItem } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'capitalize',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    color: (props) => (props.error ? colors.red[600] : 'inherit'),
  },
  icon: {
    color: (props) => (props.error ? colors.red[600] : theme.palette.icon),
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 16,
    width: 16,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: (props) => (props.error ? colors.red[600] : theme.palette.primary.main),
    },
  },
  addScheduleGroup: {
    color: '#3f0bef',
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

const NavItem = ({
  title,
  href,
  // eslint-disable-next-line react/prop-types
  depth = 0,
  icon: Icon,
  className,
  // eslint-disable-next-line react/prop-types
  error,
  // eslint-disable-next-line react/prop-types
  shouldOpenNewTab = false,
  ...rest
}) => {
  const classes = useStyles({ error });

  /// management/schedules/new
  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 12 + 8 * depth;
  }

  const style = {
    paddingLeft,
  };

  return (
    <ListItem {...rest} className={clsx(classes.itemLeaf, className)} disableGutters key={title}>
      {shouldOpenNewTab ? (
        <Button target="_blank" href={href} className={classes.buttonLeaf}>
          {Icon && <Icon className={classes.icon} />}
          {title}
        </Button>
      ) : (
        <Button
          activeClassName={classes.active}
          className={classes.buttonLeaf}
          component={RouterLink}
          error={error ? 1 : 0}
          exact
          style={style}
          to={href}
        >
          {Icon && <Icon className={classes.icon} />}
          {title}
        </Button>
      )}
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
