/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import axios from 'axios';

import { notifySuccess, notifyError } from '../notifications/notificationsSlice';
import {
  listGlobalMembers,
  updateMember as apiUpdate,
  activateGlobalMembers,
  deactivateGlobalMembers,
  deleteGlobalMembers,
  deleteGlobalInvites,
  updateInvite,
  generatePassword,
} from '../../api/gatewise_api';
import { LastSeenMode } from '../members/LastSeenMode';

const initialState = {
  filter: 'all',
  invitationStatusFilter: 'all',
  lastSeenFilter: LastSeenMode.AnyTime,
  active: true,
  search: '',
  page: {
    items: [],
    page: 0,
    limit: 10,
    total: 0,
  },
  selectedMembers: [],
  modal: {
    editedMember: null,
    isNew: false,
    submitting: false,
  },
  inviteModal: {
    editedMember: null,
    isNew: false,
    submitting: false,
  },
  generateModal: {
    isOpen: false,
    member: null,
  },
  memberAction: {},
  loading: true,
  loaded: false,
  notifications: {
    success: null,
    error: null,
  },
  error: null,
};

const membersSlice = createSlice({
  name: 'globalMembers',
  initialState,
  reducers: {
    reset: (state) => {
      const {
        page,
        filter,
        selectedMembers,
        modal,
        memberAction,
        error,
        search,
        loaded,
        invitationStatusFilter,
        lastSeenFilter,
      } = initialState;
      state.page = page;
      state.selectedMembers = selectedMembers;
      state.modal = modal;
      state.memberAction = memberAction;
      state.error = error;
      state.filter = filter;
      state.invitationStatusFilter = invitationStatusFilter;
      state.lastSeenFilter = lastSeenFilter;
      state.notifications = {};
      state.search = search;
      state.loaded = loaded;
    },
    setPage: (state, action) => {
      const { page, error, loaded } = action.payload;
      state.page = page;
      state.error = error;
      state.loaded = loaded;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    closeModal: (state) => {
      state.modal = { isNew: false, editedMember: null };
      state.inviteModal = { isNew: false, editedMember: null };
    },
    openEditMemberModal: (state, action) => {
      const member = action.payload;
      state.modal = { isNew: false, editedMember: member };
    },
    openEditInvitationModal: (state, action) => {
      const member = action.payload;
      state.inviteModal = { isNew: false, editedMember: member };
    },
    setSubmitting: (state, action) => {
      state.modal.submitting = action.payload;
    },
    setSelectedMembers: (state, action) => {
      state.selectedMembers = action.payload;
    },
    setGenerateModal: (state, action) => {
      state.generateModal = action.payload;
    },
    startMemberAction: (state, _action) => {
      const { action, ids } = _action.payload;
      state.memberAction = { action, ids };
    },
    cancelMemberAction: (state) => {
      state.memberAction = {};
    },
    finishMemberAction: (state, action) => {
      const { success, error } = action.payload;
      state.memberAction.error = error;
      state.memberAction.status = success ? 'success' : 'failure';
      if (success) {
        state.selectedMembers = [];
      }
    },
    setMemberActionInProgress: (state) => {
      state.memberAction.status = 'inprogress';
    },
    updateFilter: (state, action) => {
      state.filter = action.payload;
    },
    updateInvitationStatusFilter: (state, action) => {
      state.invitationStatusFilter = action.payload;
    },
    updateLastSeenFilter: (state, action) => {
      state.lastSeenFilter = action.payload;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
    updateActive: (state, action) => {
      state.active = action.payload;
    },
    clearNotifications: (state) => {
      state.notifications = {};
    },
  },
});

export const {
  openNewStaffModal,
  clearNotifications,
  updateSearch,
  updateFilter,
  updateInvitationStatusFilter,
  updateLastSeenFilter,
  updateActive,
  setMemberActionInProgress,
  finishMemberAction,
  startMemberAction,
  cancelMemberAction,
  setSelectedMembers,
  setGenerateModal,
  reset,
  setSubmitting,
  openEditMemberModal,
  openEditInvitationModal,
  setPage,
  openNewMemberModal,
  setLoading,
  closeModal,
} = membersSlice.actions;

export default membersSlice.reducer;

export const loadPage = (page, limit, abortSignal) => (dispatch, getState) => {
  const { filter, search, active, invitationStatusFilter, lastSeenFilter } = getState().globalMembers;
  dispatch(setLoading(true));

  let lastSeen;
  switch (lastSeenFilter) {
    case LastSeenMode.AnyTime:
      lastSeen = null;
      break;
    case LastSeenMode.MoreThanAWeek:
      lastSeen = moment().subtract(7, 'days').format();
      break;
    case LastSeenMode.MoreThanAMonth:
      lastSeen = moment().subtract(1, 'months').format();
      break;
    default:
      break;
  }

  listGlobalMembers({
    offset: page * limit,
    limit,
    filter,
    invitationStatusFilter,
    search,
    active,
    lastSeen,
    abortSignal,
  })
    .then(({ items, total }) => {
      dispatch(setLoading(false));
      dispatch(
        setPage({
          page: {
            items,
            page,
            limit,
            total,
          },
          loaded: true,
          error: null,
        }),
      );
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        return;
      }

      dispatch(setLoading(false));
      dispatch(setPage({ page: { items: [], limit: 10 }, error: error.toString() }));
    });
};

export const reloadWithFilter = (filter) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().globalMembers;
  dispatch(setSelectedMembers([]));
  dispatch(updateFilter(filter));
  dispatch(loadPage(0, limit));
};

export const reloadWithSearch = (search, abortSignal) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().globalMembers;
  dispatch(setLoading(true));
  dispatch(setSelectedMembers([]));
  dispatch(updateSearch(search));
  dispatch(loadPage(0, limit, abortSignal));
};

export const reloadWithActive = (active) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().globalMembers;
  dispatch(setSelectedMembers([]));
  dispatch(updateActive(active));
  dispatch(loadPage(0, limit));
};

export const reloadWithInvitationStatusFilter = (filter) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().globalMembers;
  dispatch(setSelectedMembers([]));
  dispatch(updateInvitationStatusFilter(filter));
  dispatch(loadPage(0, limit));
};

export const reloadWithLastSeenFilter = (filter) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().globalMembers;
  dispatch(setSelectedMembers([]));
  dispatch(updateLastSeenFilter(filter));
  dispatch(loadPage(0, limit));
};

export const reload = () => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().globalMembers;
  dispatch(loadPage(0, limit));
};

export const updateMember = (id, member) => (dispatch, getState) => {
  dispatch(setSubmitting(true));
  apiUpdate(member.community_id, id, member)
    .then(() => {
      dispatch(closeModal());
      const { page } = getState().globalMembers;
      dispatch(loadPage(page.page, page.limit));
      dispatch(notifySuccess('Member updated'));
    })
    .catch(() => {
      dispatch(notifyError('Update failed'));
    })
    .finally(() => {
      dispatch(setSubmitting(false));
    });
};

export const updateInvitation = (id, invite) => (dispatch, getState) => {
  dispatch(setSubmitting(true));
  updateInvite(invite.community_id, id, invite)
    .then(() => {
      dispatch(closeModal());
      const { page } = getState().globalMembers;
      dispatch(loadPage(page.page, page.limit));
      dispatch(notifySuccess('Invitation updated'));
    })
    .catch(() => {
      dispatch(notifyError('Update failed'));
    })
    .finally(() => {
      dispatch(setSubmitting(false));
    });
};

export const startGenerateAction =
  ({ member, invited }) =>
  (dispatch) => {
    dispatch(setGenerateModal({ member, isOpen: true, invited }));
  };

export const finishGenerateAction = () => (dispatch) => {
  dispatch(setGenerateModal({ member: null, isOpen: false, invited: null }));
};

export const generateMemberPassword =
  ({ member, invited }) =>
  (dispatch) => {
    dispatch(setGenerateModal({ member, isOpen: true, status: 'inprogress', invited }));
    generatePassword(member.id, invited)
      .then((data) => {
        dispatch(
          setGenerateModal({ member, isOpen: true, status: data.status, generatedPassword: data.data, invited }),
        );
      })
      .catch(() => {
        dispatch(setGenerateModal({ member, isOpen: true, status: 'failed' }));
        dispatch(notifyError('Generate password failed'));
      })
      .finally(() => {});
  };

export const archiveAction = 'archive';
export const unarchiveAction = 'unarchive';
export const deleteAction = 'delete';
export const deleteInvitationAction = 'cancel invitation';

export const executeMemberAction = () => (dispatch, getState) => {
  const { action, ids } = getState().globalMembers.memberAction;
  let currentAction = null;
  dispatch(setMemberActionInProgress());
  if (action === archiveAction) {
    currentAction = deactivateGlobalMembers(ids);
  } else if (action === unarchiveAction) {
    currentAction = activateGlobalMembers(ids);
  } else if (action === deleteAction) {
    currentAction = deleteGlobalMembers(ids);
  } else if (action === deleteInvitationAction) {
    currentAction = deleteGlobalInvites(ids);
  }
  currentAction
    .then(() => {
      dispatch(finishMemberAction({ success: true }));
      const { page } = getState().globalMembers;
      dispatch(loadPage(page.page, page.limit));
    })
    .catch((e) => {
      dispatch(finishMemberAction({ success: false, error: e.toString() }));
      dispatch(notifyError('Operation failed'));
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};
