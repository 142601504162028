import React, { FC, useEffect } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import { ICameraRpiEvent } from '../../../app/domain/ICameraRpiEvent';

interface IProps {
  anchorEl: Element | null;
  onCloseActionMenuHandle: () => void;
  cameraRpEvent: ICameraRpiEvent | null;
  onChangeSeenStatus: (cameraId: string, eventId: string, status: boolean) => void;
  onChangeImportantStatus: (cameraId: string, eventId: string, status: boolean) => void;
}

const CameraRpiEventActions: FC<IProps> = (props) => {
  const { anchorEl, onCloseActionMenuHandle, cameraRpEvent, onChangeSeenStatus, onChangeImportantStatus } = props;

  const [isSeen, setIsSeen] = React.useState<boolean>(!!cameraRpEvent?.tags?.seen);
  const [isImportant, setIsImportant] = React.useState<boolean>(!!cameraRpEvent?.tags?.important);

  useEffect(() => {
    if (cameraRpEvent) {
      setIsSeen(!!cameraRpEvent?.tags?.seen);
      setIsImportant(!!cameraRpEvent?.tags?.important);
    }
  }, [cameraRpEvent]);

  const onChangeSeenStatusHandle = () => {
    if (cameraRpEvent) {
      onChangeSeenStatus(cameraRpEvent.cameraRpi.cameraId, cameraRpEvent.eventId, !cameraRpEvent?.tags?.seen);
    }
    onCloseActionMenuHandle();
  };
  // mark as unseen
  const onChangeImportantStatusHandle = () => {
    if (cameraRpEvent) {
      onChangeImportantStatus(cameraRpEvent.cameraRpi.cameraId, cameraRpEvent.eventId, !cameraRpEvent?.tags?.important);
    }
    onCloseActionMenuHandle();
  };

  return (
    <Menu
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onCloseActionMenuHandle}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MenuItem onClick={onChangeSeenStatusHandle}>
        <Typography>{`${isSeen ? 'Unmark' : 'Mark'} as Seen`}</Typography>
      </MenuItem>
      <MenuItem onClick={onChangeImportantStatusHandle}>
        <Typography>{`${isImportant ? 'Unmark' : 'Mark'} as Important`}</Typography>
      </MenuItem>
    </Menu>
  );
};

export default CameraRpiEventActions;
