import { PropsWithChildren } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';

export function AlertDialog({ onClose, children }: PropsWithChildren<{ onClose: () => void }>): JSX.Element {
  return (
    <Dialog fullWidth open={true} onClose={onClose}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}
