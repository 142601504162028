/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  addProductUpdates as apiAdd,
  listProductUpdates,
  updateProductUpdates as apiUpdate,
  deleteProductUpdates as apiDelete,
} from '../../api/gatewise_api';
import { notifyError, notifySuccess } from '../notifications/notificationsSlice';

const userSlice = createSlice({
  name: 'productUpdates',
  initialState: {
    page: {
      items: [],
      offset: 0,
      limit: 3000,
      total: 0,
    },
    search: null,
    modal: {
      editedProductUpdates: null,
      isNew: false,
      ProductUpdatesImage: null,
      uploadingImage: false,
      submitting: false,
    },
    loading: true,
    error: null,
  },
  reducers: {
    setPage: (state, action) => {
      const { page, error } = action.payload;
      state.page = page;
      state.error = error;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    closeModal: (state) => {
      state.modal = { isNew: false, editedProductUpdates: null, ProductUpdatesImage: null };
    },
    openNewProductUpdatesModal: (state) => {
      state.modal = { isNew: true, editedProductUpdates: null, ProductUpdatesImage: null };
    },
    openEditProductUpdatesModal: (state, action) => {
      state.modal = { isNew: false, editedProductUpdates: action.payload };
    },
    setSubmitting: (state, action) => {
      state.modal.submitting = action.payload;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
  },
});

export const {
  updateSearch,
  setSubmitting,
  openEditProductUpdatesModal,
  setPage,
  openNewProductUpdatesModal,
  setLoading,
  closeModal,
} = userSlice.actions;

export default userSlice.reducer;

export const loadPage = (offset, limit) => (dispatch, getState) => {
  const { search } = getState().productUpdates;
  dispatch(setLoading(true));
  listProductUpdates(offset, limit, search)
    .then(({ items }) => {
      dispatch(setLoading(false));
      dispatch(setPage({ page: { items, offset, limit }, error: null }));
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(
        setPage({
          page: {
            items: [],
            offset: 0,
            limit: 3000,
            total: 0,
          },
          error: error.toString(),
        }),
      );
    });
};

export const addProductUpdates = (productUpdates) => async (dispatch, getState) => {
  dispatch(setSubmitting(true));

  try {
    await apiAdd({ ...productUpdates });
    dispatch(closeModal());
    const { page } = getState().productUpdates;
    dispatch(loadPage(page.offset, page.limit));
    dispatch(notifySuccess('Product update added'));
  } catch (err) {
    dispatch(notifyError('Failed to add product update'));
  } finally {
    dispatch(setSubmitting(false));
  }
};

export const updateProductUpdates = (productUpdates) => async (dispatch, getState) => {
  dispatch(setSubmitting(true));

  try {
    dispatch(setLoading(true));
    const { page } = getState().productUpdates;
    await apiUpdate(productUpdates.id, { ...productUpdates });
    dispatch(closeModal());
    dispatch(loadPage(page.offset, page.limit));
    dispatch(notifySuccess('product update updated'));
  } catch (err) {
    dispatch(notifyError('Failed to update product update'));
  } finally {
    dispatch(setSubmitting(false));
    dispatch(setLoading(false));
  }
};

export const reloadWithSearch = (search) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().productUpdates;
  dispatch(updateSearch(search));
  dispatch(loadPage(0, limit));
};

export const deleteProductUpdates = (productUpdates) => async (dispatch, getState) => {
  const { page } = getState().productUpdates;
  dispatch(setSubmitting(true));
  try {
    await apiDelete(productUpdates.id);
    dispatch(notifySuccess('productUpdates is deleted'));
    dispatch(loadPage(page.offset, page.limit));
  } catch (error) {
    dispatch(notifyError(`Failed to delete productUpdates ${productUpdates.id}`));
  } finally {
    dispatch(setSubmitting(false));
  }
};
