import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { listGlobalAccessLog } from '../../api/gatewise_api';
import { AppDispatch, RootState } from '../../app/store';
import { notifyError, notifySuccess } from '../notifications/notificationsSlice';
import moment from 'moment';
import { hasNext, initialPagination, Paginable, paginationReducers } from '../pagination/paginationStateHelpers';
import { generateGlobalAccessLogExcel } from './utils';
import { TimeFrame } from '../../utils/time';

export type AccessLogsFilter = {
  timeFrame: Partial<TimeFrame>;
  search?: string;
};

export interface AccessLogItem {
  id: string;
  created_at: string;
  member_name: string;
  phone: string;
  access_point_name: string;
  press_tool: string;
  press_timestamp: number;
  device_hw: string;
  device_os: string;
  gsm_success_timestamp: number;
  ble_success_timestamp: number;
  gsm_fail_reason: string;
  ble_fail_reason: string;
  community_name: string;
  community_id: string;
  serial_number: string;
  camera_id: number | null;
  camera_sn: string | null;
  snapshot?: string;
  visitor_key_id: number | null;
  prospect: boolean | null;
  prospect_name: string | null;
  vendor: boolean | null;
  vendor_name: string | null;
}

interface AccessLogState extends Paginable {
  items: AccessLogItem[];
  filter: AccessLogsFilter;
  loaded: boolean;
}

const initialState: AccessLogState = {
  ...initialPagination,
  items: [],
  filter: {
    timeFrame: { to: moment().endOf('day').toDate().getTime() },
  },
  loaded: false,
};

const { actions, reducer } = createSlice({
  name: 'globalAccessLog',
  initialState,
  reducers: {
    ...paginationReducers,
    setItems: (state, action: PayloadAction<AccessLogItem[]>) => {
      state.items = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loaded = !action.payload;
    },
    updateFilter: (state, action: PayloadAction<Partial<AccessLogsFilter>>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
  },
});

export const { updateFilter, updatePagination } = actions;

export const accessLogsState = (state: RootState) => state.globalAccessLog;
export const isLoaded = (state: RootState) => accessLogsState(state).loaded;
export const logs = (state: RootState) => accessLogsState(state).items;
export const filter = (state: RootState) => accessLogsState(state).filter;
export const pagination = (state: RootState) => accessLogsState(state).pagination;
export const hasNextPage = (state: RootState) => {
  const { pagination, items } = accessLogsState(state);
  return hasNext(pagination, items);
};

export default reducer;

export const loadPage =
  (current: number, perPage: number, filter: AccessLogsFilter) => async (dispatch: AppDispatch) => {
    dispatch(actions.setLoading(true));
    try {
      const { items } = await listGlobalAccessLog(current * perPage, perPage, filter.timeFrame, filter.search);
      dispatch(actions.setItems(items));
    } catch (e) {
      dispatch(notifyError(e));
      dispatch(actions.resetPagination());
    } finally {
      dispatch(actions.setLoading(false));
    }
  };

export const exportToExcel = (items: AccessLogItem[], onDone: any) => async (dispatch: AppDispatch) => {
  try {
    generateGlobalAccessLogExcel(items);
    dispatch(notifySuccess('Export completed'));
  } catch (err) {
    dispatch(notifyError(`Failed to export - ${err}`));
  } finally {
    if (onDone) {
      onDone();
    }
  }
};
