import React, { FC, useRef, useState } from 'react';
import {
  Box,
  CardMedia,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  MoreHoriz,
  PermIdentityOutlined,
  Pets,
  DirectionsCarOutlined,
  VisibilityOutlined,
  StarBorderOutlined,
  LocalShippingOutlined,
  Star,
  Inbox,
} from '@material-ui/icons';
import { ECameraRpiEventLabel, ICameraRpiEvent } from '../../../app/domain/ICameraRpiEvent';
import CameraRpiEventActions from './CameraRpiEventActions';

interface IProps {
  items: ICameraRpiEvent[];
  onChangeSeenStatus: (cameraId: string, eventId: string, status: boolean) => void;
  onChangeImportantStatus: (cameraId: string, eventId: string, status: boolean) => void;
  onStartEventStream: (event: ICameraRpiEvent) => void;
}

const CameraRpiEventsTable: FC<IProps> = (props) => {
  const classes = useStyles();

  const { items, onChangeSeenStatus, onChangeImportantStatus, onStartEventStream } = props;
  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState<Element | null>(null);
  const selectedItemRef = useRef<ICameraRpiEvent | null>(null);

  const onOpenActionMenuHandle = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    currentItem: ICameraRpiEvent,
  ) => {
    selectedItemRef.current = currentItem;
    setActionsMenuAnchorEl(event.currentTarget);
  };

  const onCloseActionMenuHandle = () => {
    setActionsMenuAnchorEl(null);
    selectedItemRef.current = null;
  };

  const formatTimeDifference = (timestamp1: number, timestamp2: number): string => {
    // Calculate the difference in seconds
    const diffInSeconds = Math.abs(timestamp1 - timestamp2);

    // We translate the difference into minutes and seconds
    const minutes = Math.floor(diffInSeconds / 60);
    const seconds = diffInSeconds % 60;

    // Format the minutes and seconds in the string "MM:SS"
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${minutes}m ${formattedSeconds}s`;
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="160">Snapshot</TableCell>
            <TableCell>Captures On</TableCell>
            <TableCell width="250">Recorded Actions</TableCell>
            <TableCell width="200">Tags</TableCell>
            <TableCell align="center" width={'100px'}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((it) => (
            <TableRow key={it.id} hover>
              <TableCell>
                <Box className={classes.mediaWrapper} onClick={() => onStartEventStream(it)}>
                  <CardMedia
                    className={classes.media}
                    image={`${process.env.REACT_APP_IMAGE_RESIZER_URL}/snapshots/${it.cameraRpi.cameraId}/${it.eventId}?aspectRatio=1:1&width=300`}
                    title={it.cameraRpi.name}
                  />
                </Box>
              </TableCell>
              <TableCell>
                <Box className={classes.capturesWrapper}>
                  <Typography variant="h4" component="span">
                    {it.cameraRpi.name}
                  </Typography>
                  <Typography variant="subtitle2" component="span">
                    {it.createdAt}
                  </Typography>
                  <Typography variant="body2" component="span">
                    {formatTimeDifference(it.beforeStartTime, it.afterEndTime)}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box className={classes.tagsCell}>
                  {getChipComponent(it.label)}
                  {!!it.subLabel && getChipComponent(it.subLabel.toLowerCase())}
                </Box>
              </TableCell>
              <TableCell>
                <Box className={classes.tagsCell}>
                  {it.tags.seen && <CameraRpiEventChip icon={<VisibilityOutlined fontSize="small" />} label="Seen" />}
                  {it.tags.important && (
                    <CameraRpiEventChip
                      icon={<StarBorderOutlined fontSize="small" />}
                      label="Important"
                      color="light-pink"
                    />
                  )}
                </Box>
              </TableCell>
              <TableCell align="center">
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={(event) => onOpenActionMenuHandle(event, it)}
                >
                  <MoreHoriz />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <CameraRpiEventActions
          anchorEl={actionsMenuAnchorEl}
          onCloseActionMenuHandle={onCloseActionMenuHandle}
          cameraRpEvent={selectedItemRef.current}
          onChangeSeenStatus={onChangeSeenStatus}
          onChangeImportantStatus={onChangeImportantStatus}
        />
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles(() => ({
  mediaWrapper: {
    cursor: 'pointer',
    borderRadius: '0.5rem',
    overflow: 'hidden',
  },
  media: {
    aspectRatio: '1/1',
    position: 'relative',
    backgroundSize: 'cover',
  },
  capturesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.375rem',
  },
  tagsCell: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    justifyContent: 'center',
    alignItems: 'start',
  },
}));

export default CameraRpiEventsTable;

const BootstrapTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    boxShadow: theme.shadows[1],
    color: theme.palette.common.black,
    fontSize: '0.875rem',
    borderRadius: '0.5rem',
    maxWidth: '16rem',
    padding: '0.5rem',
    margin: '0.5rem',
  },
}))(Tooltip);

interface ICameraRpiEventChipProps {
  icon: React.ReactNode;
  label: string;
  color?: 'light-aqua' | 'light-yellow' | 'light-lavender' | 'pale-canary' | 'light-pink' | 'soft-grey';
  tooltipLabel?: string;
}

const CameraRpiEventChip: FC<ICameraRpiEventChipProps> = (props) => {
  const { icon, label, color, tooltipLabel } = props;
  const classes = cameraRpiEventChipStyles();

  const getColorClass = () => {
    switch (color) {
      case 'light-aqua':
        return classes.lightAqua;
      case 'light-yellow':
        return classes.lightYellow;
      case 'light-lavender':
        return classes.lightLavender;
      case 'pale-canary':
        return classes.paleCanary;
      case 'light-pink':
        return classes.lightPink;
      case 'soft-grey':
        return classes.softGrey;
      default:
        return '';
    }
  };

  return (
    <BootstrapTooltip title={tooltipLabel || ''} placement="right">
      <Box className={`${classes.root} ${getColorClass()}`}>
        {icon}
        <span>{label}</span>
      </Box>
    </BootstrapTooltip>
  );
};

const cameraRpiEventChipStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    gap: '5px',
    fontSize: '0.75rem',
    borderRadius: '16px',
    alignItems: 'center',
    lineHeight: '1rem',
    height: '28px',
    paddingInline: '7px 10px',
    fontWeight: 500,
    backgroundColor: '#f7fafc',
    cursor: 'pointer',
  },
  lightAqua: {
    color: '#134e4a',
    backgroundColor: '#ccfbf1',
  },
  lightYellow: {
    color: '#365314',
    backgroundColor: '#ecfccb',
  },
  lightLavender: {
    color: '#4c1d95',
    backgroundColor: '#ede9fe',
  },
  paleCanary: {
    color: '#78350f',
    backgroundColor: '#fef3c7',
  },
  lightPink: {
    color: '#881337',
    backgroundColor: '#ffe4e6',
  },
  softGrey: {
    color: '#000000',
    backgroundColor: '#ebebeb',
  },
}));

const getChipComponent = (type: string, tooltipLabel?: string) => {
  switch (type) {
    case ECameraRpiEventLabel.PEOPLE:
      return (
        <CameraRpiEventChip
          key={type}
          icon={<PermIdentityOutlined fontSize="small" />}
          label="People"
          color="light-yellow"
          tooltipLabel={tooltipLabel}
        />
      );
    case ECameraRpiEventLabel.CARS:
      return (
        <CameraRpiEventChip
          key={type}
          icon={<DirectionsCarOutlined fontSize="small" />}
          label="Vehicles"
          color="light-aqua"
          tooltipLabel={tooltipLabel}
        />
      );
    case ECameraRpiEventLabel.DELIVERY:
      return (
        <CameraRpiEventChip
          key={type}
          icon={<LocalShippingOutlined fontSize="small" />}
          label="Delivery"
          color="pale-canary"
          tooltipLabel={tooltipLabel}
        />
      );
    case ECameraRpiEventLabel.POLICE:
      return (
        <CameraRpiEventChip
          key={type}
          icon={<Star fontSize="small" />}
          label="Police"
          color="soft-grey"
          tooltipLabel={tooltipLabel}
        />
      );
    case ECameraRpiEventLabel.DOGS:
      return (
        <CameraRpiEventChip
          key={type}
          icon={<Pets fontSize="small" />}
          label="Dogs"
          color="light-lavender"
          tooltipLabel={tooltipLabel}
        />
      );
    case ECameraRpiEventLabel.CATS:
      return (
        <CameraRpiEventChip
          key={type}
          icon={<Pets fontSize="small" />}
          label="Cats"
          color="light-lavender"
          tooltipLabel={tooltipLabel}
        />
      );
    case ECameraRpiEventLabel.PACKAGE:
      return (
        <CameraRpiEventChip
          key={type}
          icon={<Inbox fontSize="small" />}
          label="Package"
          color="pale-canary"
          tooltipLabel={tooltipLabel}
        />
      );
    default:
      return null;
  }
};
