import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export function Divider(): JSX.Element {
  const classes = useStyles();
  return <div className={classes.separator} />;
}

const useStyles = makeStyles((theme) => ({
  separator: {
    backgroundColor: 'black',
    opacity: 0.1,
    height: 1,
    margin: theme.spacing(1),
  },
}));
