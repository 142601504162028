import React, { FC } from 'react';
import { ICameraRpi } from '../../../app/domain/ICameraRpi';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {
  open: boolean;
  onClose: () => void;
  cameraRpi: ICameraRpi;
  hotspotPassword: string | null;
}

const CameraRpiHotspotDialog: FC<IProps> = (props) => {
  const classes = useStyles();

  const handleCopyPassword = () => {
    if (!props.hotspotPassword) return;

    try {
      navigator.clipboard.writeText(props.hotspotPassword).then();
    } catch (e) {
      console.error('Failed to copy: ', e);
    }
  };

  return (
    <Dialog maxWidth={'sm'} fullWidth onClose={props.onClose} open={props.open}>
      <DialogTitle className={classes.title}>{`${props.hotspotPassword ? 'Enabled' : 'Enabling'} Wi-Fi for camera ${
        props?.cameraRpi.name
      }`}</DialogTitle>
      <IconButton className={classes.closeIconBtn} onClick={props.onClose}>
        <Close />
      </IconButton>
      <DialogContent>
        {props.hotspotPassword ? (
          <>
            <Box display="flex" alignItems="center">
              <Typography variant="body1">{'Wi-Fi Name:'}</Typography>
              <Typography variant="body1" style={{ marginLeft: 8, fontWeight: 'bold' }}>
                {'GatewiseHotspot'}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body1">{'Password:'}</Typography>
              <Typography variant="body1" style={{ marginLeft: 8, fontWeight: 'bold' }}>
                {props.hotspotPassword}
              </Typography>
              <Tooltip title="Copy to clipboard">
                <IconButton aria-valuetext={'Copy'} onClick={handleCopyPassword} aria-label="copy" aria-haspopup="true">
                  <FileCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        ) : (
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress size={100} />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CameraRpiHotspotDialog;

const useStyles = makeStyles((theme) => ({
  title: {
    '& h2': {
      letterSpacing: '-0.025rem',
      lineHeight: '1rem',
      textTransform: 'capitalize',
      fontSize: '1.125rem',
    },
  },
  closeIconBtn: {
    position: 'absolute',
    right: 8,
    top: 1,
    color: theme.palette.grey[500],
  },
  videoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '800px',
    aspectRatio: '16 / 9',
  },
}));
