import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IResidentsRepo, IResidentsRepoKey } from '../../../app/repos/IResidentsRepo';
import { AppSelector, AppThunk } from '../../../app/store';
import { IInvitation, IResident, IResidentValue } from '../../../app/domain/IResident';
import { IAccessPointSyncItem, IAccessPointSyncItemValue } from '../../../app/stores/IAccessPointSyncItem';
import { notifyError } from '../../notifications/notificationsSlice';
import { IInvitationRepo, IInvitationRepoKey } from '../../../app/repos/IInvitationRepo';
import { IAccessPointSyncRepo, IAccessPointSyncRepoKey } from '../../../app/repos/IAccessPointSyncRepo';
import { IKeypadsRepo, IKeypadsRepoKey } from '../../../app/repos/IKeypadsRepo';
import { IKeypadViewItem, KeypadViewItem } from './IKeypadViewItem';
import { isPresent } from '../../../utils/validation';
import { IKeypadFormDialogResident, KeypadFormDialogResident } from './KeypadFormDialog';
import { GateSyncStateViewItem, IGateSyncStateViewItem } from './IGateSyncStateViewItem';

interface IKeypadsState {
  residents: IResident[];
  invitations: IInvitation[];
  syncItems: IAccessPointSyncItem[];
  loadingSyncItems: string[];
}

const initialState: IKeypadsState = {
  residents: [],
  invitations: [],
  syncItems: [],
  loadingSyncItems: [],
};

const { reducer, actions } = createSlice({
  name: 'keypads',
  initialState,
  reducers: {
    setResidents: (state, action: PayloadAction<IResident[]>) => {
      state.residents = action.payload;
    },
    setInvitations: (state, action: PayloadAction<IInvitation[]>) => {
      state.invitations = action.payload;
    },
    updateInvitation: (state, action: PayloadAction<{ id: string; value: Partial<IResidentValue> }>) => {
      const { id, value } = action.payload;
      state.invitations = state.invitations.map((i) => (i.id === id ? { ...i, ...value } : i));
    },
    updateResident: (state, action: PayloadAction<{ id: string; value: Partial<IResidentValue> }>) => {
      const { id, value } = action.payload;
      state.residents = state.residents.map((i) => (i.id === id ? { ...i, ...value } : i));
    },
    setSyncItems: (state, action: PayloadAction<IAccessPointSyncItem[]>) => {
      state.syncItems = action.payload;
    },
    updateSyncItem: (state, action: PayloadAction<{ id: string; value: Partial<IAccessPointSyncItemValue> }>) => {
      const { id, value } = action.payload;
      state.syncItems = state.syncItems.map((i) => (i.id === id ? { ...i, ...value } : i));
    },
    updateLoadingStatus: (state, action: PayloadAction<{ id: string; isLoading: boolean }>) => {
      const { id, isLoading } = action.payload;
      state.loadingSyncItems = isLoading
        ? state.loadingSyncItems.concat(id)
        : state.loadingSyncItems.filter((it) => it !== id);
    },
  },
});

export const loadResidentsByCommunityId =
  (communityId: string): AppThunk<void> =>
  async (dispatch, getState, { container }) => {
    try {
      const residentsRepo = container.resolve<IResidentsRepo>(IResidentsRepoKey);
      const residents = await residentsRepo.fetchResidentsByCommunityId(communityId);
      dispatch(actions.setResidents(residents));
    } catch (e) {
      dispatch(notifyError(e));
    }
  };

export const loadInvitationsByCommunityId =
  (communityId: string): AppThunk<void> =>
  async (dispatch, getState, { container }) => {
    try {
      const invitationRepo = container.resolve<IInvitationRepo>(IInvitationRepoKey);
      const invitations = await invitationRepo.fetchInvitationsByCommunity(communityId);
      dispatch(actions.setInvitations(invitations));
    } catch (e) {
      dispatch(notifyError(e));
    }
  };

export const loadSyncItemsByCommunityId =
  (communityId: string): AppThunk<void> =>
  async (dispatch, getState, { container }) => {
    try {
      const accessPointSyncRepo = container.resolve<IAccessPointSyncRepo>(IAccessPointSyncRepoKey);
      const syncItems = await accessPointSyncRepo.findManyByCommunityId(communityId);
      dispatch(actions.setSyncItems(syncItems));
    } catch (e) {
      dispatch(notifyError(e));
    }
  };

export const updateInvitationKeypad =
  (communityId: string, id: string, keypad: string | null): AppThunk<void> =>
  async (dispatch, getState, { container }) => {
    try {
      const invitationRepo = container.resolve<IInvitationRepo>(IInvitationRepoKey);
      const invitation = await invitationRepo.updateInvitation(communityId, id, { keypad });
      dispatch(actions.updateInvitation({ id, value: invitation }));
    } catch (e) {
      dispatch(notifyError(e));
    }
  };

export const updateResidentKeypad =
  (communityId: string, id: string, keypad: string | null): AppThunk<void> =>
  async (dispatch, getState, { container }) => {
    try {
      const residentsRepo = container.resolve<IResidentsRepo>(IResidentsRepoKey);
      const resident = await residentsRepo.updateResident(communityId, id, { keypad });
      dispatch(actions.updateResident({ id, value: resident }));
    } catch (e) {
      dispatch(notifyError(e));
    }
  };

export const generateKeypad =
  (communityId: string): AppThunk<string> =>
  async (dispatch, getState, { container }) => {
    try {
      const keypadsRepo = container.resolve<IKeypadsRepo>(IKeypadsRepoKey);
      return keypadsRepo.generateKeypad(communityId);
    } catch (e) {
      dispatch(notifyError(e));
      return '';
    }
  };

export const syncGate =
  (communityId: string, gsmId: string): AppThunk<void> =>
  async (dispatch, getState, { container }) => {
    try {
      const accessPointSyncRepo = container.resolve<IAccessPointSyncRepo>(IAccessPointSyncRepoKey);
      dispatch(actions.updateLoadingStatus({ id: gsmId, isLoading: true }));
      const item = await accessPointSyncRepo.syncStateItem(communityId, gsmId);
      dispatch(actions.updateSyncItem({ id: gsmId, value: item }));
      dispatch(actions.updateLoadingStatus({ id: gsmId, isLoading: false }));
    } catch (e) {
      dispatch(actions.updateLoadingStatus({ id: gsmId, isLoading: false }));
      dispatch(notifyError(e));
    }
  };

const getResidentsWithKeypad: AppSelector<IResident[]> = ({ keypads }) => {
  const { residents } = keypads;
  return residents.filter((it) => !!it.keypad);
};

const getInvitationsWithKeypad: AppSelector<IInvitation[]> = ({ keypads }) => {
  const { invitations } = keypads;
  return invitations.filter((it) => !!it.keypad);
};

export const getKeypads: AppSelector<IKeypadViewItem[]> = (state) => {
  const { keypads } = state;
  const { syncItems, loadingSyncItems } = keypads;
  const residents = getResidentsWithKeypad(state);
  const invitations = getInvitationsWithKeypad(state);
  return residents
    .map((it) => KeypadViewItem.fromResidentsAndSyncItems(it, syncItems, loadingSyncItems))
    .concat(invitations.map((it) => KeypadViewItem.fromResidentsAndSyncItems(it, syncItems, loadingSyncItems, true)))
    .filter(isPresent);
};

export const getResidentsWithoutKeypads: AppSelector<IKeypadFormDialogResident[]> = ({ keypads }) => {
  const { invitations, residents } = keypads;
  return residents
    .map((r) => new KeypadFormDialogResident(r))
    .concat(invitations.map((r) => new KeypadFormDialogResident(r, true)));
};

export const getKeypadSyncStateItems =
  (id: string | null, pending?: boolean): AppSelector<IGateSyncStateViewItem[] | null> =>
  ({ keypads, accessPoints }) => {
    const { invitations, residents, syncItems } = keypads;
    if (id === null) {
      return null;
    }
    const resident = (pending ? invitations : residents).find((i) => i.id === id);
    if (!resident) {
      throw new Error('Cannot find resident');
    }
    return syncItems
      .filter((s) => {
        return s.codes.includes(resident.keypad!) && !s.committedCode.includes(resident.keypad!);
      })
      .map((s) => {
        return GateSyncStateViewItem.fromAccessPointAndSyncItems(
          s,
          accessPoints.items,
          keypads.loadingSyncItems.includes(s.id),
        );
      })
      .filter(isPresent);
  };

export default reducer;
