import React, { useCallback } from 'react';
import { Button, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';

import { getInitialSchedule } from './utils';
import { ScheduleItem } from './ScheduleItem';

export interface IAvailabilityHours {
  start: string; // "08:00" - 24-hour format
  durationMinutes: number;
}

export interface IAvailabilitySchedule {
  id: number;
  days: number[]; // weekdays 0-6
  hours: IAvailabilityHours;
}

export interface ScheduleProps {
  name?: string;
  schedules?: IAvailabilitySchedule[];
  onChange: (schedules: IAvailabilitySchedule[]) => void;
  errorMessage?: string;
}

export const ScheduleV2: React.FC<ScheduleProps> = ({
  name = 'Schedule',
  schedules = [getInitialSchedule(0)],
  onChange,
  errorMessage,
}) => {
  const classes = useStyles();

  const addSchedule = useCallback(() => {
    onChange([...schedules, getInitialSchedule(schedules.length)]);
  }, [onChange, schedules]);

  const removeSchedule = useCallback(
    (scheduleId: number) => {
      onChange(schedules.filter((schedule) => schedule.id !== scheduleId));
    },
    [onChange, schedules],
  );

  return (
    <div className={classes.root}>
      {schedules.map((schedule) => (
        <ScheduleItem
          key={schedule.id}
          name={name}
          removeSchedule={() => removeSchedule(schedule.id)}
          schedule={schedule}
          onChange={(schedule) => {
            onChange(schedules.map((s) => (s.id === schedule.id ? schedule : s)));
          }}
        />
      ))}

      <Button variant="text" onClick={addSchedule}>
        <Add className="w-4 h-4 mr-2" />
        Add {name.toLowerCase()}
      </Button>

      <Typography color="error">{errorMessage}</Typography>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '0.5rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    gap: '1rem',
  },
}));
