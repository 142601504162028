import actionCreatorFactory, { Action, ActionCreator, ActionCreatorFactory, AnyAction } from 'typescript-fsa';
import { filter, Observable } from 'rxjs';

export class EpicAction<Payload = undefined> {
  private action: ActionCreator<Payload>;

  constructor(name: string, authActionCreator: ActionCreatorFactory) {
    this.action = authActionCreator(name);
  }

  filter(action$: Observable<AnyAction>): Observable<Action<Payload>> {
    return action$.pipe(filter(this.action.match));
  }

  invoke(payload: Payload) {
    return this.action(payload);
  }
}

export class EpicActionFactory {
  private readonly authActionCreator: ActionCreatorFactory;
  constructor(topic: string) {
    this.authActionCreator = actionCreatorFactory(topic);
  }

  create(name: string) {
    return new EpicAction(name, this.authActionCreator);
  }
}
