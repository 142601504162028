import { IResident, IResidentValue } from '../domain/IResident';
import { by, inject, keys } from '../../injectDecorators';
import { IAuthorizer, IAuthorizerKey } from '../../api/IAuthorizer';
import axios, { AxiosInstance } from 'axios';

export const IResidentsRepoKey = Symbol('IResidentsRepo');

export interface IResidentsRepo {
  fetchResidentsByCommunityId(communityId: string): Promise<IResident[]>;

  updateResident(communityId: string, id: string, payload: Partial<IResidentValue>): Promise<Partial<IResidentValue>>;
}

@keys(IResidentsRepoKey)
export class ResidentsRepo implements IResidentsRepo {
  constructor(@inject(by(IAuthorizerKey)) private authorizer: IAuthorizer) {}

  async fetchResidentsByCommunityId(communityId: string): Promise<IResident[]> {
    const httpClient = await this.createHttpClient();
    const response = await httpClient.get(`community/${communityId}/member/all`);
    return response.data;
  }

  async updateResident(
    communityId: string,
    id: string,
    payload: Partial<IResidentValue>,
  ): Promise<Partial<IResidentValue>> {
    const httpClient = await this.createHttpClient();
    const response = await httpClient.patch(`community/${communityId}/member/${id}`, payload);
    return response.data;
  }

  private async createHttpClient(): Promise<AxiosInstance> {
    return axios.create({
      baseURL: process.env.API_URL_CLUSTER,
      headers: {
        Authorization: `Bearer ${await this.authorizer.getIdToken()}`,
      },
    });
  }
}
