import React, { FC, useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core';

interface Player {
  autoplay: (autoplay: boolean) => void;
  src: (sources: any) => void;
  isDisposed: () => boolean;
  dispose: () => void;
}

interface IProps {
  url: string | null;
  live?: boolean;
  autoplay?: boolean;
  aspectRatio?: '16:9' | '16:10' | '4:3' | '1:1';
  onReady?: (player: Player) => void;
}

const VideoJsPlayer: FC<IProps> = (props) => {
  const { url, live, autoplay, aspectRatio, onReady } = props;

  const classes = useStyles();

  const videoRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<Player | null>(null);

  const [videoIsDone, setVideoIsDone] = useState(false);

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current && videoRef.current) {
      const player = (playerRef.current = videojs(
        videoRef.current,
        {
          controls: true,
          autoplay: !!autoplay,
          preload: 'auto',
          liveui: live,
          sources: [
            {
              src: url,
            },
          ],
          plugins: {},
          aspectRatio: aspectRatio,
        },
        () => {
          videojs.log('videojs player is ready');
          setVideoIsDone(true);
          onReady && onReady(player);
        },
      ));

      player.on('error', (e: any) => {
        if (!videoIsDone) {
          setVideoIsDone(true);
        }
        videojs.log('An error occurred:', e);
      });

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;
      if (player && url) {
        player.src({ src: url });
        player.autoplay(!!autoplay);
      }
    }
    // eslint-disable-next-line
  }, [url, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div className={classes.videoJsWrapper}>
      <div data-vjs-player>
        {!videoIsDone && (
          <Box className={classes.spinnerWrapper}>
            <CircularProgress size={100} />
          </Box>
        )}
        <video ref={videoRef} className={`video-js ${classes.videoJsPlayer}`} data-setup="{}"></video>
      </div>
    </div>
  );
};

export default VideoJsPlayer;

const useStyles = makeStyles(() => ({
  videoJsWrapper: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  videoJsPlayer: {
    width: '100%',
    height: '100%',
  },
  spinnerWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    zIndex: 99,
  },
}));
