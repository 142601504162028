import { FirebaseApp } from 'firebase/app';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { v4 as uuid } from 'uuid';

export class ImageManager {
  constructor(private app: FirebaseApp) {}

  async uploadImage(imageFile: ArrayBuffer) {
    const imageRef = ref(getStorage(this.app), `/community_images/${uuid()}`);
    const snapshot = await uploadBytes(imageRef, imageFile);
    return getDownloadURL(snapshot.ref);
  }
}
