import { RootState } from '../../app/store';
import { createSelector } from 'reselect';
import { sortTwoFields } from '../../utils/sorting';

const getDailyErrors = (state: RootState) => state.dailyErrors;

export const getDailyErrorsPage = (state: RootState) => getDailyErrors(state).page;
export const getDailyErrorsPageItems = (state: RootState) => getDailyErrors(state).page.items;
export const getDailyErrorsPageSortSettings = (state: RootState) => getDailyErrors(state).sortOptions;
export const getSortedDailyErrorsPageItems = createSelector(
  getDailyErrorsPageSortSettings,
  getDailyErrorsPageItems,
  ({ direction, field }, items) => {
    if (!direction) {
      return items;
    }
    return [...items].sort((a, b) => {
      const fieldA = a[field];
      const fieldB = b[field];
      return sortTwoFields(fieldA, fieldB, direction);
    });
  },
);

const getAppSettings = (state: RootState) => getDailyErrorsPage(state).settings;
export const getAccessPoint = (state: RootState, id: string) => {
  const item = getDailyErrorsPage(state).items.find((item) => item.id === id);
  if (!item) {
    throw new Error(`Entity not found ${id}`);
  }
  return item;
};
export const getFailuresPercentThreshold = (state: RootState) => getAppSettings(state)?.failuresPercentThreshold;
