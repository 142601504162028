import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  addErrorListener,
  addProductUpdatesViewDate as addProductUpdatesViewDateApi,
  getCurrentUser,
  getProductUpdatesViewDate as getProductUpdatesViewDateApi,
  setRequestListener,
} from '../../api/gatewise_api';
import { notifyError, notifySuccess } from '../notifications/notificationsSlice';
import { Role } from '../../models/Role';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    profile: null,
    loading: false,
    pageLoading: true,
    productUpdates: [],
    productInterval: null,
    productUpdatesViewDate: {},
    customToken: '',
  },
  reducers: {
    setUserProfile: (state, action) => {
      state.profile = action.payload;
      state.loading = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload;
    },
    setProductUpdates: (state, action) => {
      state.productUpdates = action.payload;
    },
    setProductInterval: (state, action) => {
      state.productInterval = action.payload;
    },
    setProductUpdatesViewDate: (state, action) => {
      state.productUpdatesViewDate = action.payload.date;
    },
    setCustomToken: (state, action) => {
      state.customToken = action.payload;
    },
  },
});

export const {
  setLoading,
  setUserProfile,
  setPageLoading,
  setProductUpdates,
  setProductUpdatesViewDate,
  setCustomToken,
} = userSlice.actions;

export default userSlice.reducer;

export const signOut =
  () =>
  (dispatch, getState, { authorizer }) => {
    authorizer.signOut();
    const { productInterval } = getState().user;
    clearInterval(productInterval);
    dispatch(setUserProfile(null));
  };

function signInUser() {
  return async (dispatch) => {
    try {
      dispatch(setPageLoading(true));
      const profile = await getCurrentUser();
      dispatch(setCustomToken(profile.customToken));
      dispatch(setUserProfile(profile));
    } catch (e) {
      dispatch(signOut());
      dispatch(notifyError(e.toString()));
    } finally {
      dispatch(setPageLoading(false));
    }
  };
}

function signOutUser() {
  return (dispatch) => {
    dispatch(setPageLoading(true));
    dispatch(signOut());
    dispatch(setPageLoading(false));
  };
}

export const login =
  (email, password) =>
  async (dispatch, getState, { authorizer }) => {
    try {
      dispatch(setLoading(true));
      await authorizer.signInWithEmailAndPassword(email, password);
    } catch (err) {
      dispatch(notifyError(err));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const forgotPassword =
  (email) =>
  async (dispatch, getState, { authorizer }) => {
    try {
      dispatch(setLoading(true));
      await authorizer.sendPasswordResetEmail(email);
      dispatch(notifySuccess('Reset Password email was sent'));
    } catch (err) {
      dispatch(notifyError(err));
    } finally {
      dispatch(setLoading(false));
    }
  };

const shouldResendResetPasswordEmail = (err) =>
  err.code === 'auth/expired-action-code' ||
  err.code === 'auth/invalid-action-code' ||
  err.message === 'EXPIRED_OOB_CODE' ||
  err.message === 'INVALID_OOB_CODE';

export const resetPassword =
  (newPassword, code, history, email) =>
  async (dispatch, getState, { authorizer }) => {
    try {
      dispatch(setLoading(true));
      await authorizer.confirmPasswordReset(newPassword, code);
      dispatch(notifySuccess('Password was reset successfully'));
      history.push('/auth/login');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      if (shouldResendResetPasswordEmail(err)) {
        await authorizer.sendPasswordResetEmail(email);
        dispatch(notifyError('Link was expired! - resending a new one now, Please check your inbox.'));
      } else {
        dispatch(notifyError(err.message));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

export const monitorUserAuthentication = () => (dispatch, getState, deps) => {
  const { authorizer } = deps;
  addErrorListener((err) => {
    // eslint-disable-next-line no-console
    if (!axios.isCancel(err)) console.error(err);
    const s = err?.response?.status;
    if (s === 401 || s === 403) {
      dispatch(signOut());
    }
  });

  setRequestListener(async (request) => {
    if (authorizer.isLoggedIn()) {
      const token = await authorizer.getIdToken();
      request.headers = { ...request.headers, Authorization: `Bearer ${token}` };
    }
    return request;
  });

  authorizer.onAuthStateChanged((user) => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('signOut')) {
      searchParams.delete('signOut');
      window.history.replaceState({}, document.title, `${window.location.pathname}?${searchParams}`);
      return dispatch(signOutUser());
    }

    return user ? dispatch(signInUser()) : dispatch(signOutUser());
  });
};

const permissions = {
  accessPoint: {
    edit: ['superadmin', 'admin', 'manager'],
    editDeviceData: ['superadmin'],
    add: ['superadmin'],
    delete: ['superadmin'],
    reset: ['superadmin'],
    sync: ['superadmin'],
    fetchLogs: ['superadmin'],
  },
  prospectAccess: {
    edit: ['superadmin', 'admin', 'manager'],
    add: ['superadmin', 'admin', 'manager'],
    delete: ['superadmin', 'admin', 'manager'],
  },
  vendorAccess: {
    edit: ['superadmin', 'admin', 'manager'],
    add: ['superadmin', 'admin', 'manager'],
    delete: ['superadmin', 'admin', 'manager'],
  },
  members: {
    addFullAccess: ['superadmin', 'admin'],
  },
  remote: {
    add: ['superadmin'],
    addPincode: ['manager'],
  },
  supportLog: {
    view: ['superadmin'],
  },
  syncLog: {
    view: ['superadmin'],
  },
  groups: {
    addRemoveMembers: [Role.Admin, Role.Worker],
  },
};
export const isAllowedForResourceAndAction = (profile, resource, action, communityId) => {
  if (profile.isAdmin) {
    return true;
  }
  const resourcePermissions = permissions[resource] || {};
  const permittedRoles = resourcePermissions[action] || [];
  const membership = profile.memberships.filter((i) => +i.community_id === +communityId)[0];
  const userRole = membership ? membership.role : null;
  return permittedRoles.includes(userRole);
};

export const isSuperAdmin = (profile) => (profile.roles || []).indexOf('superadmin') >= 0;

export const getProductUpdatesViewDate = () => async (dispatch) => {
  try {
    const { data } = await getProductUpdatesViewDateApi();
    dispatch(setProductUpdatesViewDate(data));
  } catch (err) {
    dispatch(notifyError('Failed to get product update view date'));
  } finally {
    dispatch(setLoading(false));
  }
};

export const addProductUpdatesViewDate = () => async (dispatch) => {
  try {
    await addProductUpdatesViewDateApi();
    dispatch(getProductUpdatesViewDate());
  } catch (err) {
    dispatch(notifyError('Failed to add product update view date'));
  } finally {
    dispatch(setLoading(false));
  }
};
