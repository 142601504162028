import { IContainer } from 'ts-ioc-container';
import { common } from './common';
import { fromClass } from '../injectDecorators';
import { KeypadsRepo } from '../app/repos/IKeypadsRepo';
import { ResidentsRepo } from '../app/repos/IResidentsRepo';
import { UnitLocksRepo } from '../app/repos/IUnitLocksRepo';
import { CamerasRpiRepo } from '../app/repos/ICamerasRpiRepo';
import { CameraRpiEventsRepo } from '../app/repos/ICameraRpiEventsRepo';
import { AccessPointV3Repo } from '../app/repos/IAccessPointV3Repo';

export function production(): IContainer {
  return common()
    .register(fromClass(KeypadsRepo).build())
    .register(fromClass(ResidentsRepo).build())
    .register(fromClass(UnitLocksRepo).build())
    .register(fromClass(CamerasRpiRepo).build())
    .register(fromClass(CameraRpiEventsRepo).build())
    .register(fromClass(AccessPointV3Repo).build());
}
