import React, { FC } from 'react';
import { Button, DialogTitle, IconButton, makeStyles, Box, Typography, CircularProgress } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { ICameraRpiEvent } from '../../../app/domain/ICameraRpiEvent';
import DialogContent from '@material-ui/core/DialogContent';
import { CloudDownload } from '@material-ui/icons';
import VideoJsPlayer from '../../../components/VideoJsPlayer';

interface IProps {
  url: string | null;
  open: boolean;
  cameraRpiEvent: ICameraRpiEvent | null;
  onDownload: (event: ICameraRpiEvent) => void;
  onClose: () => void;
  isDownloading: boolean;
}

const CameraRpiEventStreamDialog: FC<IProps> = (props) => {
  const { url, open, cameraRpiEvent, onDownload, onClose, isDownloading } = props;

  const onDownloadHandle = () => {
    if (cameraRpiEvent) {
      onDownload(cameraRpiEvent);
    }
  };

  const classes = useStyles();
  return (
    <Dialog maxWidth={'lg'} onClose={onClose} open={open}>
      <DialogTitle>
        <Box className={classes.capturesWrapper}>
          <Typography variant="h4" component="span">
            {cameraRpiEvent?.cameraRpi.name}
          </Typography>
          <Typography variant="subtitle2" component="span">
            {cameraRpiEvent?.createdAt}
          </Typography>
        </Box>
      </DialogTitle>
      <IconButton className={classes.closeIconBtn} onClick={onClose}>
        <Close />
      </IconButton>
      <DialogContent>
        <Box className={classes.videoWrapper}>
          {url ? <VideoJsPlayer url={url} live autoplay /> : <CircularProgress size={100} />}
        </Box>
      </DialogContent>
      <DialogActions style={{ padding: '8px 24px' }}>
        <Button variant={'outlined'} onClick={onClose}>
          Close
        </Button>
        <Button
          type="submit"
          variant={'contained'}
          color="primary"
          startIcon={isDownloading ? <CircularProgress size={24} /> : <CloudDownload />}
          disabled={!url || isDownloading}
          onClick={onDownloadHandle}
        >
          Download video
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CameraRpiEventStreamDialog;

const useStyles = makeStyles((theme) => ({
  capturesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.375rem',
  },
  closeIconBtn: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
  videoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '800px',
    aspectRatio: '16 / 9',
  },
}));
