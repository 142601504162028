export interface IInvoice {
  amount: number;
  balance: number;
  date: string;
  id: string;
}

export enum InvoicePaymentStatus {
  Paid = 'Paid',
  Unpaid = 'Unpaid',
  Partial = 'Partial',
}

export function getInvoicePaymentStatus({ balance, amount }: IInvoice): InvoicePaymentStatus {
  if (balance === 0) {
    return InvoicePaymentStatus.Paid;
  }

  if (balance === amount) {
    return InvoicePaymentStatus.Unpaid;
  }

  return InvoicePaymentStatus.Partial;
}
