// AsyncComponent.tsx
import React, { useEffect, useState } from 'react';

type ComponentTypeWithDefault = { default: React.ComponentType<any> };

const asyncComponent = (importComponent: () => Promise<ComponentTypeWithDefault>): React.ComponentType => {
  // eslint-disable-next-line react/display-name
  return (props) => {
    const [LoadedComponent, setLoadedComponent] = useState<React.ComponentType | null>(null);

    useEffect(() => {
      importComponent()
        .then((cmp) => {
          setLoadedComponent(() => cmp.default);
        })
        .catch(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.location.reload(true);
        });
    }, []);

    if (LoadedComponent) {
      return <LoadedComponent {...props} />;
    }

    return null;
  };
};

export default asyncComponent;
