import React, { Suspense, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0, // IE11 fix
    },
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56,
    },
  },
}));

function Dashboard({ children }) {
  const classes = useStyles();
  const user = useSelector((state) => state.user);

  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  if (!user.profile) {
    return <Redirect to="/auth/login" />;
  }
  return (
    <>
      <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
      <NavBar user={user} onMobileClose={() => setOpenNavBarMobile(false)} openMobile={openNavBarMobile} />
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>{children}</Suspense>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
