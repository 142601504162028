import React, { PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SuccessSnackbar from 'src/components/SuccessSnackbar';
import { clearNotifications, getLastNotification } from './notificationsSlice';
import { isPresent } from '../../utils/validation';
import { AlertDialog } from '../../components/AlertDialog';

export function Notifications({}: PropsWithChildren<{}>): JSX.Element {
  const dispatch = useDispatch();
  const notification = useSelector(getLastNotification);
  return (
    <>
      {isPresent(notification) && notification.type === 'error' && (
        <AlertDialog onClose={() => dispatch(clearNotifications())}>{notification.message}</AlertDialog>
      )}
      {isPresent(notification) && (
        <SuccessSnackbar
          open={notification.type === 'success'}
          message={notification.message}
          onClose={() => dispatch(clearNotifications())}
        />
      )}
    </>
  );
}
