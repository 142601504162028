import React, { PropsWithChildren, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';

export function Page({
  title,
  children,
  className,
}: PropsWithChildren<{ title: string; className?: string }>): JSX.Element {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    if ((window as any).gtag) {
      (window as any).gtag('config', process.env.REACT_APP_GA_MEASUREMENT_ID, {
        page_path: location.pathname,
        page_name: title,
      });
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className={className}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
}
