import React from 'react';
import { Grid, LinearProgress } from '@material-ui/core';

export function LoadingScreen(): JSX.Element {
  return (
    <Grid container alignItems="center" justifyContent="center" style={{ flex: 1 }}>
      <LinearProgress style={{ flex: 1, maxWidth: '50%' }} />
    </Grid>
  );
}
