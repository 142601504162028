/* eslint-disable no-unused-vars */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton, AppBar, Button, Toolbar, Hidden, Typography, colors } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import { signOut, isSuperAdmin } from 'src/features/user/userSlice';
import NotificationsPopover from '../../features/product_updates/NotificationsPopover';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit',
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit',
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900],
    },
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  chatButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
}));

function TopBar({ onOpenNavBarMobile, className }) {
  const classes = useStyles();
  const history = useHistory();
  const communityName = useSelector((state) => state.communityDetails.details.name);
  const userProfile = useSelector((state) => state.user.profile);
  const dispatch = useDispatch();
  const superAdmin = isSuperAdmin(userProfile);

  const handleLogout = () => {
    dispatch(signOut());
  };

  const handleMyCommunitiesOpen = () => {
    history.push('/');
  };

  return (
    <AppBar className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <Hidden lgUp>
          <IconButton className={classes.menuButton} color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/">
          <div
            style={{
              flexDirection: 'column',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'space-between',
            }}
          >
            <img style={{ marginBottom: 5 }} width={120} alt="Logo" src="/images/logos/gatewise_logo.png" />
            <img width={120} alt="Logo" src="/images/logos/gatewise_multi.png" />
          </div>
        </RouterLink>
        {communityName && (
          <Typography style={{ marginLeft: 20, color: 'white' }} variant="h4">
            {`${communityName} -
            Easily manage your community access`}
          </Typography>
        )}
        <div className={classes.flexGrow} />
        <Hidden smDown>
          <NotificationsPopover />
          <Button className={classes.trialButton} onClick={handleMyCommunitiesOpen} variant="contained">
            {superAdmin ? 'Back to Admin' : 'My Communities'}
          </Button>
          <Button className={classes.logoutButton} color="inherit" onClick={handleLogout}>
            <InputIcon className={classes.logoutIcon} />
            Sign out
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
  signOut: PropTypes.func,
};

export default TopBar;
