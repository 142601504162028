import { IAccessPointV3 } from '../../../app/domain/AccessPoint';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../../../app/store';
import { notifyError } from '../../notifications/notificationsSlice';
import { AccessPointV3Repo, IAccessPointV3RepoKey } from '../../../app/repos/IAccessPointV3Repo';

interface IAccessPointV3State {
  accessPoints: IAccessPointV3[];
  accessPointsLoading: boolean;
}

const initialState: IAccessPointV3State = {
  accessPoints: [],
  accessPointsLoading: false,
};

const { reducer, actions } = createSlice({
  name: 'accessPointV3',
  initialState,
  reducers: {
    setAccessPoints: (state, action) => {
      state.accessPoints = action.payload;
    },
    setAccessPointsLoading: (state, action) => {
      state.accessPointsLoading = action.payload;
    },
  },
});

export const loadAccessPointByCommunityId =
  (communityId: number): AppThunk<void> =>
  async (dispatch, _getState, { container }) => {
    try {
      dispatch(actions.setAccessPointsLoading(true));
      const accessPointRepo = container.resolve<AccessPointV3Repo>(IAccessPointV3RepoKey);
      const accessPoint = await accessPointRepo.fetchAccessPoint(communityId);
      dispatch(actions.setAccessPoints(accessPoint));
    } catch (e) {
      dispatch(notifyError(e));
    } finally {
      dispatch(actions.setAccessPointsLoading(false));
    }
  };

export default reducer;
