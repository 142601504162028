/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  listGlobaRemotes,
  syncRemote as syncRemoteAPI,
  activateRemote as activateRemoteAPI,
  deactivateRemote as deactivateRemoteAPI,
  deleteRemote as deleteRemoteAPI,
} from '../../../api/gatewise_api';
import { notifyError, notifySuccess } from '../../notifications/notificationsSlice';

const initialState = {
  page: {
    items: [],
    page: 0,
    limit: 10,
    total: 0,
  },
  search: '',
  filter: {
    status: 'all',
    deviceStatus: 'all',
  },
  inProgressActions: {},
  loading: true,
  loaded: false,
  error: null,
};

const globalRemotesSlice = createSlice({
  name: 'globalRemotes',
  initialState,
  reducers: {
    setPage: (state, action) => {
      const { page, error, loaded } = action.payload;
      state.page = page;
      state.error = error;
      state.loaded = loaded;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
    updateFilter: (state, action) => {
      state.filter = action.payload;
    },
    startRemoteAction: (state, action) => {
      const { remoteID, actionType } = action.payload;
      state.inProgressActions[remoteID] = actionType;
    },
    finishRemoteAction: (state, action) => {
      const { remoteID } = action.payload;
      delete state.inProgressActions[remoteID];
    },
  },
});

export const { updateSearch, setCommunity, setPage, setLoading, updateFilter, startRemoteAction, finishRemoteAction } =
  globalRemotesSlice.actions;

export default globalRemotesSlice.reducer;

export const loadPage = (page, limit) => (dispatch, getState) => {
  const {
    search,
    filter: { status, deviceStatus },
  } = getState().globalRemotes;
  dispatch(setLoading(true));
  listGlobaRemotes(search, status, deviceStatus, page * limit, limit, search)
    .then(({ items, total }) => {
      items.sort((a, b) => parseInt(a.serial_number) - parseInt(b.serial_number));
      dispatch(setLoading(false));
      dispatch(
        setPage({
          page: {
            items,
            page,
            limit,
            total,
          },
          loaded: true,
          error: null,
        }),
      );
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(setPage({ page: { items: [], limit: 10 }, error: error.toString() }));
    });
};

export const reloadWithSearch = (search) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().globalRemotes;
  dispatch(updateSearch(search));
  dispatch(loadPage(0, limit));
};

export const reloadWithFilter = (filter) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().globalRemotes;
  dispatch(updateFilter(filter));
  dispatch(loadPage(0, limit));
};

export const reload = () => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().globalRemotes;
  dispatch(loadPage(0, limit));
};

export const activateRemote = (communityID, serial) => (dispatch) => {
  dispatch(startRemoteAction({ remoteID: serial, actionType: 'activate' }));
  activateRemoteAPI(communityID, serial)
    .then(() => {
      dispatch(reload());
      dispatch(notifySuccess('Remote activation was scheduled'));
    })
    .catch(() => {
      dispatch(notifyError('Remote activation failed'));
    })
    .finally(() => {
      dispatch(finishRemoteAction({ remoteID: serial, actionType: 'activate' }));
    });
};

export const deactivateRemote = (communityID, serial) => (dispatch) => {
  dispatch(startRemoteAction({ remoteID: serial, actionType: 'deactivate' }));
  deactivateRemoteAPI(communityID, serial)
    .then(() => {
      dispatch(reload());
      dispatch(notifySuccess('Remote deactivation was scheduled'));
    })
    .catch(() => {
      dispatch(notifyError('Remote deactivation failed'));
    })
    .finally(() => {
      dispatch(finishRemoteAction({ remoteID: serial, actionType: 'deactivate' }));
    });
};
export const syncRemote = (communityID, serial) => (dispatch) => {
  dispatch(startRemoteAction({ remoteID: serial, actionType: 'sync' }));
  syncRemoteAPI(communityID, serial)
    .then(() => {
      dispatch(reload());
      dispatch(notifySuccess('Remote sync was scheduled'));
    })
    .catch(() => {
      dispatch(notifyError('Failed to schedule remote sync'));
    })
    .finally(() => {
      dispatch(finishRemoteAction({ remoteID: serial, actionType: 'sync' }));
    });
};

export const deleteRemote = (communityID, serial) => (dispatch) => {
  dispatch(startRemoteAction({ remoteID: serial, actionType: 'delete' }));
  deleteRemoteAPI(communityID, serial)
    .then(() => {
      dispatch(reload());
      dispatch(notifySuccess('Remote deletion succeeded'));
    })
    .catch(() => {
      dispatch(notifyError('Remote deletion failed'));
    })
    .finally(() => {
      dispatch(finishRemoteAction({ remoteID: serial, actionType: 'delete' }));
    });
};
