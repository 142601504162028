import moment from 'moment';
import React, { PropsWithChildren, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import DateUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { TimeFrame } from '../utils/time';

export function DateRangePicker({
  onChange,
  from,
  to,
  isDateToClearable,
  shouldDisableDateTo = () => false,
  shouldDisableDateFrom = () => false,
}: PropsWithChildren<
  {
    onChange: (range: Partial<TimeFrame>) => void;
    isDateToClearable?: boolean;
    shouldDisableDateTo?: (date: moment.Moment) => boolean;
    shouldDisableDateFrom?: (date: moment.Moment) => boolean;
  } & Partial<TimeFrame>
>): JSX.Element {
  const dateStart = useMemo(() => (from ? new Date(from) : null), [from]);
  const dateStop = useMemo(() => (to ? new Date(to) : null), [to]);
  return (
    <MuiPickersUtilsProvider utils={DateUtils}>
      <Grid container justifyContent="flex-start" spacing={2}>
        <>
          <Grid item>
            <KeyboardDatePicker
              clearable
              autoOk
              format="MM/DD/yyyy"
              margin="none"
              id="date-picker-inline1"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              label="Start Date"
              value={dateStart}
              onChange={(date) => {
                onChange({ from: date && date.isValid ? date.startOf('day').toDate().getTime() : undefined });
              }}
              shouldDisableDate={(date) =>
                date === null || date.startOf('day') > moment(dateStop).startOf('day') || shouldDisableDateFrom(date)
              }
            />
          </Grid>
          <Grid item>
            <KeyboardDatePicker
              clearable={isDateToClearable}
              autoOk
              format="MM/DD/yyyy"
              margin="none"
              id="date-picker-inline1"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              label="End Date"
              value={dateStop}
              onChange={(date) => {
                onChange({ to: date && date.isValid ? date.endOf('day').toDate().getTime() : undefined });
              }}
              shouldDisableDate={(date) =>
                date === null || date.startOf('day') < moment(dateStart).startOf('day') || shouldDisableDateTo(date)
              }
            />
          </Grid>
        </>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
