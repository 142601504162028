import { PayloadAction } from '@reduxjs/toolkit';

export type Pagination = {
  current: number;
  perPage: number;
};

export type PaginationOptions = Pagination & {
  total: number;
};

export interface Paginable {
  pagination: PaginationOptions;
}

export const initialPagination: Paginable = {
  pagination: {
    current: 0,
    perPage: 25,
    total: -1,
  },
};

export const paginationReducers = {
  resetPagination: (state: Paginable) => {
    state.pagination = initialPagination.pagination;
  },

  updatePagination: (state: Paginable, { payload }: PayloadAction<Partial<PaginationOptions>>) => {
    state.pagination = { ...state.pagination, ...payload };
  },
};

export const hasNext = <T>(pagination: PaginationOptions, items: T[]) => {
  return items.length >= pagination.perPage;
};
