/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import arrayMove from 'array-move';
import {
  addAllMembersToGroup,
  addGroup as apiAdd,
  deleteGroup as apiDelete,
  fetchGroupUnits,
  listGateTypes,
  listGroups,
  removeAllMembersFromGroup,
  reorderGroups,
  updateGroup as apiUpdate,
  updateGroupUnits as apiUpdateGroupUnits,
} from '../../api/gatewise_api';
import { notifyError, notifySuccess } from '../notifications/notificationsSlice';

const initialState = {
  communityID: null,
  groups: [],
  gateTypes: [],
  gates: [],
  units: [],
  modalStatus: {
    loading: false,
    open: false,
  },
  deleteGroupStatus: {
    loading: false,
    open: false,
  },
  loading: true,
  error: null,
};

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setCommunity: (state, action) => {
      const { groups, units, gateTypes, gates, error } = initialState;
      state.groups = groups;
      state.gateTypes = gateTypes;
      state.gates = gates;
      state.units = units;
      state.error = error;
      state.communityID = action.payload;
    },
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setGateTypes: (state, action) => {
      state.gateTypes = action.payload;
    },
    setGates: (state, action) => {
      state.gates = action.payload;
    },
    setUnits: (state, action) => {
      state.units = action.payload;
    },
    updateModalStatus: (state, action) => {
      state.modalStatus = { ...state.modalStatus, ...action.payload };
    },
    updateDeleteGroupStatus: (state, action) => {
      state.deleteGroupStatus = { ...state.deleteGroupStatus, ...action.payload };
    },
  },
});

export const {
  updateDeleteGroupStatus,
  updateModalStatus,
  setGateTypes,
  setCommunity,
  setGroups,
  setGates,
  setUnits,
  setLoading,
} = groupsSlice.actions;

export default groupsSlice.reducer;

export const loadGroups = () => (dispatch, getState) => {
  const { communityID } = getState().groups;
  dispatch(setLoading(true));
  listGroups(communityID, 0, 1000)
    .then(({ items }) => {
      dispatch(setLoading(false));
      dispatch(setGroups(items));
    })
    .catch(() => {
      dispatch(setLoading(false));
      dispatch(setGroups([]));
      notifyError('Failed to load groups');
    });
};

export const loadUnits = (communityId) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    let units = await fetchGroupUnits(communityId);
    units = units.sort((u1, u2) => u1?.name?.localeCompare(u2?.name));
    dispatch(setUnits(units));
  } catch (err) {
    notifyError('Failed to load units');
  } finally {
    dispatch(setLoading(false));
  }
};

export const loadCommunity = (id) => async (dispatch) => {
  dispatch(setCommunity(id));
  dispatch(setLoading(true));

  try {
    const gateTypes = await listGateTypes(id, 0, 1000);
    dispatch(setGateTypes(gateTypes));
    dispatch(loadGroups());
  } catch (err) {
    notifyError('Failed to load gate types');
    dispatch(setLoading(false));
  }
};

export const updateGroup = (id, gate) => (dispatch, getState) => {
  const { communityID } = getState().groups;
  dispatch(updateModalStatus({ loading: true }));
  apiUpdate(communityID, id, gate)
    .then(() => {
      dispatch(updateModalStatus({ success: true, loading: false, editedGroup: null }));
      dispatch(loadGroups());
      dispatch(notifySuccess('Group updated'));
    })
    .catch(() => {
      dispatch(notifyError('Update failed'));
    })
    .finally(() => {
      dispatch(updateModalStatus({ success: false, loading: false }));
    });
};

export const addGroup = (group) => (dispatch, getState) => {
  const { communityID } = getState().groups;
  dispatch(updateModalStatus({ loading: true }));
  apiAdd(communityID, group)
    .then(() => {
      dispatch(updateModalStatus({ success: true, loading: false, editedGroup: null }));
      dispatch(loadGroups());
      dispatch(notifySuccess('Group added'));
    })
    .catch(() => {
      dispatch(notifyError('Add group failed'));
      dispatch(updateModalStatus({ success: false }));
    })
    .finally(() => {
      dispatch(updateModalStatus({ loading: false }));
    });
};

export const deleteGroup = (id) => (dispatch, getState) => {
  const { communityID } = getState().groups;
  dispatch(updateDeleteGroupStatus({ loading: true }));
  apiDelete(communityID, id)
    .then(() => {
      dispatch(updateDeleteGroupStatus({ success: true, loading: false, item: null }));
      dispatch(loadGroups());
      dispatch(notifySuccess('Group deleted'));
    })
    .catch(() => {
      dispatch(notifyError('Delete group failed'));
      dispatch(updateDeleteGroupStatus({ success: false }));
    })
    .finally(() => {
      dispatch(updateDeleteGroupStatus({ loading: false }));
    });
};

export const updateGroupUnits = (communityId, groupId, units) => async (dispatch) => {
  try {
    await apiUpdateGroupUnits(communityId, groupId, units);
    dispatch(loadGroups());
    // TODO should load only the specific group
    // dispatch(loadGroup(communityId, groupId));
    dispatch(notifySuccess('Group units were updated'));
  } catch (error) {
    dispatch(notifyError(error.toString()));
  }
};

export const addAllMembers = (groupId, communityId) => async (dispatch) => {
  try {
    await addAllMembersToGroup(groupId, communityId);
    dispatch(notifySuccess('Group updated'));
  } catch (err) {
    dispatch(notifyError('Update failed'));
  }
};

export const removeAllMembers = (groupId, communityId) => async (dispatch) => {
  try {
    await removeAllMembersFromGroup(groupId, communityId);
    dispatch(notifySuccess('Group updated'));
  } catch (err) {
    dispatch(notifyError('Update failed'));
  }
};

const prepareAccessPointsOrder = (items) => items.map((item) => ({ id: item.id, index: item.index }));

export const changeOrder = (communityId, items, oldIndex, newIndex) => async (dispatch) => {
  const newItems1 = arrayMove(items, oldIndex, newIndex);
  const newItems2 = newItems1.map((item, index) => ({
    ...item,
    index,
  }));
  dispatch(setGroups(newItems2));

  const accessPointsOrder = prepareAccessPointsOrder(newItems2);
  await reorderGroups(communityId, accessPointsOrder);
};
