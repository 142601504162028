/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { listDevices, listGsmVersions } from '../../api/gatewise_api';
import { AccessPoint } from '../../app/domain/AccessPoint';
import { AppDispatch } from '../../app/store';
import { errorToString } from '../../utils/errors';

export enum SortDirection {
  asc = 'asc',
  desc = 'desc',
}

type DeviceState = {
  page: {
    items: AccessPoint[];
    total: number;
  };
  search: string;
  loading: boolean;
  error: null | string;
  selectedDevices: string[];
  gsmVersions: string[];
};

const initialState: DeviceState = {
  page: {
    items: [],
    total: 0,
  },
  search: '',
  loading: true,
  error: null,
  selectedDevices: [],
  gsmVersions: [],
};

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setPage: (state, action) => {
      const { page, error } = action.payload;
      state.page = page;
      state.error = error;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSelectedDevices: (state, action) => {
      state.selectedDevices = action.payload;
    },
    setGsmVersions: (state, action) => {
      state.gsmVersions = action.payload;
    },
  },
});

export const { setPage, setLoading, setSelectedDevices, setGsmVersions } = devicesSlice.actions;

export default devicesSlice.reducer;

export const loadPage =
  (page: number, limit: number, params: object = {}, abortSignal?: AbortSignal) =>
  async (dispatch: AppDispatch) => {
    dispatch(setSelectedDevices([]));
    dispatch(setLoading(true));
    try {
      const { items, total } = await listDevices(page * limit, limit, params, abortSignal);
      dispatch(
        setPage({
          page: {
            items,
            total,
          },
        }),
      );
      dispatch(setLoading(false));
    } catch (error) {
      if (axios.isCancel(error)) return;

      dispatch(setPage({ page: { items: [] }, error: errorToString(error) }));
      dispatch(setLoading(false));
    }
  };

export const reloadWithSearch =
  (params: object, limit = 100, page = 0, abortSignal?: AbortSignal) =>
  (dispatch: AppDispatch) => {
    dispatch(loadPage(page, limit, params, abortSignal));
  };

export const loadGsmVersions = () => async (dispatch: AppDispatch) => {
  dispatch(setSelectedDevices([]));
  try {
    const { gsmVersions } = await listGsmVersions();
    dispatch(setGsmVersions(gsmVersions));
  } catch (error) {
    dispatch(setPage({ page: { items: [] }, error: errorToString(error) }));
  }
};
