import React, { PropsWithChildren, useMemo } from 'react';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';

export type Option = { label: string; id: string };

export function AutocompleteOptionSelector({
  value,
  options,
  onChange,
  groupBy,
  children,
  disabled,
}: PropsWithChildren<{
  value: string | null;
  onChange: (value: string | null) => void;
  options: Option[];
  groupBy?: (option: Option) => string;
  disabled?: boolean;
  children: (params: AutocompleteRenderInputParams) => JSX.Element;
}>): JSX.Element {
  const selectedOption = useMemo(() => options.find((it) => it.id === value), [options, value]);
  return (
    <Autocomplete
      disabled={disabled}
      getOptionSelected={(option, value) => option.id === value.id}
      value={selectedOption ?? null}
      groupBy={groupBy}
      options={options}
      getOptionLabel={(option) => option.label || ''}
      renderInput={children}
      onChange={(event, selectedValue) => {
        onChange(selectedValue?.id ?? null);
      }}
    />
  );
}
