import { by, inject, keys } from '../../injectDecorators';
import { IAuthorizer, IAuthorizerKey } from '../../api/IAuthorizer';
import axios, { AxiosInstance } from 'axios';

export const IKeypadsRepoKey = Symbol('IKeypadsRepo');

export interface IKeypadsRepo {
  generateKeypad(communityId: string): Promise<string>;
}

@keys(IKeypadsRepoKey)
export class KeypadsRepo implements IKeypadsRepo {
  constructor(@inject(by(IAuthorizerKey)) private authorizer: IAuthorizer) {}

  async generateKeypad(communityId: string): Promise<string> {
    const httpClient = await this.createHttpClient();
    const { data } = await httpClient.get(`community/${communityId}/keypad/generate`);
    return data.code;
  }

  private async createHttpClient(): Promise<AxiosInstance> {
    return axios.create({
      baseURL: process.env.API_URL_CLUSTER,
      headers: {
        Authorization: `Bearer ${await this.authorizer.getIdToken()}`,
      },
    });
  }
}
