import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'classnames';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCustomToken } from '../features/user/selectors';

export function SidebarNavigationLink({
  children,
  icon,
  to,
  redirectToPortalV3,
}: PropsWithChildren<{ icon?: JSX.Element; to: string; redirectToPortalV3?: boolean }>): JSX.Element {
  const classes = useStyles();

  const usersCustomToken = useSelector(getCustomToken);

  const onRedirectHandle = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!redirectToPortalV3 && !to.startsWith('http')) return;
    e.preventDefault();
    window.location.href = to + `?token=${encodeURIComponent(usersCustomToken)}`;
  };

  return (
    <ListItem className={classes.itemLeaf} disableGutters>
      <NavLink
        onClick={onRedirectHandle}
        to={to}
        className={(active) => clsx(classes.navLink, active ? classes.active : undefined)}
      >
        <Button className={clsx(classes.buttonLeaf)}>
          {icon && <span className={classes.icon}>{icon}</span>}
          {children}
        </Button>
      </NavLink>
    </ListItem>
  );
}

export function SidebarLink({
  children,
  icon,
  to,
}: PropsWithChildren<{ icon?: JSX.Element; to: string }>): JSX.Element {
  const classes = useStyles();
  return (
    <ListItem className={classes.itemLeaf} disableGutters>
      <Button className={clsx(classes.buttonLeaf)} target="_blank" href={to}>
        {icon && <span className={classes.icon}>{icon}</span>}
        {children}
      </Button>
    </ListItem>
  );
}

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  navLink: {
    flexGrow: 1,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'capitalize',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium as never,
  },
  icon: {
    color: (theme.palette as any).icon,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 16,
    width: 16,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium as never,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
  addScheduleGroup: {
    color: '#3f0bef',
    fontWeight: theme.typography.fontWeightMedium as never,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));
