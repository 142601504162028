import { Context, createContext, useContext } from 'react';
import { IContainer } from 'ts-ioc-container';

export const ContainerContext = createContext<IContainer | undefined>(undefined);

export function useContextOrFail<T>(context: Context<T | undefined>): T {
  const instance = useContext(context);
  if (instance === undefined) {
    throw new Error('Context is undefined');
  }
  return instance;
}
