import { Authorizer } from '../api/Authorizer';
import { ImageManager } from '../api/ImageManager';
import { initializeApp } from 'firebase/app';
import { production } from '../env/production';
import { development } from '../env/development';
import { IContainer } from 'ts-ioc-container';
import { deps as developmentDeps } from './development';
import { deps as productionDeps } from './production';
import { deps as stagingDeps } from './staging';
import { AppEnv } from './types';

export const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_DATABASE_URL,
});

export type Dependencies = {
  authorizer: Authorizer;
  imageManager: ImageManager;
  container: IContainer;
  gatewiseApi: any;
};

export const container = process.env.NODE_ENV === 'production' ? production() : development();

export function createDeps(envName: AppEnv | undefined): Dependencies {
  return {
    authorizer: new Authorizer(firebaseApp, process.env.REACT_APP_DEPLOYMENT_URL as string),
    imageManager: new ImageManager(firebaseApp),
    gatewiseApi: envName === 'production' ? productionDeps : envName === 'staging' ? stagingDeps : developmentDeps,
    container,
  };
}
