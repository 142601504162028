import { IAccessPointV3 } from '../domain/AccessPoint';
import { by, inject, keys } from '../../injectDecorators';
import axios, { AxiosInstance } from 'axios';
import { IAuthorizer, IAuthorizerKey } from '../../api/IAuthorizer';

export const IAccessPointV3RepoKey = Symbol('IAccessPointV3Repo');

export interface IAccessPointV3Repo {
  fetchAccessPoint(communityId: number): Promise<IAccessPointV3[]>;
}

@keys(IAccessPointV3RepoKey)
export class AccessPointV3Repo implements IAccessPointV3Repo {
  constructor(@inject(by(IAuthorizerKey)) private authorizer: IAuthorizer) {}

  async fetchAccessPoint(communityId: number): Promise<IAccessPointV3[]> {
    const httpClient = await this.createHttpClient();
    const response = await httpClient.get(`community/${communityId}/access-point`);
    return response.data;
  }

  private async createHttpClient(): Promise<AxiosInstance> {
    return axios.create({
      baseURL: process.env.API_V3_URL,
      headers: {
        Authorization: `Bearer ${await this.authorizer.getIdToken()}`,
      },
    });
  }
}
