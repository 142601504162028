/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import arrayMove from 'array-move';
import {
  addGate as apiAdd,
  checkCameraLiveStreamAPI,
  deleteGate as apiDelete,
  findGateById,
  listGates,
  listGateTypes,
  openGate as apiOpen,
  reorderAccessPoints,
  resetGate as apiResetGate,
  startCameraLiveStreamAPI,
  stopCameraLiveStreamAPI,
  syncDevices as apiSyncDevices,
  syncGate as apiSync,
  updateGate as apiUpdate,
  upgradeDevices as apiUpgradeDevices,
  fetchGateLog as apiFetchGateLog,
} from '../../api/gatewise_api';
import { notifyError, notifySuccess } from '../notifications/notificationsSlice';
import { AppDispatch, AppThunk, GetState } from '../../app/store';
import { Order } from '../../app/domain/Order';
import { AccessPoint, AccessPointValue } from '../../app/domain/AccessPoint';
import { GateType } from '../../app/domain/GateType';

type AccessPointsState = {
  items: AccessPoint[];
  modalStatus: {
    loading: boolean;
    open: boolean;
  };
  upgradeModal: {
    loading: boolean;
    open: boolean;
  };
  deleteGateStatus: {
    loading: boolean;
    open: boolean;
  };
  openGateStatus: Record<string, boolean>;
  liveStreamModal: {
    url: string | null;
    open: boolean;
    sn: string | null;
  };
  gateTypes: GateType[];
  loading: boolean;
  quickActionLoading: boolean;
  quickActionLoadingId: string | null;
  currentAccessPoint: AccessPoint | null;
  modalGateIsOpen: boolean;
};

const initialState: AccessPointsState = {
  items: [],
  modalStatus: {
    loading: false,
    open: false,
  },
  upgradeModal: {
    loading: false,
    open: false,
  },
  deleteGateStatus: {
    loading: false,
    open: false,
  },
  openGateStatus: {},
  liveStreamModal: {
    url: null,
    open: false,
    sn: null,
  },
  gateTypes: [],
  loading: true,
  quickActionLoading: false,
  quickActionLoadingId: null,
  currentAccessPoint: null,
  modalGateIsOpen: false,
};

const accessPointsSlice = createSlice({
  name: 'accessPoints',
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setQuickActionLoading: (state, action) => {
      state.quickActionLoading = action.payload;
    },
    setQuickActionLoadingId: (state, action) => {
      state.quickActionLoadingId = action.payload;
    },
    setGateTypes: (state, action) => {
      state.gateTypes = action.payload;
    },
    setLiveStreamModal: (state, action) => {
      state.liveStreamModal = { ...state.liveStreamModal, ...action.payload };
    },
    updateModalStatus: (state, action) => {
      state.modalStatus = { ...state.modalStatus, ...action.payload };
    },
    updateUpgradeModal: (state, action) => {
      state.upgradeModal = { ...state.upgradeModal, ...action.payload };
    },
    updateDeleteGateStatus: (state, action) => {
      state.deleteGateStatus = { ...state.deleteGateStatus, ...action.payload };
    },
    updateAccessPoint: (state, action) => {
      state.items = state.items.map((it) => (it.id === action.payload.id ? { ...it, ...action.payload } : it));
    },
    setCurrentAccessPoint: (state, action) => {
      state.currentAccessPoint = action.payload;
    },
    setOpenGateStatus: (state, action) => {
      const {
        payload: { id },
      } = action;
      state.openGateStatus = { ...state.openGateStatus, [id]: true };
    },
    resetOpenGateStatus: (state, action) => {
      const {
        payload: { id },
      } = action;
      const { [id]: val, ...rest } = state.openGateStatus;
      state.openGateStatus = rest;
    },
    setModalGateIsOpen: (state, action) => {
      state.modalGateIsOpen = action.payload;
    },
  },
});

export const {
  updateDeleteGateStatus,
  updateModalStatus,
  updateUpgradeModal,
  setOpenGateStatus,
  resetOpenGateStatus,
  setGateTypes,
  setItems,
  setLoading,
  setQuickActionLoading,
  setLiveStreamModal,
  setQuickActionLoadingId,
  setCurrentAccessPoint,
  setModalGateIsOpen,
} = accessPointsSlice.actions;

export default accessPointsSlice.reducer;

export const openModalGate = () => (dispatch: AppDispatch) => {
  dispatch(setModalGateIsOpen(true));
};

export const closeModalGate = () => (dispatch: AppDispatch) => {
  dispatch(setModalGateIsOpen(false));
};

export const getModalGateIsOpen = (state: { accessPoints: { modalGateIsOpen: boolean } }) => {
  return state.accessPoints.modalGateIsOpen;
};

export const loadAccessPointByCommunityId =
  (communityID: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoading(true));
      const items = await listGates(communityID);
      dispatch(setLoading(false));
      dispatch(setItems(items));
    } catch (e) {
      dispatch(setLoading(false));
      dispatch(notifyError(e));
    }
  };

export const loadGateTypesByCommunityId = (communityID: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const gateTypes = await listGateTypes(communityID, 0, 1000);
    dispatch(setGateTypes(gateTypes));
  } catch (e) {
    dispatch(setLoading(false));
    notifyError('Failed to load gate types');
  }
};

const prepareAccessPointsOrder = (items: AccessPoint[]): Order[] =>
  items.map((item) => ({ id: item.id, index: item.index }));

export const changeOrder =
  (communityId: string, items: AccessPoint[], oldIndex: number, newIndex: number) => async (dispatch: AppDispatch) => {
    const newItems1 = arrayMove(items, oldIndex, newIndex);
    const newItems2 = newItems1.map((item, index) => ({
      ...item,
      index,
    }));
    dispatch(setItems(newItems2));

    const accessPointsOrder = prepareAccessPointsOrder(newItems2);
    await reorderAccessPoints(communityId, accessPointsOrder);
  };

export const updateGate = (communityId: string, id: string, gate: AccessPoint) => async (dispatch: AppDispatch) => {
  dispatch(updateModalStatus({ loading: true }));
  try {
    const { hasSyncError } = await apiUpdate(communityId, id, gate);
    if (hasSyncError) {
      dispatch(notifyError('The device is not connected, some of the fields might not be updated'));
    } else {
      dispatch(updateModalStatus({ success: true, loading: false }));
      dispatch(notifySuccess('Gate updated'));
    }
  } catch (e) {
    dispatch(notifyError(e));
  } finally {
    dispatch(updateModalStatus({ success: false, loading: false }));
  }
};

export const loadGate =
  (communityId: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      const gate = await findGateById(communityId, id);
      dispatch(accessPointsSlice.actions.updateAccessPoint(gate));
      dispatch(accessPointsSlice.actions.setCurrentAccessPoint(gate));
    } catch (e: any) {
      dispatch(notifyError(`Failed to load gate: ${e.message}`));
      dispatch(closeModalGate());
    }
  };

export const addGate = (payload: AccessPointValue, communityID: string) => async (dispatch: AppDispatch) => {
  dispatch(updateModalStatus({ loading: true }));
  try {
    await apiAdd(communityID, payload);
    dispatch(updateModalStatus({ success: true, loading: false }));
    dispatch(loadAccessPointByCommunityId(communityID));
    dispatch(notifySuccess('Gate added'));
  } catch (e) {
    dispatch(notifyError(e));
    dispatch(updateModalStatus({ success: false }));
  } finally {
    dispatch(updateModalStatus({ loading: false }));
  }
};

export const deleteGate = (id: string, communityID: string) => async (dispatch: AppDispatch) => {
  dispatch(updateDeleteGateStatus({ loading: true }));
  try {
    await apiDelete(communityID, id);
    dispatch(updateDeleteGateStatus({ success: true, loading: false, deletedGate: null }));
    dispatch(loadAccessPointByCommunityId(communityID));
    dispatch(notifySuccess('Gate deleted'));
  } catch (e) {
    dispatch(notifyError('Delete gate failed'));
    dispatch(updateDeleteGateStatus({ success: false }));
  } finally {
    dispatch(updateDeleteGateStatus({ loading: false }));
  }
};

export const openGate = (id: string, name: string, communityID: string) => async (dispatch: AppDispatch) => {
  dispatch(setOpenGateStatus({ id }));
  try {
    const { status, error } = await apiOpen(communityID, id);
    if (status === 'fail') {
      dispatch(notifyError(`Failed to open gate ${name}: ${error}`));
    } else {
      dispatch(notifySuccess(`Gate ${name} opened`));
    }
  } catch (e) {
    dispatch(notifyError(`Failed to open gate ${name}`));
  } finally {
    dispatch(resetOpenGateStatus({ id }));
  }
};

export const syncGate = (id: string, communityID: string) => async (dispatch: AppDispatch) => {
  try {
    const { status, data, error } = await apiSync(communityID, id);
    if (status === 'fail') {
      dispatch(notifyError(`Failed to sync gate: ${JSON.stringify(error)}`));
    } else {
      dispatch(accessPointsSlice.actions.updateAccessPoint(data));
      dispatch(notifySuccess(`Gate is synced`));
    }
  } catch (e) {
    dispatch(notifyError(`Failed to sync gate`));
  }
};

export const resetGate = (id: string, communityID: string) => async (dispatch: AppDispatch) => {
  dispatch(setQuickActionLoading(true));
  dispatch(setQuickActionLoadingId(id));
  try {
    const { status, error } = await apiResetGate(communityID, id);
    dispatch(setQuickActionLoading(false));
    dispatch(setQuickActionLoadingId(null));
    if (status === 'fail') {
      dispatch(notifyError(`Failed to reset gate: ${error}`));
    } else {
      dispatch(notifySuccess('The gate has been successfully reset'));
    }
  } catch (e) {
    dispatch(notifyError('Gate reset failed'));
  } finally {
    dispatch(setQuickActionLoading(false));
    dispatch(setQuickActionLoadingId(null));
  }
};

export const upgradeDevices = (serverPath: string) => async (dispatch: AppDispatch, getState: GetState) => {
  const { selectedDevices, page } = getState().devices;
  const { items } = page;
  const devices = items.filter((item: { id: string }) => selectedDevices.includes(item.id));
  dispatch(updateUpgradeModal({ loading: true }));
  try {
    const action = await apiUpgradeDevices(serverPath, devices);
    if (action.status === 'ok') {
      dispatch(updateUpgradeModal({ open: false }));
      dispatch(notifySuccess(`${selectedDevices.length} device(s) upgraded`));
    } else {
      dispatch(
        notifyError(
          selectedDevices.length > 0
            ? `Upgrade devices failed ${action.error}`
            : `Upgrade device failed ${action.error}`,
        ),
      );
    }
  } catch (e) {
    dispatch(notifyError(`Failed to upgrade - ${e}`));
  } finally {
    dispatch(updateUpgradeModal({ loading: false }));
  }
};

export const syncDevices = (devices: any[]) => async (dispatch: AppDispatch) => {
  const gsmIDs = devices.map((device) => device.gsm_id);
  const action = await apiSyncDevices(gsmIDs);
  if (action.status === 'ok') {
    dispatch(notifySuccess('Device synchronized successfully'));
  } else {
    dispatch(notifyError(`Sync device failed ${action.error}`));
  }
};

export const startCameraLiveStream = (communityId: string, sn: string) => async (dispatch: AppDispatch) => {
  await startCameraLiveStreamAPI(communityId, sn);
  dispatch(setLiveStreamModal({ open: true, url: null, sn: sn }));
};

export const closeLiveStreamModal = (communityId: string, sn: string) => async (dispatch: AppDispatch) => {
  try {
    await stopCameraLiveStreamAPI(communityId, sn);
  } catch (e) {
    console.log(`Failed to stop live camera stream - ${e}`);
  }
  dispatch(setLiveStreamModal({ open: false, url: null, sn: null }));
};

export const getLiveStreamUrl = (communityId: string, sn: string) => async (dispatch: AppDispatch) => {
  try {
    const result = await checkCameraLiveStreamAPI(communityId, sn);
    if (result.url) {
      dispatch(setLiveStreamModal({ url: result.url }));
    }
  } catch (e) {
    console.log(`Failed to get live stream url - ${e}`);
  }
};

export const fetchLog = (id: string, communityID: string) => async (dispatch: AppDispatch) => {
  dispatch(setQuickActionLoading(true));
  dispatch(setQuickActionLoadingId(id));
  try {
    const { status, error } = await apiFetchGateLog(communityID, id);
    dispatch(setQuickActionLoading(false));
    dispatch(setQuickActionLoadingId(null));
    if (status === 'fail') {
      dispatch(notifyError(`Failed to fetch log of the gate: ${error}`));
    } else {
      dispatch(notifySuccess('The gate log has been successfully fetched'));
    }
  } catch (e) {
    dispatch(notifyError('Gate log fetch failed'));
  } finally {
    dispatch(setQuickActionLoading(false));
    dispatch(setQuickActionLoadingId(null));
  }
};
