import {
  constructor,
  Container,
  createAddKeysDecorator,
  createMethodHookDecorator,
  createSingletonDecorator,
  createTagsDecorator,
  fromClass as fromConstructor,
  InjectionToken,
  IServiceLocator,
  MethodsMetadataCollector,
  ProvidersMetadataCollector,
  ServiceLocator,
} from 'ts-ioc-container';
import { Fn, inject as injectFn, InjectionDecorator, resolve } from 'ts-constructor-injector';
import { InstanceHook } from 'ts-ioc-container/cjm/features/instanceHook/InstanceHook';

const onDisposeMetadata = new MethodsMetadataCollector('onDispose');
export const onDispose = createMethodHookDecorator(onDisposeMetadata);
const onConstructMetadata = new MethodsMetadataCollector('onConstruct');
export const onConstruct = createMethodHookDecorator(onConstructMetadata);

function isInstanceOfObject(instance: unknown): instance is object {
  return typeof instance === 'object' && !!(instance as any).constructor;
}

export const ROOT_SCOPE = 'root';
export function createContainer() {
  return new Container(
    new ServiceLocator({
      resolve<T>(locator: IServiceLocator, value: constructor<T>, ...deps: unknown[]): T {
        const instance = resolve(locator)(value, ...deps);
        onConstructMetadata.invokeHooksOf(instance);
        return instance;
      },
    })
      .setTags([ROOT_SCOPE])
      .setHook(
        new InstanceHook((instance) => {
          if (isInstanceOfObject(instance)) {
            onDisposeMetadata.invokeHooksOf(instance);
          }
        }),
      ),
  );
}

export const inject: InjectionDecorator<IServiceLocator> = injectFn;
const providerMetadata = ProvidersMetadataCollector.create();
export const singleton = createSingletonDecorator(providerMetadata);
export const scope = createTagsDecorator(providerMetadata);
export const keys = createAddKeysDecorator(providerMetadata);
export const by =
  <T>(key: InjectionToken<T>, ...args: unknown[]): Fn<IServiceLocator, T> =>
  (l) =>
    l.resolve<T>(key, ...args);

export const fromClass = <T>(target: constructor<T>) =>
  fromConstructor(target).map(providerMetadata.findReducerOrCreate(target));

export const moduleTags = ['module'];
export const COMMUNITY_SCOPE = 'community';
export const communityTags = [...moduleTags, COMMUNITY_SCOPE];
