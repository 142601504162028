import { Action, configureStore, Selector } from '@reduxjs/toolkit';
import userSlice from '../features/user/userSlice';
import communitiesSlice from '../features/communities/communitiesSlice';
import companiesSlice from '../features/companies/companiesSlice';
import membersSlice from '../features/members/membersSlice';
import notificationsSlice from '../features/notifications/notificationsSlice';
import accessPoints from '../features/access_points/accessPointsSlice';
import groups from '../features/groups/groupsSlice';
import accessLog from '../features/access_log/accessLogsSlice';
import activityAnalytic, { epic } from '../features/community/dashboard/activityAnalyticSlice';
import communityDetails from '../features/community_details/communityDetailsSlice';
import globalMembers from '../features/global_members/globalMembersSlice';
import globalAccessLog from '../features/global_access_log/accessLogsSlice';
import jobs from '../features/jobs/jobsSlice';
import devices from '../features/devices/devicesSlice';
import remotes from '../features/community/remotes/remotesSlice';
import productUpdates from '../features/product_updates/productUpdatesSlice';
import prospectsAccess from '../features/prospects_access/prospectsAccessSlice';
import vendorsAccess from '../features/vendors_access/vendorsAccessSlice';
import globalRemotes from '../features/admin/remotes/remotesSlice';
import deviceEvents from '../features/device_events/deviceEventsSlice';
import deviceOpenEvents from '../features/device_open_events/deviceOpenEventsSlice';
import dailyErrors from '../features/daily_errors/dailyErrorsSlice';
import settings from '../features/settings/settingsSlice';
import syncLog from '../features/sync_log/syncLogSlice';
import invoices from '../features/invoices/invoicesSlice';
import keypads from '../features/community/keypads/keypadsSlice';
import unitLocks from '../features/community/unit_locks/unitLocksSlice';
import camerasRpi from '../features/community/cameras_rpi/camerasRpiSlice';
import accessPointV3 from '../features/community/access_point/accessPointSlice';
import cameraRpiEvents from '../features/community/camera_rpi_events/cameraRpiEventsSlice';
import tours from '../features/tours/toursSlice';
import { createDeps, Dependencies } from './di';
import { useDispatch, useSelector } from 'react-redux';
import { unusedAccessPointsReportReducer } from '../features/unused_access_points_report/unusedAccessPointsReportSlice';
import { unusedCommunityReportReducer } from '../features/unused_access_points_report/unusedCommunityReportSlice';
import { combineEpics, createEpicMiddleware, Epic } from 'redux-observable';
import { yardi } from '../features/communities/integration/yardiSlice';
import { AppEnv } from './types';
import { productUpdateEpics } from '../features/user/productUpdate';
import apiKeySlice from '../features/community/settings/apiKeySlice';

export const dependencies = createDeps(process.env.APP_ENV as AppEnv);
const epicMiddleware = createEpicMiddleware<Action, Action, any, Dependencies>();
const store = configureStore({
  reducer: {
    user: userSlice,
    communities: communitiesSlice,
    companies: companiesSlice,
    members: membersSlice,
    notifications: notificationsSlice,
    unusedAccessPointsReport: unusedAccessPointsReportReducer,
    unusedCommunityReport: unusedCommunityReportReducer,
    accessPoints,
    groups,
    accessLog,
    activityAnalytic,
    communityDetails,
    globalMembers,
    globalAccessLog,
    jobs,
    devices,
    remotes,
    productUpdates,
    globalRemotes,
    prospectsAccess,
    vendorsAccess,
    deviceEvents,
    deviceOpenEvents,
    dailyErrors,
    settings,
    syncLog,
    invoices,
    keypads,
    unitLocks,
    camerasRpi,
    accessPointV3,
    cameraRpiEvents,
    yardi,
    tours,
    apiKey: apiKeySlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: dependencies,
      },
    }).concat(epicMiddleware),
});
epicMiddleware.run(combineEpics(epic, productUpdateEpics));
export default store;
export type GetState = typeof store.getState;
export type RootState = ReturnType<GetState>;
export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppSelector<Result = unknown, Params extends never | readonly any[] = any[]> = Selector<
  RootState,
  Result,
  Params
>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = <TSelected>(selector: (state: RootState) => TSelected) =>
  useSelector<RootState, TSelected>(selector);

export type AppThunk<Result = void> = (
  dispatch: AppDispatch,
  getState: GetState,
  deps: Dependencies,
) => Promise<Result>;
export type AppEpic<Input extends Action = any, Output extends Input = Input> = Epic<
  Input,
  Output,
  RootState,
  Dependencies
>;
