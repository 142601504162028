import { HOUR, sleep } from '../utils/time';
import { GateErrorStatItemValue } from '../features/daily_errors/GateErrorStatItem';

export const listDailyErrors = async (failuresPercentThreshold: number) => {
  console.log('listDailyErrors', failuresPercentThreshold);
  await sleep(500);
  return [
    {
      gsm_id: '350200690694122',
      id: 5325,
      total: '281',
      failures_count: '281',
      failures_percent: '100',
      access_point_name: 'Main Entrance/Entrada Principal',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: 'Major bluetooth failure issues - Gate offline, will send email to property for reset ',
      comment_updated_at: '2022-11-16T22:13:54.114Z',
      community_name: 'The Sophia Apartments',
      community_id: 1788,
      rssi: -63,
    },
    {
      gsm_id: '350200690695244',
      id: 5203,
      total: '275',
      failures_count: '275',
      failures_percent: '100',
      access_point_name: 'Car Gate Entrance',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.42',
      comment: 'gate is online and working, updated to ver 37 for better connectivity in future ',
      comment_updated_at: '2022-08-12T14:30:39.326Z',
      community_name: 'Emory Point',
      community_id: 1741,
      rssi: -93,
    },
    {
      gsm_id: '359705372564717',
      id: 6451,
      total: '82',
      failures_count: '82',
      failures_percent: '100',
      access_point_name: 'Car Gate By Bldg One ',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment:
        'Having issues with remotes on this property - ticket  open in sales force until things are fixed properly ',
      comment_updated_at: '2022-11-28T21:44:35.693Z',
      community_name: 'Sofia Austin',
      community_id: 2087,
      rssi: -51,
    },
    {
      gsm_id: '350200690695467',
      id: 5315,
      total: '46',
      failures_count: '46',
      failures_percent: '100',
      access_point_name: 'Car Gate Entrance Bldg 5',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.37',
      comment: 'Gate is offline for about a week now,. I have notified the property to perform a reset on the module ',
      comment_updated_at: '2022-11-16T20:51:15.639Z',
      community_name: 'Montebello Gardens',
      community_id: 1785,
      rssi: -69,
    },
    {
      gsm_id: '350200690695467',
      id: 5471,
      total: '46',
      failures_count: '46',
      failures_percent: '100',
      access_point_name: 'Car Gate Entrance Bldg 5',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.37',
      comment: 'Gate is working - errors due to devices being too far away for the box to porperly read command ',
      comment_updated_at: '2022-11-16T22:12:56.255Z',
      community_name: 'Bella Capri',
      community_id: 1861,
      rssi: -69,
    },
    {
      gsm_id: '350200690694999',
      id: 5175,
      total: '36',
      failures_count: '36',
      failures_percent: '100',
      access_point_name: 'Bldg 7-1 Entrance (28)',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: 'ver: 1.0.26',
      comment:
        'Notice sent for power outage at access points and notice was also sent to reset the gate as it has been offline for about 2 hours toaday ',
      comment_updated_at: '2022-12-06T21:10:15.743Z',
      community_name: 'Crescent House',
      community_id: 1722,
      rssi: -89,
    },
    {
      gsm_id: '359705372564857',
      id: 6456,
      total: '21',
      failures_count: '21',
      failures_percent: '100',
      access_point_name: 'Pedestrian Gate By Bldg 22 ( 5 )',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.42',
      comment:
        'Door has been upgraded to ver. 42 and is online and running properly. Property Emailed about no power at gates 3 and 4 ',
      comment_updated_at: '2022-11-08T15:56:55.148Z',
      community_name: 'Sofia Austin',
      community_id: 2087,
      rssi: -53,
    },
    {
      gsm_id: '359705372564857',
      id: 6923,
      total: '21',
      failures_count: '21',
      failures_percent: '100',
      access_point_name: 'Gate/Porton #3',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.42',
      comment:
        'Minor issues, this was software version was updated to the most recent. It is online and running. Maingate  was offline since 9/28 in tellit, device has power as well and is working for the residents, not many complaints on this gate so not sure why tellit is reporting this ',
      comment_updated_at: '2022-11-08T15:11:57.081Z',
      community_name: 'The Sophia Apartments',
      community_id: 1788,
      rssi: -53,
    },
    {
      gsm_id: '352682050501575',
      id: 3906,
      total: '13',
      failures_count: '13',
      failures_percent: '100',
      access_point_name: 'Pool East',
      gsm_hardware_version: 'EZ863 NAG',
      gsm_firmware_version: 'ver: 1.0.26',
      comment:
        'Gate has failures and multiple success rates being shown. Amanda reached out to the property to get feedback on the pool gate. Property has not given any insight at this time. ',
      comment_updated_at: '2022-05-24T16:12:29.685Z',
      community_name: 'Landmark at Spring Cypress',
      community_id: 1276,
      rssi: -103,
    },
    {
      gsm_id: '359705372572207',
      id: 6095,
      total: '8',
      failures_count: '8',
      failures_percent: '100',
      access_point_name: 'Car Gate 1 By Bldg 1',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment:
        'Car Gate 1 bldg 1 has been offline for a about a week now, an email was sent to reset the module as well as connect power back on to another access point. ',
      comment_updated_at: '2022-12-06T20:22:55.646Z',
      community_name: 'Siena Apartments',
      community_id: 2024,
      rssi: -83,
    },
    {
      gsm_id: '359705372568593',
      id: 6260,
      total: '6',
      failures_count: '6',
      failures_percent: '100',
      access_point_name: 'Pool Gate',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment:
        'Pool Gate has been offline for 2wks. A reset request email was sent to the property. I will be awaiting a response on that. ',
      comment_updated_at: '2022-08-16T15:07:44.138Z',
      community_name: 'Samford Square',
      community_id: 2060,
      rssi: -75,
    },
    {
      gsm_id: '359705376204245',
      id: 7113,
      total: '3',
      failures_count: '3',
      failures_percent: '100',
      access_point_name: '(G2) East Resident Gate',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: 'New Install- Gate is working ',
      comment_updated_at: '2022-10-27T20:33:53.524Z',
      community_name: 'The Pearl Florida',
      community_id: 2285,
      rssi: -91,
    },
    {
      gsm_id: '352682050295053',
      id: 4398,
      total: '2',
      failures_count: '2',
      failures_percent: '100',
      access_point_name: 'Pedestrian gate #8',
      gsm_hardware_version: 'EZ863 NAG',
      gsm_firmware_version: 'Version: 1.0.0.35 - 1.00.12 - 08.01.2018',
      comment:
        'GSM hasnt worked for a long time but BT is working and with it being a ped gate, this has been ok. Will replace with Tmobile changeover. gate was reset',
      comment_updated_at: '2022-11-16T20:54:40.612Z',
      community_name: 'Ashford Santa Ana',
      community_id: 1447,
      rssi: -51,
    },
    {
      gsm_id: '352682050500734',
      id: 4529,
      total: '2',
      failures_count: '2',
      failures_percent: '100',
      access_point_name: 'Fitness Room',
      gsm_hardware_version: 'EZ863 NAG',
      gsm_firmware_version: 'ver: 1.0.16',
      comment: 'Equipment needs to be changed out . Lots of failures to the door. A reset has been done and tested . ',
      comment_updated_at: '2022-11-16T20:51:33.755Z',
      community_name: 'Parc at Murfreesboro',
      community_id: 1348,
      rssi: -83,
    },
    {
      gsm_id: '359705370350473',
      id: 5370,
      total: '2',
      failures_count: '2',
      failures_percent: '100',
      access_point_name: 'Community Center',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.32',
      comment: 'Emailed Manager, Gate offline - offered a reset and sent service agreement ',
      comment_updated_at: '2022-11-16T21:01:34.773Z',
      community_name: 'Homes of Parker Commons',
      community_id: 1340,
      rssi: -101,
    },
    {
      gsm_id: '359705372565631',
      id: 5919,
      total: '2',
      failures_count: '2',
      failures_percent: '100',
      access_point_name: 'Car Gate Entrance',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment:
        'reception is not great at 111. Gate is failing to reset . tested from portal and it was successful . Gate also tested from app and it was successful as well.',
      comment_updated_at: '2022-05-27T03:49:36.168Z',
      community_name: 'Chapel Run Apartments',
      community_id: 1963,
      rssi: -103,
    },
    {
      gsm_id: '359705376195211',
      id: 7090,
      total: '2',
      failures_count: '2',
      failures_percent: '100',
      access_point_name: 'Breezeway - Grainger Ave.',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: null,
      comment_updated_at: null,
      community_name: 'Broadway Chapter',
      community_id: 2273,
      rssi: -101,
    },
    {
      gsm_id: '359705370354079',
      id: 5707,
      total: '1',
      failures_count: '1',
      failures_percent: '100',
      access_point_name: 'Bldg. 2 Stairwell 2',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.42',
      comment: null,
      comment_updated_at: null,
      community_name: 'Mercantile River District',
      community_id: 1882,
      rssi: -93,
    },
    {
      gsm_id: '359705370355894',
      id: 5785,
      total: '1',
      failures_count: '1',
      failures_percent: '100',
      access_point_name: 'Pedestrian Gate',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: 'Tons of events in telit on the door. Hard Reset was instructed to the property ',
      comment_updated_at: '2022-10-26T18:17:51.507Z',
      community_name: 'Mueller Crossing Apartments',
      community_id: 1934,
      rssi: -57,
    },
    {
      gsm_id: '359705370355894',
      id: 5806,
      total: '1',
      failures_count: '1',
      failures_percent: '100',
      access_point_name: 'Staff Pedestrian Gate',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: 'Tons of events in telit on the door. Hard Reset was instructed to the property',
      comment_updated_at: '2022-10-26T18:17:53.546Z',
      community_name: 'Mueller Crossing Apartments',
      community_id: 1934,
      rssi: -57,
    },
    {
      gsm_id: '359705372569948',
      id: 6452,
      total: '1',
      failures_count: '1',
      failures_percent: '100',
      access_point_name: 'Fitness Door  ( 1 )',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.42',
      comment: 'Fitness Door had a lot of the errors , door was upgraded to the latest version and tested ',
      comment_updated_at: '2022-11-16T20:42:26.473Z',
      community_name: 'Sofia Austin',
      community_id: 2087,
      rssi: -63,
    },
    {
      gsm_id: '359705372572843',
      id: 7112,
      total: '1',
      failures_count: '1',
      failures_percent: '100',
      access_point_name: '(G1) Main Gate',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: 'New Install- Gate is online and working ',
      comment_updated_at: '2022-10-27T20:10:22.915Z',
      community_name: 'The Pearl Florida',
      community_id: 2285,
      rssi: -97,
    },
    {
      gsm_id: '359705372575796',
      id: 7114,
      total: '1',
      failures_count: '1',
      failures_percent: '100',
      access_point_name: '(D1) Building 3880 Southeast  Door',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: 'New Install- Gate is online and working ',
      comment_updated_at: '2022-10-27T20:02:56.343Z',
      community_name: 'The Pearl Florida',
      community_id: 2285,
      rssi: 99,
    },
    {
      gsm_id: '359705376202132',
      id: 7115,
      total: '1',
      failures_count: '1',
      failures_percent: '100',
      access_point_name: '(D3) Building 3920 Northwest Door',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: 'New Install- Gate is online and working ',
      comment_updated_at: '2022-10-27T20:10:07.304Z',
      community_name: 'The Pearl Florida',
      community_id: 2285,
      rssi: -103,
    },
    {
      gsm_id: '359705370351992',
      id: 5414,
      total: '268',
      failures_count: '245',
      failures_percent: '91',
      access_point_name: 'New Car Gate',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.32',
      comment: 'Car gate was out of power and then power was connected back ',
      comment_updated_at: '2022-12-08T21:36:19.849Z',
      community_name: 'Retreat at Waterside',
      community_id: 1654,
      rssi: -83,
    },
    {
      gsm_id: '359705372572744',
      id: 6450,
      total: '20',
      failures_count: '17',
      failures_percent: '85',
      access_point_name: 'Car Gate By The Office  ',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.42',
      comment:
        'Most Failures Due to FAIL: ble error: BleError: Operation was cancelled - Gate is Online and working and tested ',
      comment_updated_at: '2022-11-16T14:45:21.429Z',
      community_name: 'Sofia Austin',
      community_id: 2087,
      rssi: -51,
    },
    {
      gsm_id: '359705370351844',
      id: 5773,
      total: '496',
      failures_count: '353',
      failures_percent: '71',
      access_point_name: 'Left Vehicle Gate',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: 'Reset was requested from the property- Once reset is completed the manager will email me back.',
      comment_updated_at: '2022-12-08T21:50:51.056Z',
      community_name: 'The Luxe of Prosper',
      community_id: 1932,
      rssi: -81,
    },
    {
      gsm_id: '359705370349889',
      id: 5689,
      total: '350',
      failures_count: '237',
      failures_percent: '68',
      access_point_name: 'Car Gate LEFT',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: 'Reset was requested from the property- Once reset is completed the manager will email me back.',
      comment_updated_at: '2022-12-08T21:50:54.888Z',
      community_name: 'The Mansions of Prosper',
      community_id: 1893,
      rssi: -91,
    },
    {
      gsm_id: '352682050277895',
      id: 4034,
      total: '95',
      failures_count: '64',
      failures_percent: '67',
      access_point_name: 'Cars Gate',
      gsm_hardware_version: 'EZ863 NAG',
      gsm_firmware_version: 'Version: 1.0.0.35 - 1.00.12 - 08.01.2018',
      comment: null,
      comment_updated_at: null,
      community_name: 'Windsor Turtle Creek',
      community_id: 1316,
      rssi: -97,
    },
    {
      gsm_id: '359705370347990',
      id: 5691,
      total: '447',
      failures_count: '267',
      failures_percent: '60',
      access_point_name: 'Car Gate RIGHT',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.42',
      comment: 'Reset was requested from the property- Once reset is completed the manager will email me back.',
      comment_updated_at: '2022-12-08T21:50:51.148Z',
      community_name: 'The Mansions of Prosper',
      community_id: 1893,
      rssi: -79,
    },
    {
      gsm_id: '359705372574674',
      id: 6763,
      total: '40',
      failures_count: '24',
      failures_percent: '60',
      access_point_name: 'Guest Car Gate Entrance ',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment:
        'The Guest Car Gate needs a hard reset. I have notified the property, to perform the hard reset and to connect power back to other access points as well.',
      comment_updated_at: '2022-10-26T18:05:03.870Z',
      community_name: 'Scarlett Place Apartments',
      community_id: 2190,
      rssi: -101,
    },
    {
      gsm_id: '352682050498509',
      id: 4215,
      total: '9',
      failures_count: '5',
      failures_percent: '56',
      access_point_name: 'Gym',
      gsm_hardware_version: 'EZ863 NAG',
      gsm_firmware_version: 'Version: 1.0.0.35 - 1.00.14 - 20.05.2019',
      comment: 'Reception is terrible, unit needs replaced',
      comment_updated_at: '2022-02-23T13:26:06.336Z',
      community_name: 'Fall Creek Square',
      community_id: 1379,
      rssi: -105,
    },
    {
      gsm_id: '359705376201191',
      id: 6014,
      total: '6',
      failures_count: '3',
      failures_percent: '50',
      access_point_name: 'Bldg 7 Pedestrian Gate',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: 'gate tested from portal. gate us working and online currently. No reset conducted at this time.',
      comment_updated_at: '2022-05-27T04:23:57.735Z',
      community_name: 'Madison Spring Valley',
      community_id: 1973,
      rssi: -95,
    },
    {
      gsm_id: '359705376201209',
      id: 6674,
      total: '4',
      failures_count: '2',
      failures_percent: '50',
      access_point_name: 'D13',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: null,
      comment_updated_at: null,
      community_name: 'Union At Carrollton Square',
      community_id: 2105,
      rssi: -97,
    },
    {
      gsm_id: '359705376195831',
      id: 6189,
      total: '18',
      failures_count: '8',
      failures_percent: '44',
      access_point_name: 'Pedestrian Gate',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: 'Gate is online and working at the current time ',
      comment_updated_at: '2022-11-08T16:16:32.922Z',
      community_name: 'Westley On The Beltine',
      community_id: 2048,
      rssi: -91,
    },
    {
      gsm_id: '354592114145533',
      id: 4819,
      total: '508',
      failures_count: '220',
      failures_percent: '43',
      access_point_name: 'Car Gate Entrance',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: 'ver: 1.0.26',
      comment:
        'Minor errors to various reasons including bluetooth being turned off. Gate is online and working at this time',
      comment_updated_at: '2022-12-08T14:45:21.014Z',
      community_name: 'Sandestin at Pearland',
      community_id: 1583,
      rssi: -87,
    },
    {
      gsm_id: '352682050499010',
      id: 4111,
      total: '10',
      failures_count: '4',
      failures_percent: '40',
      access_point_name: 'Stairwell 4',
      gsm_hardware_version: 'EZ863 NAG',
      gsm_firmware_version: 'Version: 1.0.0.42a - 1.00.14 - 20.05.2019',
      comment: 'Equipment needs to be changed out to AM1',
      comment_updated_at: '2022-07-26T19:18:57.682Z',
      community_name: 'Parc at Wesley Chapel',
      community_id: 1346,
      rssi: -85,
    },
    {
      gsm_id: '359705376194651',
      id: 5952,
      total: '384',
      failures_count: '138',
      failures_percent: '36',
      access_point_name: 'Left Car Gate Entrance ',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.37',
      comment: 'Gate is working and online ',
      comment_updated_at: '2022-05-26T18:35:35.652Z',
      community_name: 'Winding Creek at The Preserve ',
      community_id: 1971,
      rssi: -87,
    },
    {
      gsm_id: '359705372566241',
      id: 6602,
      total: '6',
      failures_count: '2',
      failures_percent: '33',
      access_point_name: 'Salado Pedestrian Door ',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.42',
      comment: 'version updated to version 42 for better connectivity ',
      comment_updated_at: '2022-10-03T19:29:54.458Z',
      community_name: 'Rio West',
      community_id: 2149,
      rssi: -93,
    },
    {
      gsm_id: '359705376203734',
      id: 7017,
      total: '3',
      failures_count: '1',
      failures_percent: '33',
      access_point_name: '(D9) 3360 Building Door',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.42',
      comment: 'Door has been upgraded to ver. 42 and is online and running properly ',
      comment_updated_at: '2022-11-08T14:43:43.190Z',
      community_name: 'Park Pleasant Apartments 2',
      community_id: 2230,
      rssi: -81,
    },
    {
      gsm_id: '359705376203734',
      id: 7222,
      total: '3',
      failures_count: '1',
      failures_percent: '33',
      access_point_name: '(D9) 3360 Building Door',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.42',
      comment: 'Door Version is update and the Door is online and working properly I can see from remotes ',
      comment_updated_at: '2022-11-08T14:49:34.920Z',
      community_name: 'Park Pleasant Apartments 1',
      community_id: 2310,
      rssi: -81,
    },
    {
      gsm_id: '359705372572884',
      id: 7145,
      total: '503',
      failures_count: '138',
      failures_percent: '27',
      access_point_name: 'Car Gate Entrance Phase - 1',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: 'Gate is online and working at this current time ',
      comment_updated_at: '2022-12-05T23:11:48.077Z',
      community_name: 'Tides on Timberglen',
      community_id: 2293,
      rssi: -73,
    },
    {
      gsm_id: '359705372565011',
      id: 6681,
      total: '16',
      failures_count: '4',
      failures_percent: '25',
      access_point_name: 'Clubroom Door',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: 'Clubroom door was upgraded to version .37 for better connectivity ',
      comment_updated_at: '2022-08-30T18:39:22.479Z',
      community_name: 'HollyView',
      community_id: 2028,
      rssi: -103,
    },
    {
      gsm_id: '359705376198819',
      id: 7011,
      total: '8',
      failures_count: '2',
      failures_percent: '25',
      access_point_name: '(D3) 3339 Building Door',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: null,
      comment_updated_at: null,
      community_name: 'Park Pleasant Apartments 2',
      community_id: 2230,
      rssi: -81,
    },
    {
      gsm_id: '359705376198819',
      id: 7227,
      total: '8',
      failures_count: '2',
      failures_percent: '25',
      access_point_name: '(D3) 3339 Building Door',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: null,
      comment_updated_at: null,
      community_name: 'Park Pleasant Apartments 1',
      community_id: 2310,
      rssi: -81,
    },
    {
      gsm_id: '359705372571449',
      id: 7021,
      total: '4',
      failures_count: '1',
      failures_percent: '25',
      access_point_name: '(G1) Lot 1',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment:
        'One Resident having bluetooth issues, the gates being opened by remote seem to be working properly - Gate is online and working ',
      comment_updated_at: '2022-11-16T21:46:39.927Z',
      community_name: 'Park Pleasant Apartments 2',
      community_id: 2230,
      rssi: -95,
    },
    {
      gsm_id: '359705372571449',
      id: 7218,
      total: '4',
      failures_count: '1',
      failures_percent: '25',
      access_point_name: '(G1) Lot 1',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment:
        'Bluetooth turned off issues and device not found. a lot of errors with only 49 total attempts, device needs reset- Contacted property ',
      comment_updated_at: '2022-11-16T22:07:40.578Z',
      community_name: 'Park Pleasant Apartments 1',
      community_id: 2310,
      rssi: -95,
    },
    {
      gsm_id: '359705370348386',
      id: 5512,
      total: '30',
      failures_count: '7',
      failures_percent: '23',
      access_point_name: 'South Hallway Door',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.32',
      comment:
        'Door is online and working - version .32  sporadic errors due to mailbox time out, and the equipment failing sporadically. An upgrade in versions would okay to do. ',
      comment_updated_at: '2022-06-16T17:22:32.578Z',
      community_name: '2900 Broadmoor',
      community_id: 1868,
      rssi: -87,
    },
    {
      gsm_id: '352682050501328',
      id: 4475,
      total: '399',
      failures_count: '81',
      failures_percent: '20',
      access_point_name: 'Car Gate 2',
      gsm_hardware_version: 'EZ863 NAG',
      gsm_firmware_version: 'ver: 1.0.26',
      comment:
        'Gate is not working properly - needs to be replaced, hard to reset for now, tired to reset serveral times today but it keeps timing out',
      comment_updated_at: '2022-11-28T21:30:17.900Z',
      community_name: 'Sunset Point',
      community_id: 1468,
      rssi: -91,
    },
    {
      gsm_id: '359705376200318',
      id: 6601,
      total: '42',
      failures_count: '8',
      failures_percent: '19',
      access_point_name: 'Salado Car Gate Entrance',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: 'Gate is online and working ',
      comment_updated_at: '2022-10-26T18:32:23.389Z',
      community_name: 'Rio West',
      community_id: 2149,
      rssi: -83,
    },
    {
      gsm_id: '359705372568940',
      id: 6661,
      total: '11',
      failures_count: '2',
      failures_percent: '18',
      access_point_name: 'Car Gate - 1',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.42',
      comment:
        'Gate 1 is online - Gate 2 is going on and off line for two days now. A hard reset is suggested to the property ',
      comment_updated_at: '2022-10-27T20:26:52.234Z',
      community_name: 'The Park at Greatstone Apartments',
      community_id: 2169,
      rssi: -75,
    },
    {
      gsm_id: '359705372566936',
      id: 6153,
      total: '18',
      failures_count: '3',
      failures_percent: '17',
      access_point_name: 'Terraces Hallway Entrance Door (4)',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.37',
      comment: null,
      comment_updated_at: null,
      community_name: 'Cortland Southpark Terraces',
      community_id: 2041,
      rssi: -87,
    },
    {
      gsm_id: '350200690693512',
      id: 5025,
      total: '193',
      failures_count: '31',
      failures_percent: '16',
      access_point_name: 'Car Gate Entrance',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.42',
      comment:
        'gate is online and tested from portal. No reset completed, gate is working after most recent errors.  Minor errors due to mostly Bluetooth being cancelled or not respond, and devices not being close enough to the box to read commands etc',
      comment_updated_at: '2022-05-25T20:37:43.137Z',
      community_name: 'Riverwalk Houston ',
      community_id: 1697,
      rssi: -81,
    },
    {
      gsm_id: '359705372574120',
      id: 6757,
      total: '175',
      failures_count: '28',
      failures_percent: '16',
      access_point_name: 'Car Gate Entrance',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.42',
      comment: null,
      comment_updated_at: null,
      community_name: 'Liberty Village Apartments',
      community_id: 2075,
      rssi: -79,
    },
    {
      gsm_id: '359705372570938',
      id: 6143,
      total: '56',
      failures_count: '9',
      failures_percent: '16',
      access_point_name: 'Car Gate Entrance 4',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: null,
      comment_updated_at: null,
      community_name: 'The Crossings at Berkley Square',
      community_id: 2039,
      rssi: -83,
    },
    {
      gsm_id: '359705370356033',
      id: 5725,
      total: '198',
      failures_count: '29',
      failures_percent: '15',
      access_point_name: 'Car Gate Entrance',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: null,
      comment_updated_at: null,
      community_name: 'Aspen Creek',
      community_id: 1896,
      rssi: -79,
    },
    {
      gsm_id: '354592114146317',
      id: 4647,
      total: '132',
      failures_count: '19',
      failures_percent: '14',
      access_point_name: 'Left Car Gate',
      gsm_hardware_version: 'EZ863 NA1',
      gsm_firmware_version: 'ver: 1.0.26',
      comment: null,
      comment_updated_at: null,
      community_name: 'Cortland Avion Shadow Creek',
      community_id: 1517,
      rssi: -89,
    },
    {
      gsm_id: '359705372572413',
      id: 4040,
      total: '156',
      failures_count: '21',
      failures_percent: '13',
      access_point_name: 'P2 Car Gate',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: null,
      comment_updated_at: null,
      community_name: 'Eleven By Windsor',
      community_id: 1318,
      rssi: -81,
    },
    {
      gsm_id: '352682050501344',
      id: 4463,
      total: '110',
      failures_count: '14',
      failures_percent: '13',
      access_point_name: 'Orleans Pedestrian Gate',
      gsm_hardware_version: 'EZ863 NAG',
      gsm_firmware_version: 'ver: 1.0.26',
      comment: null,
      comment_updated_at: null,
      community_name: 'American Can Company',
      community_id: 1462,
      rssi: -87,
    },
    {
      gsm_id: '359705372569542',
      id: 5932,
      total: '16',
      failures_count: '2',
      failures_percent: '13',
      access_point_name: ' Stairwell 6',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: 'Door is online and working at this time - no errors for about 100 attempts ',
      comment_updated_at: '2022-11-16T17:08:34.333Z',
      community_name: 'Parc at Wesley Chapel',
      community_id: 1346,
      rssi: -79,
    },
    {
      gsm_id: '359705370350341',
      id: 5854,
      total: '8',
      failures_count: '1',
      failures_percent: '13',
      access_point_name: 'Gym Hallway Door 2',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: null,
      comment_updated_at: null,
      community_name: 'The Enclave At Woodridge',
      community_id: 1808,
      rssi: -87,
    },
    {
      gsm_id: '359705370350648',
      id: 3912,
      total: '419',
      failures_count: '51',
      failures_percent: '12',
      access_point_name: 'Left Car Gate',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.34',
      comment: 'Car Gate is online and working properly ',
      comment_updated_at: '2022-11-08T16:14:26.065Z',
      community_name: 'The Landing at Little Elm',
      community_id: 1277,
      rssi: -79,
    },
    {
      gsm_id: '359705370353469',
      id: 3986,
      total: '494',
      failures_count: '55',
      failures_percent: '11',
      access_point_name: 'Car Gate',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: 'Gate is online and working, no test or reset was completed. ',
      comment_updated_at: '2022-05-26T18:37:20.812Z',
      community_name: 'El Patrimonio',
      community_id: 1296,
      rssi: -89,
    },
    {
      gsm_id: '359705370347537',
      id: 5352,
      total: '286',
      failures_count: '31',
      failures_percent: '11',
      access_point_name: 'Car Gate Entrance',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.32',
      comment:
        'Gate is online and working, equipment fails to stay online sporadically. Version .32 is was the gate is on currently ',
      comment_updated_at: '2022-06-20T16:33:43.127Z',
      community_name: 'The Grand At Westchase',
      community_id: 1798,
      rssi: -87,
    },
    {
      gsm_id: '359705370355514',
      id: 4146,
      total: '105',
      failures_count: '12',
      failures_percent: '11',
      access_point_name: 'Plano Pkwy Gate',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.32',
      comment: null,
      comment_updated_at: null,
      community_name: 'Prestonwood Hills',
      community_id: 1355,
      rssi: -87,
    },
    {
      gsm_id: '354592114135674',
      id: 4100,
      total: '61',
      failures_count: '7',
      failures_percent: '11',
      access_point_name: 'Elevator Lobby by Mail Room',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: 'There was a brief issue during which one person kept hitting button, looks oknow',
      comment_updated_at: '2022-02-23T13:33:58.521Z',
      community_name: 'Metro Greenway',
      community_id: 1343,
      rssi: -91,
    },
    {
      gsm_id: '359705372574310',
      id: 6385,
      total: '159',
      failures_count: '16',
      failures_percent: '10',
      access_point_name: 'Left Car Gate Entrance ',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.42',
      comment: 'Car Gate is online and working, and the software version was upgraded to 42',
      comment_updated_at: '2022-11-08T16:13:32.656Z',
      community_name: 'Paramount at Kingwood',
      community_id: 2084,
      rssi: -87,
    },
    {
      gsm_id: '359705372568346',
      id: 6013,
      total: '10',
      failures_count: '1',
      failures_percent: '10',
      access_point_name: 'Center Pedestrian Gate',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment: 'Gate is online and working properly at this time- Gate updated to version 37',
      comment_updated_at: '2022-08-17T15:45:40.222Z',
      community_name: 'Madison Spring Valley',
      community_id: 1973,
      rssi: -85,
    },
    {
      gsm_id: '359705372568346',
      id: 6015,
      total: '10',
      failures_count: '1',
      failures_percent: '10',
      access_point_name: 'Old Module Bldg 4 Pedestrian Gate',
      gsm_hardware_version: 'AM1',
      gsm_firmware_version: '1.0.35',
      comment:
        'Module is hidden in app and not being used. It is the old module and has not been deleted out of the portal for some reason ',
      comment_updated_at: '2022-09-22T16:03:16.846Z',
      community_name: 'Madison Spring Valley',
      community_id: 1973,
      rssi: -85,
    },
  ].map((it) => ({
    ...it,
    comment_updated_at: it.comment_updated_at ? new Date(it.comment_updated_at).getTime() : null,
  }));
};

export const listGeneralStatistics = async () => {
  console.log('listGeneralStatistics');
  await sleep(500);
  return {
    opensCount: '187091',
    failuresCount: '5849',
    failuresPercent: 3,
    accessPointsCount: '2609',
    usersCount: '240368',
  };
};

export const updateDailyReportItem = async (id: string, value: Partial<GateErrorStatItemValue>) => {
  await sleep(1000);
  return { ...value, id, comment_updated_at: Date.now() };
};

export const fetchUnusedAccessPointsReport = async (interval: number) => {
  console.log('fetchUnusedAccessPointsReport', interval);
  await sleep(1000);
  return [
    {
      communityId: '1',
      accessPointId: '5',
      accessPointName: 'test',
      communityName: 'Bahrenfeld',
      comment: null,
      commentLastUpdated: Date.now(),
    },
    {
      communityId: '1',
      accessPointId: '4',
      accessPointName: 'main gate',
      communityName: 'Bahrenfeld',
      comment: null,
      lastUsageDate: Date.now(),
    },
    {
      communityId: '1',
      accessPointId: '6',
      accessPointName: 'main gate',
      communityName: 'Bahrenfeld',
      comment: null,
      lastUsageDate: Date.now() - 2 * HOUR,
    },
  ];
};
