import { format } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseIcon from '@material-ui/icons/Close';

import { generateTimeOptions, getDaysRangeString, getFormattedEndTime } from './utils';
import { IAvailabilitySchedule } from './ScheduleV2';

export interface ITimeOption {
  displayValue: string;
  value: string;
}

export interface ScheduleItemProps {
  removeSchedule: () => void;
  onChange: (schedule: IAvailabilitySchedule) => void;
  name?: string;
  schedule: IAvailabilitySchedule;
}

export const ScheduleItem: React.FC<ScheduleItemProps> = ({
  name = 'Schedule',
  removeSchedule,
  onChange,
  schedule,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const classes = useStyles();

  const endTime = useMemo(
    () => getFormattedEndTime(schedule.hours.start, schedule.hours.durationMinutes),
    [schedule.hours.start, schedule.hours.durationMinutes],
  );

  const handleRemoveClick = useCallback(() => setIsDialogOpen(true), []);
  const confirmRemoval = useCallback(() => {
    removeSchedule();
    setIsDialogOpen(false);
  }, [removeSchedule]);

  const handleDayChange = useCallback(
    (selectedDays: number[]) => {
      onChange({
        ...schedule,
        days: selectedDays,
      });
    },
    [onChange, schedule],
  );

  const handleStartTimeChange = useCallback(
    (startTime: ITimeOption | null) => {
      onChange({
        ...schedule,
        hours: {
          ...schedule.hours,
          start: startTime?.value || '',
        },
      });
    },
    [onChange, schedule],
  );

  const handleEndTimeChange = useCallback(
    (endTime: ITimeOption | null) => {
      const deltaInMs = Math.abs(
        new Date(`2023-01-01T${schedule.hours.start}:00`).getTime() -
          new Date(`2023-01-01T${endTime?.value || endTime}:00`).getTime(),
      );

      const deltaInMinutes = deltaInMs / 60000;
      const durationMinutes = deltaInMinutes === 0 ? 24 * 60 : deltaInMinutes; // handle edge case when delta is 24hours

      onChange({ ...schedule, hours: { ...schedule.hours, durationMinutes } });
    },
    [onChange, schedule],
  );

  const createTimeOptions = useCallback(
    (isEndTime: boolean) => generateTimeOptions(isEndTime, schedule.hours.start),
    [schedule],
  );

  const selectedDaysMemo = useMemo(() => {
    if (schedule.days.length > 0) {
      return getDaysRangeString(schedule.days);
    }
    return '';
  }, [schedule.days]);

  const formattedStartTime = useMemo(() => {
    if (schedule.hours.start) {
      return format(new Date(`2023-01-01T${schedule.hours.start}:00`), 'h:mm a');
    }
    return '';
  }, [schedule.hours.start]);

  const formattedEndTime = useMemo(() => {
    if (endTime) {
      return format(new Date(`2023-01-01T${endTime}:00`), 'h:mm a');
    }
    return '';
  }, [endTime]);

  const scheduleName = useMemo(() => {
    if (selectedDaysMemo) {
      if (formattedStartTime && formattedEndTime) {
        return (
          <>
            <h4 className="text-base font-medium">{selectedDaysMemo}</h4>
            <h5 className="text-sm font-medium leading-8 text-muted-foreground">{`${formattedStartTime} - ${formattedEndTime}`}</h5>
          </>
        );
      } else {
        return `${selectedDaysMemo}`;
      }
    }
    return name;
  }, [selectedDaysMemo, formattedStartTime, formattedEndTime, name]);

  const scheduleAlertName = useMemo(() => {
    if (selectedDaysMemo) {
      if (formattedStartTime && formattedEndTime) {
        return `${selectedDaysMemo} (${formattedStartTime} - ${formattedEndTime})`;
      } else {
        return `${selectedDaysMemo}`;
      }
    }
    return name;
  }, [selectedDaysMemo, formattedStartTime, formattedEndTime, name]);

  const startTimeOptions = useMemo(() => createTimeOptions(false), [createTimeOptions]);
  const endTimeOptions = useMemo(() => createTimeOptions(true), [createTimeOptions]);

  return (
    <div className={classes.scheduleItem}>
      <div>
        <div className={classes.scheduleItemHeader}>
          <Typography variant="h5">{scheduleName}</Typography>
          <IconButton onClick={handleRemoveClick} className={classes.removeButton}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>

        <Dialog open={isDialogOpen}>
          <DialogContent>
            <DialogTitle>Confirm removal</DialogTitle>

            <DialogContent>
              <DialogContentText>
                Are you sure you want to remove <span className={classes.bold}>&quot;{scheduleAlertName}&quot;</span>{' '}
                schedule?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsDialogOpen(false)}>Close</Button>
              <Button variant="contained" color="primary" onClick={confirmRemoval}>
                Remove
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>

      <FormControl>
        <InputLabel className={classes.daySelectLabel} id="days-selection-label">
          Select days...
        </InputLabel>
        <Select
          multiple
          labelId="days-selection-label"
          id="days-selection"
          className={classes.daySelect}
          value={schedule.days}
          onChange={(event) => handleDayChange(event.target.value as number[])}
        >
          <MenuItem value={1}>Monday</MenuItem>
          <MenuItem value={2}>Tuesday</MenuItem>
          <MenuItem value={3}>Wednesday</MenuItem>
          <MenuItem value={4}>Thursday</MenuItem>
          <MenuItem value={5}>Friday</MenuItem>
          <MenuItem value={6}>Saturday</MenuItem>
          <MenuItem value={0}>Sunday</MenuItem>
        </Select>
      </FormControl>

      <div className={classes.timeSelection}>
        <Autocomplete
          placeholder="From time..."
          className={classes.autocomplete}
          onChange={(event, value) => handleStartTimeChange(value)}
          renderInput={(params) => (
            <TextField {...params} value={schedule.hours.start} label="From Time..." variant="outlined" />
          )}
          getOptionLabel={(option) => option.displayValue}
          getOptionSelected={(option, value) => option.value === value?.value}
          options={startTimeOptions}
          value={startTimeOptions.find((option) => option.value === schedule.hours.start)}
        />

        <Autocomplete
          placeholder="To time..."
          className={classes.autocomplete}
          onChange={(event, value) => handleEndTimeChange(value)}
          renderInput={(params) => <TextField {...params} value={endTime} label="To Time..." variant="outlined" />}
          getOptionLabel={(option) => option.displayValue}
          getOptionSelected={(option, value) => option.value === value?.value}
          options={endTimeOptions}
          value={endTimeOptions.find((option) => option.value === endTime)}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  scheduleItem: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    borderRadius: 'calc(0.5rem - 2px)',
    border: '1px solid #e5e7eb',
    backgroundColor: 'hsl(220 14.3% 95.9%)',
    padding: '1rem',
  },
  scheduleItemHeader: {
    display: 'flex',
    alignItems: 'center',
    '& h5': {
      flexGrow: 1,
    },
  },
  removeButton: {
    height: '40px',
    width: '40px',
  },
  timeSelection: {
    marginTop: '0.75rem',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  daySelect: {
    backgroundColor: '#fff',
    border: '1px solid hsl(217 26% 93%)',
    borderRadius: '0.375rem',
  },
  daySelectLabel: {
    zIndex: 1,
    paddingLeft: '0.5rem',
  },
  autocomplete: {
    width: '100%',
    backgroundColor: '#fff',
    '&::placeholder': {
      color: 'hsl(220 8.9% 46.1%)',
    },
  },
  bold: {
    fontWeight: 500,
    color: 'hsl(224 71.4% 4.1%)',
  },
}));
