/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  listProspectsAccess,
  addProspectAccess as addProspectAccessAPI,
  activateProspectAccess as activateProspectsAccessAPI,
  deactivateProspectAccess as deactivateProspectsAccessAPI,
  deleteProspectAccess as deleteProspectsAccessAPI,
} from '../../api/gatewise_api';
import { notifyError, notifySuccess } from '../notifications/notificationsSlice';

const initialState = {
  communityID: null,
  page: {
    items: [],
    page: 0,
    limit: 10,
    total: 0,
  },
  modalStatus: {
    loading: false,
    open: false,
  },
  search: '',
  filter: {
    status: 'all',
  },
  inProgressActions: {},
  loading: true,
  loaded: false,
  error: null,
};

const prospectsAccessSlice = createSlice({
  name: 'prospectsAccess',
  initialState,
  reducers: {
    setCommunity: (state, action) => {
      const { page, error, search, loaded } = initialState;
      state.page = page;
      state.search = search;
      state.error = error;
      state.loaded = loaded;

      const id = action.payload;
      state.communityID = id;
    },
    setPage: (state, action) => {
      const { page, error, loaded } = action.payload;
      state.page = page;
      state.error = error;
      state.loaded = loaded;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
    updateModalStatus: (state, action) => {
      state.modalStatus = { ...state.modalStatus, ...action.payload };
    },
    updateFilter: (state, action) => {
      state.filter = action.payload;
    },
    startProspectAccessAction: (state, action) => {
      const { remoteID, actionType } = action.payload;
      state.inProgressActions[remoteID] = actionType;
    },
    finishProspectAccessAction: (state, action) => {
      const { remoteID } = action.payload;
      delete state.inProgressActions[remoteID];
    },
  },
});

export const {
  updateSearch,
  setCommunity,
  setPage,
  setLoading,
  updateModalStatus,
  updateFilter,
  finishProspectAccessAction,
  startProspectAccessAction,
} = prospectsAccessSlice.actions;

export default prospectsAccessSlice.reducer;

export const loadPage = (page, limit) => (dispatch, getState) => {
  const {
    communityID,
    search,
    filter: { status },
  } = getState().prospectsAccess;
  dispatch(setLoading(true));
  listProspectsAccess(communityID, search, status, page * limit, limit, search)
    .then(({ items, total }) => {
      dispatch(setLoading(false));
      dispatch(
        setPage({
          page: {
            items,
            page,
            limit,
            total,
          },
          loaded: true,
          error: null,
        }),
      );
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(setPage({ page: { items: [], limit: 10 }, error: error.toString() }));
    });
};

export const reloadWithSearch = (search) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().prospectsAccess;
  dispatch(updateSearch(search));
  dispatch(loadPage(0, limit));
};

export const reloadWithFilter = (filter) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().prospectsAccess;
  dispatch(updateFilter(filter));
  dispatch(loadPage(0, limit));
};

export const loadCommunity = (id) => (dispatch, getState) => {
  dispatch(setCommunity(id));
  const {
    page: { limit },
  } = getState().prospectsAccess;
  dispatch(loadPage(0, limit));
};

export const reload = () => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().prospectsAccess;
  dispatch(loadPage(0, limit));
};

export const addProspectAccess = (params) => (dispatch, getState) => {
  const { communityID } = getState().prospectsAccess;
  dispatch(updateModalStatus({ loading: true }));
  addProspectAccessAPI(communityID, params)
    .then(() => {
      dispatch(updateModalStatus({ success: true, loading: false, open: false }));
      dispatch(reload());
      dispatch(notifySuccess('Prospect access added'));
    })
    .catch(() => {
      dispatch(notifyError('Create Prospect access failed'));
    })
    .finally(() => {
      dispatch(updateModalStatus({ success: false, loading: false }));
    });
};

export const activateProspectAccess = (id) => (dispatch, getState) => {
  const { communityID } = getState().prospectsAccess;
  dispatch(startProspectAccessAction({ remoteID: id, actionType: 'activate' }));
  activateProspectsAccessAPI(communityID, id)
    .then(() => {
      dispatch(reload());
      dispatch(notifySuccess('Prospect access activated'));
    })
    .catch(() => {
      dispatch(notifyError('Prospect access activation failed'));
    })
    .finally(() => {
      dispatch(finishProspectAccessAction({ remoteID: id, actionType: 'activate' }));
    });
};

export const deactivateProspectAccess = (id) => (dispatch, getState) => {
  const { communityID } = getState().prospectsAccess;
  dispatch(startProspectAccessAction({ remoteID: id, actionType: 'deactivate' }));
  deactivateProspectsAccessAPI(communityID, id)
    .then(() => {
      dispatch(reload());
      dispatch(notifySuccess('Prospect access deactivated'));
    })
    .catch(() => {
      dispatch(notifyError('Prospect access deactivation failed'));
    })
    .finally(() => {
      dispatch(finishProspectAccessAction({ remoteID: id, actionType: 'deactivate' }));
    });
};

export const deleteProspectAccess = (communityID, id) => (dispatch) => {
  dispatch(startProspectAccessAction({ remoteID: id, actionType: 'delete' }));
  deleteProspectsAccessAPI(communityID, id)
    .then(() => {
      dispatch(reload());
      dispatch(notifySuccess('Prospect access deletion succeeded'));
    })
    .catch(() => {
      dispatch(notifyError('Prospect access deletion failed'));
    })
    .finally(() => {
      dispatch(finishProspectAccessAction({ remoteID: id, actionType: 'delete' }));
    });
};
