export enum EUnitLockType {
  REGULAR = 'REGULAR',
  DEMO = 'DEMO',
}

export type UnitLockProvider = 'IGLOO' | 'SEAM';
export type UnitLockPinStatus = 'SET' | 'SETTING' | 'UNSET';

export interface IUnitLockItem {
  id: number;
  communityId: number;
  unitId: string;
  lockId: string;
  number: string; // unit apartment number
  isActive: boolean;
  code: string;
  type: EUnitLockType;
  createdAt: Date;
  updatedAt: Date;
  provider: UnitLockProvider;
  pinStatus?: UnitLockPinStatus;
  pinId?: string;
}

export interface IUnitApartmentItem {
  unitId: string;
  unitNumber: string;
  unitType: string;
}

export enum EUnitLockActiveStatus {
  All = 'all',
  Active = 'active',
  Inactive = 'not_active',
}

export interface IUnitLockPaginationQuery {
  communityId: string;
  limit: number;
  offset: number;
  search: string;
  filterByActive: EUnitLockActiveStatus;
}

export interface IUnitLockPaginationResponse {
  items: IUnitLockItem[];
  totalCount: number;
}
