import { isBlank } from './validation';
import { inverse } from './fn';

export type SortDirection = 'asc' | 'desc';

export function toggleSortDirection(current: SortDirection | null): SortDirection | null {
  if (current === null) {
    return 'asc';
  }
  return current === 'asc' ? 'desc' : null;
}

export type SortOptions<T> = {
  field: keyof T;
  direction: SortDirection | null;
};

export function sortTwoFields(
  a: string | number | Date | undefined | null,
  b: string | number | Date | undefined | null,
  direction: 'asc' | 'desc',
) {
  if (isBlank(a) && isBlank(b)) {
    return 0;
  }

  if (isBlank(a)) {
    return 1;
  }

  if (isBlank(b)) {
    return -1;
  }
  if (typeof a === 'string') {
    a = a.toLowerCase();
  }
  if (typeof b === 'string') {
    b = b.toLowerCase();
  }
  const orderAsc = a > b ? 1 : b > a ? -1 : 0;
  return direction === 'asc' ? orderAsc : inverse(orderAsc);
}
