/* eslint-disable no-param-reassign */
import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { listDevicesEvents } from '../../api/gatewise_api';

const initialState = {
  page: {
    items: [],
    page: 0,
    limit: 100,
    total: 0,
  },
  search: '',
  groupedBy: 'none',
  orderBy: 'created_at',
  timeFilter: 'last_day',
  powerFilter: 'none',
  rssiThreshold: '',
  loading: true,
  loaded: false,
  error: null,
  deviceType: '',
};

const devicesSlice = createSlice({
  name: 'devices_events',
  initialState,
  reducers: {
    reset: (state) => {
      const { page, error, search, loaded } = initialState;
      state.page = page;
      state.search = search;
      state.error = error;
      state.loaded = loaded;
    },
    setPage: (state, action) => {
      const { page, error, loaded } = action.payload;
      state.page = page;
      state.error = error;
      state.loaded = loaded;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
    updateGroupedBy: (state, action) => {
      state.groupedBy = action.payload;
    },
    updateOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    updateTimeFilter: (state, action) => {
      state.timeFilter = action.payload;
    },
    updatePowerFilter: (state, action) => {
      state.powerFilter = action.payload;
    },
    updateRssiThreshold: (state, action) => {
      state.rssiThreshold = action.payload;
    },
    updateDeviceType: (state, action) => {
      state.deviceType = action.payload;
    },
  },
});

export const {
  updateSearch,
  reset,
  setPage,
  setLoading,
  updateGroupedBy,
  updatePowerFilter,
  updateRssiThreshold,
  updateOrderBy,
  updateTimeFilter,
  updateDeviceType,
} = devicesSlice.actions;

export default devicesSlice.reducer;

export const loadPage = (page, limit, abortSignal) => (dispatch, getState) => {
  const { search, groupedBy, timeFilter, rssiThreshold, powerFilter } = getState().deviceEvents;
  dispatch(setLoading(true));
  let from = moment().year(2000);
  switch (timeFilter) {
    case 'last_hour':
      from = moment().subtract(1, 'hours');
      break;
    case 'last_day':
      from = moment().subtract(1, 'days');
      break;
    case 'last_week':
      from = moment().subtract(7, 'days');
      break;
    default:
      break;
  }
  listDevicesEvents({
    offset: page * limit,
    limit,
    search,
    group_by: groupedBy,
    from: from.format(),
    power_filter: powerFilter,
    rssi_threshold: rssiThreshold,
    abortSignal,
  })
    .then(({ items, total }) => {
      dispatch(setLoading(false));
      dispatch(
        setPage({
          page: {
            items,
            page,
            limit,
            total,
          },
          loaded: true,
          error: null,
        }),
      );
    })
    .catch((error) => {
      if (axios.isCancel(error)) return;

      dispatch(setLoading(false));
      dispatch(setPage({ page: { items: [], limit: 100 }, error: error.toString() }));
    });
};

export const reloadWithSearch = (search, abortSignal) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().deviceEvents;
  dispatch(updateSearch(search));
  dispatch(loadPage(0, limit, abortSignal));
};

export const reloadWithGroupBy = (groupedBy) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().deviceEvents;
  dispatch(updateGroupedBy(groupedBy));
  dispatch(loadPage(0, limit));
};

export const reloadWithOrderBy = (orderBy) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().deviceEvents;
  dispatch(updateOrderBy(orderBy));
  dispatch(loadPage(0, limit));
};

export const reloadWithTimeFilter = (timeFilter) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().deviceEvents;
  dispatch(updateTimeFilter(timeFilter));
  dispatch(loadPage(0, limit));
};

export const reloadWithPowerFilter = (timeFilter) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().deviceEvents;
  dispatch(updatePowerFilter(timeFilter));
  dispatch(loadPage(0, limit));
};

export const reloadWithRssiThreshold = (ressiThreshold, abortSignal) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().deviceEvents;
  dispatch(updateRssiThreshold(ressiThreshold));
  dispatch(loadPage(0, limit, abortSignal));
};

export const setDeviceTypeFilter = (deviceTypeFilter) => (dispatch) => {
  dispatch(updateDeviceType(deviceTypeFilter));
};

export const reload = () => (dispatch, getState) => {
  dispatch(reset());
  const {
    page: { limit },
  } = getState().deviceEvents;
  dispatch(loadPage(0, limit));
};
