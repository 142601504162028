import React, { FC } from 'react';
import { Button, Dialog, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

export interface IConfirmationDialogProps {
  title: string;
  message: string;
  confirmText: string;
  cancelText: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationDialog: FC<IConfirmationDialogProps> = (props: IConfirmationDialogProps) => {
  const classes = useStyles();

  return (
    <Dialog open={true} onClose={props.onCancel}>
      <div className={classes.dialog}>
        <div>
          <Typography className={classes.title} variant="h2">
            {props.title}
          </Typography>

          <Typography variant="subtitle2">{props.message}</Typography>
        </div>

        <div className={classes.actions}>
          <Button className={classes.button} variant="outlined" onClick={props.onCancel} color="primary">
            {props.cancelText}
          </Button>

          <Button
            className={clsx(classes.redButton, classes.button)}
            onClick={props.onConfirm}
            color="secondary"
            autoFocus
          >
            {props.confirmText}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
export default ConfirmationDialog;

const useStyles = makeStyles(() => ({
  dialog: {
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '32rem',
  },
  title: {
    marginBottom: '0.5rem',
    textTransform: 'capitalize',
    fontSize: '1.125rem',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    textTransform: 'unset',
    padding: '0.5rem 1rem',
    color: 'hsl(224 71.4% 4.1%)',
    borderColor: 'hsl(217 26% 93%)',
    '&:hover': {
      borderColor: 'hsl(217 26% 93%)',
    },
  },
  redButton: {
    color: 'white',
    backgroundColor: '#fe443e',
    borderColor: '#fe443e',
    marginLeft: '0.5rem',
    '&:hover': {
      background: 'hsl(0 84.2% 60.2% / 0.9)',
      borderColor: '#fe443e',
    },
  },
}));
