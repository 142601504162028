export function not<T>(value: T | undefined): boolean {
  return !value;
}

export function arrayToObject<T, R>(array: T[], getPair: (value: T) => [string, R]): Record<string, R> {
  return array.reduce((acc, item) => {
    const [key, value] = getPair(item);
    return { ...acc, [key]: value };
  }, {});
}

export function inverse(value: number): number {
  if (value === 0) {
    return value;
  }
  return value > 0 ? -Math.abs(value) : Math.abs(value);
}
