import React, { useEffect, useRef, useState } from 'react';
import { Header } from '../../../components/Header';
import SearchBar from 'material-ui-search-bar';
import LoadingOverlay from 'react-loading-overlay';
import { Page } from '../../../components/Page';
import {
  Checkbox,
  Container,
  ListItemText,
  OutlinedInput,
  Select,
  TablePagination,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { useRouteMatch } from 'react-router';
import CameraRpiEventsTable from './CameraRpiEventsTable';
import {
  changeCameraRpiEventImportantStatus,
  changeCameraRpiEventSeenStatus,
  downloadCameraRpiEventVideo,
  filterCameraRpiEvents,
  ICameraRpiFilter,
  loadCameraRpiEventsByCommunityId,
  paginateCameraRpiEvents,
  searchCameraRpiEvents,
  startCameraRpiEventStream,
  stopCameraRpiEventStream,
} from './cameraRpiEventsSlice';
import {
  cameraRpiActions,
  ECameraRpiEventLabel,
  ECameraRpiEventTag,
  ICameraRpiEvent,
} from '../../../app/domain/ICameraRpiEvent';
import MenuItem from '@material-ui/core/MenuItem';
import useDebounce from '../../../utils/useDebounce';
import { loadCamerasRpiByCommunityId } from '../cameras_rpi/camerasRpiSlice';
import { useLocation } from 'react-router-dom';
import CameraRpiEventStreamDialog from './CameraRpiEventStreamDialog';
import { isAvailableForCommunity } from '../cameras_rpi/is-cameras-rpi-enabled';
import NotAvailableFeatureMessage from '../../../components/NotAvailableFeatureMessage';
import moment from 'moment/moment';
import { DateRangePicker } from '../../../components/DateRangePicker';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 5,
    },
  },
};

const CameraRpiEventsPage = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const match = useRouteMatch<{ id: string }>();
  const communityId = +match.params.id;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cameraId = queryParams.get('cameraId');

  const cameraRpiEventsState = useAppSelector((state) => state.cameraRpiEvents);
  const camerasRpi = useAppSelector((state) => state.camerasRpi.camerasRpi);

  const [search, setSearch] = useState('');

  const isAvailableRef = useRef<boolean>(isAvailableForCommunity(communityId)); // Available for current community

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (isAvailableRef.current) {
      if (cameraId) {
        dispatch(
          filterCameraRpiEvents(communityId, { ...cameraRpiEventsState.filter, cameraIds: [parseInt(cameraId)] }),
        );
      } else {
        dispatch(loadCameraRpiEventsByCommunityId(communityId));
      }
      dispatch(loadCamerasRpiByCommunityId(communityId));
    }
    // eslint-disable-next-line
  }, [communityId]);

  useEffect(() => {
    if (communityId !== null && cameraRpiEventsState.search !== debouncedSearch) {
      dispatch(searchCameraRpiEvents(communityId, debouncedSearch));
    }
  }, [debouncedSearch, communityId, cameraRpiEventsState.search, dispatch]);

  const OnDownloadHandle = (event: ICameraRpiEvent) => {
    dispatch(downloadCameraRpiEventVideo(event));
  };

  const onChangeSeenStatusHandle = (cameraId: string, eventId: string, status: boolean) => {
    dispatch(changeCameraRpiEventSeenStatus(communityId, cameraId, eventId, status));
  };

  const onChangeImportantStatusHandle = (cameraId: string, eventId: string, status: boolean) => {
    dispatch(changeCameraRpiEventImportantStatus(communityId, cameraId, eventId, status));
  };

  const onPageChangeHandle = (currentPage: number) => {
    dispatch(paginateCameraRpiEvents(communityId, currentPage, cameraRpiEventsState.limit));
  };

  const onRowsPerPageChangeHandle = (limit: number) => {
    dispatch(paginateCameraRpiEvents(communityId, 0, limit));
  };

  const onStartEventStreamHandle = (event: ICameraRpiEvent) => {
    dispatch(startCameraRpiEventStream(communityId, event));
  };

  const onStopEventStreamHandle = () => {
    dispatch(stopCameraRpiEventStream());
  };

  const onFilterHandle = (newValue: any, key: keyof ICameraRpiFilter) => {
    const newFilterValue = typeof newValue === 'string' ? newValue.split(',') : newValue;
    dispatch(filterCameraRpiEvents(communityId, { ...cameraRpiEventsState.filter, [key]: newFilterValue }));
  };

  if (!isAvailableRef.current) {
    return (
      <NotAvailableFeatureMessage
        title={'Unlock AI-Based Monitoring'}
        description={
          'Gatewise Smart Cameras provide real-time monitoring and event logging to make your community safer.'
        }
        textButton={'Book a Demo'}
        onClickButton={() => window.open('https://calendly.com/d/cp4y-5pz-8v6/gatewise-demo-smart-cameras', '_blank')}
        textInsteadButton={'Coming soon'}
      />
    );
  }

  const pageTitle = 'Camera Events';
  return (
    <LoadingOverlay
      className={classes.loadingOverlay}
      active={cameraRpiEventsState.cameraRpiEventsLoading}
      spinner
      text="Loading..."
      fadeSpeed={250}
    >
      <Page title={pageTitle} className={classes.root}>
        <Container maxWidth={false}>
          <Header title={pageTitle} />
          <div className={classes.barContainer}>
            <div className={classes.barSection}>
              <SearchBar
                placeholder="Search"
                value={search}
                onCancelSearch={() => {
                  setSearch('');
                }}
                onChange={setSearch}
              />
              <FormControl className={classes.filter} style={{ width: 200 }}>
                <InputLabel id="camera-label">Camera</InputLabel>
                <Select
                  labelId="camera-label"
                  id="camera"
                  className={classes.select}
                  multiple
                  value={cameraRpiEventsState.filter.cameraIds}
                  onChange={(e) => onFilterHandle(e.target.value, 'cameraIds')}
                  input={<OutlinedInput label="Camera" />}
                  renderValue={(selected: any) =>
                    selected.map((value: number) => camerasRpi.find((c) => c.id === value)?.name).join(', ')
                  }
                  MenuProps={MenuProps}
                >
                  {camerasRpi.map((camera) => (
                    <MenuItem key={camera.id} value={camera.id}>
                      <Checkbox checked={cameraRpiEventsState.filter.cameraIds.includes(camera.id)} />
                      <ListItemText primary={camera.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.filter} style={{ width: 250 }}>
                <InputLabel id="recorded-actions-label">Recorded Actions</InputLabel>
                <Select
                  labelId="recorded-actions-label"
                  id="recorded-actions"
                  className={classes.select}
                  multiple
                  value={cameraRpiEventsState.filter.labels}
                  onChange={(e) => onFilterHandle(e.target.value, 'labels')}
                  input={<OutlinedInput label="Recorded Actions" />}
                  renderValue={(selected: any) =>
                    selected.map((value: ECameraRpiEventLabel) => cameraRpiActions[value]).join(', ')
                  }
                >
                  <MenuItem key={ECameraRpiEventLabel.PEOPLE} value={ECameraRpiEventLabel.PEOPLE}>
                    <Checkbox checked={cameraRpiEventsState.filter.labels.includes(ECameraRpiEventLabel.PEOPLE)} />
                    <ListItemText primary={cameraRpiActions[ECameraRpiEventLabel.PEOPLE]} />
                  </MenuItem>
                  <MenuItem key={ECameraRpiEventLabel.CARS} value={ECameraRpiEventLabel.CARS}>
                    <Checkbox checked={cameraRpiEventsState.filter.labels.includes(ECameraRpiEventLabel.CARS)} />
                    <ListItemText primary={cameraRpiActions[ECameraRpiEventLabel.CARS]} />
                  </MenuItem>
                  <MenuItem key={ECameraRpiEventLabel.DELIVERY} value={ECameraRpiEventLabel.DELIVERY}>
                    <Checkbox checked={cameraRpiEventsState.filter.labels.includes(ECameraRpiEventLabel.DELIVERY)} />
                    <ListItemText primary={cameraRpiActions[ECameraRpiEventLabel.DELIVERY]} />
                  </MenuItem>
                  <MenuItem key={ECameraRpiEventLabel.POLICE} value={ECameraRpiEventLabel.POLICE}>
                    <Checkbox checked={cameraRpiEventsState.filter.labels.includes(ECameraRpiEventLabel.POLICE)} />
                    <ListItemText primary={cameraRpiActions[ECameraRpiEventLabel.POLICE]} />
                  </MenuItem>
                  <MenuItem key={ECameraRpiEventLabel.DOGS} value={ECameraRpiEventLabel.DOGS}>
                    <Checkbox checked={cameraRpiEventsState.filter.labels.includes(ECameraRpiEventLabel.DOGS)} />
                    <ListItemText primary={cameraRpiActions[ECameraRpiEventLabel.DOGS]} />
                  </MenuItem>
                  <MenuItem key={ECameraRpiEventLabel.CATS} value={ECameraRpiEventLabel.CATS}>
                    <Checkbox checked={cameraRpiEventsState.filter.labels.includes(ECameraRpiEventLabel.CATS)} />
                    <ListItemText primary={cameraRpiActions[ECameraRpiEventLabel.CATS]} />
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.filter} style={{ width: 200 }}>
                <InputLabel id="tags-label">Tags</InputLabel>
                <Select
                  labelId="tags-label"
                  id="tags"
                  className={classes.select}
                  multiple
                  value={cameraRpiEventsState.filter.tags}
                  onChange={(e) => onFilterHandle(e.target.value, 'tags')}
                  input={<OutlinedInput label="Tags" />}
                  renderValue={(selected: any) =>
                    selected
                      .map((value: ECameraRpiEventTag) => {
                        return value.charAt(0).toUpperCase() + value.slice(1);
                      })
                      .join(', ')
                  }
                >
                  <MenuItem key={ECameraRpiEventTag.SEEN} value={ECameraRpiEventTag.SEEN}>
                    <Checkbox checked={cameraRpiEventsState.filter.tags.includes(ECameraRpiEventTag.SEEN)} />
                    <ListItemText primary={'Seen'} />
                  </MenuItem>
                  <MenuItem key={ECameraRpiEventTag.IMPORTANT} value={ECameraRpiEventTag.IMPORTANT}>
                    <Checkbox checked={cameraRpiEventsState.filter.tags.includes(ECameraRpiEventTag.IMPORTANT)} />
                    <ListItemText primary={'Important'} />
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={classes.barSection}>
              <FormControl>
                <DateRangePicker
                  from={cameraRpiEventsState.filter.timeFrame.from}
                  to={cameraRpiEventsState.filter.timeFrame.to}
                  onChange={(range) =>
                    onFilterHandle({ ...cameraRpiEventsState.filter.timeFrame, ...range }, 'timeFrame')
                  }
                  shouldDisableDateTo={(date) => date > moment()}
                  shouldDisableDateFrom={(date) => date < moment().subtract(31, 'days')}
                />
              </FormControl>
            </div>
          </div>
          <TablePagination
            component="div"
            count={cameraRpiEventsState.totalCameraRpiEvents}
            onPageChange={(_, currentPage) => {
              onPageChangeHandle(currentPage);
            }}
            onRowsPerPageChange={(event) => {
              onRowsPerPageChangeHandle(+event.target.value);
            }}
            page={cameraRpiEventsState.page}
            rowsPerPage={cameraRpiEventsState.limit}
            rowsPerPageOptions={[10, 25, 100]}
          />
          <CameraRpiEventsTable
            items={cameraRpiEventsState.cameraRpiEvents}
            onChangeSeenStatus={onChangeSeenStatusHandle}
            onChangeImportantStatus={onChangeImportantStatusHandle}
            onStartEventStream={onStartEventStreamHandle}
          />
          {cameraRpiEventsState.eventStreamDialog.open && (
            <CameraRpiEventStreamDialog
              url={cameraRpiEventsState.eventStreamDialog.url}
              open={cameraRpiEventsState.eventStreamDialog.open}
              cameraRpiEvent={cameraRpiEventsState.eventStreamDialog.event}
              isDownloading={cameraRpiEventsState.eventStreamDialog.isDownloading}
              onDownload={OnDownloadHandle}
              onClose={onStopEventStreamHandle}
            />
          )}
        </Container>
      </Page>
    </LoadingOverlay>
  );
};

export default CameraRpiEventsPage;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  loadingOverlay: {
    flexGrow: 1,
  },
  barContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  barSection: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  filter: {
    height: 48,
    '& label': {
      left: 14,
      top: -6,
      color: '#546e7a',
    },
    backgroundColor: 'white',
    borderRadius: 4,
  },
  select: {
    width: '100%',
    height: 48,
  },
}));
