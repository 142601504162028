import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppSelector, GetState } from '../../app/store';
import { SortOptions, sortTwoFields } from '../../utils/sorting';
import { createSelector } from 'reselect';
import { notifyError, notifySuccess } from '../notifications/notificationsSlice';
import { fetchUnusedAccessPointsReport, updateUnusedAccessPointItem } from '../../api/gatewise_api';
import { UnusedAccessPointsReportItem, UnusedAccessPointsReportItemValue } from './UnusedAccessPointsReportItem';

type UnusedAccessPointsReportState = {
  items: UnusedAccessPointsReportItem[];
  sortOptions: SortOptions<UnusedAccessPointsReportItem>;
};

const initialState: UnusedAccessPointsReportState = {
  items: [],
  sortOptions: {
    field: 'lastUsageDate',
    direction: 'desc',
  },
};

const { actions, reducer } = createSlice({
  name: 'unusedAccessPointsReport',
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload;
    },
    updateItem: (state, { payload }: PayloadAction<{ id: string; value: Partial<UnusedAccessPointsReportItem> }>) => {
      state.items = state.items.map((value) => (payload.id === value.id ? { ...value, ...payload.value } : value));
    },
    setSortOptions: (state, action: PayloadAction<SortOptions<UnusedAccessPointsReportItem>>) => {
      state.sortOptions = action.payload;
    },
  },
});

export const unusedAccessPointsReportReducer = reducer;
export const { setSortOptions } = actions;

const getReport: AppSelector<UnusedAccessPointsReportItem[]> = (state) => state.unusedAccessPointsReport.items;

export const getReportItem: AppSelector<UnusedAccessPointsReportItem> = (state, id: string) => {
  const item = state.unusedAccessPointsReport.items.find((item) => item.id === id);
  if (!item) {
    throw new Error(`Entity not found ${id}`);
  }
  return item;
};

export const getSortOptions: AppSelector<SortOptions<UnusedAccessPointsReportItem>> = (state) =>
  state.unusedAccessPointsReport.sortOptions;

export const getSortedReportItems = createSelector(getSortOptions, getReport, ({ direction, field }, items) => {
  if (!direction) {
    return items;
  }
  return [...items].sort((a, b) => {
    const fieldA = a[field];
    const fieldB = b[field];
    return sortTwoFields(fieldA, fieldB, direction);
  });
});

export const loadReport = (interval: number) => async (dispatch: AppDispatch, _: GetState) => {
  try {
    const items = await fetchUnusedAccessPointsReport(interval);
    dispatch(actions.setItems(items));
  } catch (e) {
    dispatch(notifyError(`Cannot fetch report: ${e instanceof Error ? e.message : 'unknown error'}`));
  }
};

export const updateStatItem =
  (id: string, value: Partial<UnusedAccessPointsReportItemValue>) =>
  async (dispatch: AppDispatch, getState: GetState) => {
    const oldValue = getReportItem(getState(), id);
    try {
      dispatch(actions.updateItem({ id, value }));
      dispatch(actions.updateItem({ id, value: await updateUnusedAccessPointItem(id, value) }));
      dispatch(notifySuccess('Saved'));
    } catch (e) {
      dispatch(actions.updateItem({ id, value: oldValue }));
      dispatch(notifyError(e));
    }
  };
