import * as React from 'react';
import { Card, CardContent, Avatar, Typography } from '@material-ui/core';
import CardGiftcardRoundedIcon from '@material-ui/icons/CardGiftcardRounded';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  cardHeader: {
    display: 'flex',
    padding: '16px 24px 0 24px',
    alignItems: 'flex-start',
  },
  category: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: '16px',
    marginRight: '5px',
    color: '#00b4ff',
  },
  avatar: {
    flex: '0 0 auto',
    marginRight: '16px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '10px',
  },
  dot: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    marginRight: '2px',
  },
  date: {
    paddingTop: '10px',
  },
  type: {
    color: '#00b4ff',
  },
  title: {
    fontWeight: 'bold',
  },
  content: {
    padding: '12px 24px 24px 80px',
  },
}));

const UpdateCard = (props) => {
  const { update, unseen } = props;
  const classes = useStyles();

  return (
    <Card sx={{ maxWidth: 345 }} style={{ background: unseen ? '#ffffff' : '#F3F6F8' }}>
      <div className={classes.cardHeader}>
        <Avatar className={classes.avatar} src={update.avatar_url}>
          {update.name}
        </Avatar>
        <div>
          <div className={classes.category}>
            <CardGiftcardRoundedIcon className={classes.icon} />
            <Typography variant="body1" className={classes.type}>
              {update.type}
            </Typography>
          </div>
          <Typography variant="body2" color="text.secondary">
            {`by ${update.name}`}
          </Typography>
          <div className={classes.titleContainer}>
            <div className={classes.dot} style={{ background: unseen ? '#7AFF2E' : '#bdbdbd' }} />
            <Typography variant="body1" className={classes.title}>
              {update.title}
            </Typography>
          </div>
        </div>
      </div>

      <CardContent className={classes.content}>
        <Typography variant="body2" color="text.secondary">
          {update.description}
        </Typography>
        <Typography variant="body2" color="text.secondary" className={classes.date}>
          {moment(update.release_date).format('l')}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default UpdateCard;
