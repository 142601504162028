import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  EUnitLockActiveStatus,
  IUnitApartmentItem,
  IUnitLockItem,
  IUnitLockPaginationResponse,
} from '../../../app/domain/IUnitLock';
import { AppSelector, AppThunk } from '../../../app/store';
import { notifyError, notifySuccess } from '../../notifications/notificationsSlice';
import { IUnitLocksRepo, IUnitLocksRepoKey } from '../../../app/repos/IUnitLocksRepo';

interface IUnitLockState {
  unitLockItems: IUnitLockItem[];
  unitApartmentItems: IUnitApartmentItem[];
  totalUnitLockItems: number;
  page: number;
  limit: number;
  search: string;
  filter: {
    activeStatus: EUnitLockActiveStatus;
  };
  unitLockItemsLoading: boolean;
  unitApartmentItemsLoading: boolean;
}

const initialState: IUnitLockState = {
  unitLockItems: [],
  unitApartmentItems: [],
  totalUnitLockItems: 0,
  page: 0,
  limit: 10,
  search: '',
  filter: {
    activeStatus: EUnitLockActiveStatus.All,
  },
  unitLockItemsLoading: false,
  unitApartmentItemsLoading: true,
};

const { reducer, actions } = createSlice({
  name: 'unitLocks',
  initialState,
  reducers: {
    setUnitLocks: (state, action: PayloadAction<IUnitLockPaginationResponse>) => {
      state.unitLockItems = action.payload.items;
      state.totalUnitLockItems = action.payload.totalCount;
    },
    setUnitApartments: (state, action: PayloadAction<IUnitApartmentItem[]>) => {
      state.unitApartmentItems = action.payload;
    },
    createUnitLock: (state, action: PayloadAction<IUnitLockItem>) => {
      state.unitLockItems.unshift(action.payload);
      state.totalUnitLockItems++;
    },
    updateUnitLock: (state, action: PayloadAction<IUnitLockItem>) => {
      const index = state.unitLockItems.findIndex((item) => item.id === action.payload.id);
      state.unitLockItems[index] = action.payload;
    },
    setUnitLockItemsLoading: (state, action: PayloadAction<boolean>) => {
      state.unitLockItemsLoading = action.payload;
    },
    setUnitApartmentItemsLoading: (state, action: PayloadAction<boolean>) => {
      state.unitApartmentItemsLoading = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setFilter: (state, action: PayloadAction<IUnitLockState['filter']>) => {
      state.filter = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
  },
});

export const loadUnitLocksByCommunityId =
  (communityId: string): AppThunk<void> =>
  async (dispatch, getState, { container }) => {
    try {
      dispatch(actions.setUnitLockItemsLoading(true));
      const { limit, filter, page, search } = getState().unitLocks;
      const offset = page * limit;
      const unitLocksRepo = container.resolve<IUnitLocksRepo>(IUnitLocksRepoKey);
      const response = await unitLocksRepo.fetchUnitLocksByCommunityId({
        communityId,
        limit,
        offset,
        search,
        filterByActive: filter.activeStatus,
      });
      dispatch(actions.setUnitLocks(response));
    } catch (e) {
      dispatch(notifyError(e));
    } finally {
      dispatch(actions.setUnitLockItemsLoading(false));
    }
  };

export const filterUnitLocks =
  (communityId: string, filter: IUnitLockState['filter']): AppThunk<void> =>
  async (dispatch) => {
    dispatch(actions.setPage(0));
    dispatch(actions.setFilter(filter));
    dispatch(loadUnitLocksByCommunityId(communityId));
  };

export const searchUnitLocks =
  (communityId: string, search: string): AppThunk<void> =>
  async (dispatch) => {
    dispatch(actions.setPage(0));
    dispatch(actions.setSearch(search));
    dispatch(loadUnitLocksByCommunityId(communityId));
  };

export const paginateUnitLocks =
  (communityId: string, page: number, limit: number): AppThunk<void> =>
  async (dispatch) => {
    dispatch(actions.setPage(page));
    dispatch(actions.setLimit(limit));
    dispatch(loadUnitLocksByCommunityId(communityId));
  };

export const loadUnitApartmentsByCommunityId =
  (communityId: string): AppThunk<void> =>
  async (dispatch, _getState, { container }) => {
    try {
      dispatch(actions.setUnitApartmentItemsLoading(true));
      const unitLocksRepo = container.resolve<IUnitLocksRepo>(IUnitLocksRepoKey);
      const unitApartmentItems = await unitLocksRepo.fetchUnitApartmentsByCommunityId(communityId);
      dispatch(actions.setUnitApartments(unitApartmentItems));
    } catch (e) {
      dispatch(notifyError(e));
    } finally {
      dispatch(actions.setUnitApartmentItemsLoading(false));
    }
  };

export const createUnitLock =
  (communityId: string, data: Partial<IUnitLockItem>): AppThunk<void> =>
  async (dispatch, getState, { container }) => {
    try {
      const unitLocksRepo = container.resolve<IUnitLocksRepo>(IUnitLocksRepoKey);
      const unitLock = await unitLocksRepo.createUnitLock(communityId, data);
      dispatch(actions.createUnitLock(unitLock));
      dispatch(notifySuccess('Unit lock created'));
    } catch (e) {
      dispatch(notifyError(e));
    }
  };

export const updateUnitLock =
  (communityId: string, unitLockId: number, data: Partial<IUnitLockItem>): AppThunk<void> =>
  async (dispatch, getState, { container }) => {
    try {
      const unitLocksRepo = container.resolve<IUnitLocksRepo>(IUnitLocksRepoKey);
      const unitLock = await unitLocksRepo.updateUnitLock(communityId, unitLockId, data);
      dispatch(actions.updateUnitLock(unitLock));
      dispatch(notifySuccess('Unit lock updated'));
    } catch (e) {
      dispatch(notifyError(e));
    }
  };

export const checkPinCode =
  (communityId: string, unitLockId: number): AppThunk<void> =>
  async (dispatch, getState, { container }) => {
    try {
      const unitLocksRepo = container.resolve<IUnitLocksRepo>(IUnitLocksRepoKey);
      const result = await unitLocksRepo.checkPinCode(communityId, unitLockId);
      dispatch(actions.updateUnitLock(result));
      dispatch(notifySuccess('Pin code checked'));
    } catch (e) {
      dispatch(notifyError(e));
    }
  };

export const changeUnitLockStatus =
  (communityId: string, unitLockId: number, status: boolean): AppThunk<void> =>
  async (dispatch, getState, { container }) => {
    try {
      const unitLocksRepo = container.resolve<IUnitLocksRepo>(IUnitLocksRepoKey);
      const unitLock = status
        ? await unitLocksRepo.activateUnitLock(communityId, unitLockId)
        : await unitLocksRepo.deactivateUnitLock(communityId, unitLockId);
      dispatch(actions.updateUnitLock(unitLock));
      dispatch(notifySuccess(`Unit lock ${status ? 'activated' : 'deactivated'}`));
    } catch (e) {
      dispatch(notifyError(e));
    }
  };

export const getUnitLockItems: AppSelector<IUnitLockItem[]> = (state) => {
  return state.unitLocks.unitLockItems;
};

export const getUnitApartmentItems: AppSelector<IUnitApartmentItem[]> = (state) => {
  return state.unitLocks.unitApartmentItems;
};

export default reducer;
