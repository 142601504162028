/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Button, Popover, Badge } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Notifications from 'src/features/product_updates/Notifications';
import { addProductUpdatesViewDate } from '../user/userSlice';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
  },
}));

function NotificationsPopover() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const productUpdates = useSelector((state) => state.user.productUpdates);
  const productUpdatesViewDate = useSelector((state) => state.user.productUpdatesViewDate);
  const unSeenLength = productUpdates.filter((i) => i.created_at > productUpdatesViewDate).length;
  const dispatch = useDispatch();

  const handleNotificationsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorEl(null);
    dispatch(addProductUpdatesViewDate());
  };

  const open = Boolean(anchorEl);
  const id = open ? 'product-updates' : undefined;

  return (
    <div>
      <Button className={classes.button} color="inherit" onClick={handleNotificationsClick}>
        <Badge badgeContent={unSeenLength} color="error" overlap="rectangular">
          <NotificationsIcon />
        </Badge>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleNotificationsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Notifications productUpdatesViewDate={productUpdatesViewDate} />
      </Popover>
    </div>
  );
}

export default NotificationsPopover;
