import React, { PropsWithChildren, useState } from 'react';
import { InputLabelProps, InputProps, TextField as MUITextField } from '@material-ui/core';

export function TextField({
  id,
  value,
  onChange,
  placeholder,
  error,
  label,
  InputProps,
  InputLabelProps,
  disabled,
  required,
  variant,
  fullWidth,
  className,
  type = 'text',
}: PropsWithChildren<{
  id?: string;
  value: string;
  label?: string;
  error?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  InputProps?: Partial<InputProps>;
  InputLabelProps?: Partial<InputLabelProps>;
  placeholder?: string;
  fullWidth?: boolean;
  required?: boolean;
  className?: string;
  variant?: 'standard' | 'filled' | 'outlined';
  type?: 'text' | 'password' | 'email';
}>): JSX.Element {
  const [touched, setTouched] = useState(false);
  return (
    <MUITextField
      id={id}
      className={className}
      label={label}
      disabled={disabled}
      fullWidth={fullWidth}
      required={required}
      placeholder={placeholder}
      variant={variant}
      error={touched ? !!error : false}
      helperText={touched ? error : undefined}
      value={value}
      type={type}
      InputLabelProps={InputLabelProps}
      InputProps={InputProps}
      onChange={(e) => {
        if (e.target.value && e.target.value.length > 0) {
          setTouched(true);
        }
        onChange(e.target.value);
      }}
    />
  );
}
