/* eslint-disable no-param-reassign */
import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { listDevicesOpenEvents } from '../../api/gatewise_api';

const initialState = {
  page: {
    items: [],
    page: 0,
    limit: 100,
    total: 0,
  },
  search: '',
  groupedBy: 'gsm_id',
  timeFilter: 'last_day',
  loading: true,
  error: null,
};

const devicesSlice = createSlice({
  name: 'devices_open_events',
  initialState,
  reducers: {
    reset: (state) => {
      const { page, error, search } = initialState;
      state.page = page;
      state.search = search;
      state.error = error;
    },
    setPage: (state, action) => {
      const { page, error } = action.payload;
      state.page = page;
      state.error = error;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
    updateGroupedBy: (state, action) => {
      state.groupedBy = action.payload;
    },
    updateTimeFilter: (state, action) => {
      state.timeFilter = action.payload;
    },
  },
});

export const { updateSearch, reset, setPage, setLoading, updateGroupedBy, updateTimeFilter } = devicesSlice.actions;

export default devicesSlice.reducer;

export const loadPage = (page, limit, abortSignal) => async (dispatch, getState) => {
  const { search, groupedBy, timeFilter } = getState().deviceOpenEvents;
  dispatch(setLoading(true));
  let from = moment().year(2000);
  switch (timeFilter) {
    case 'last_hour':
      from = moment().subtract(1, 'hours');
      break;
    case 'last_day':
      from = moment().subtract(1, 'days');
      break;
    case 'last_week':
      from = moment().subtract(7, 'days');
      break;
    default:
      break;
  }

  try {
    const { items, total } = await listDevicesOpenEvents({
      offset: page * limit,
      limit,
      search,
      group_by: groupedBy,
      start_time: from.format(),
      abortSignal,
    });
    dispatch(
      setPage({
        page: {
          items,
          page,
          limit,
          total,
        },
        error: null,
      }),
    );
    dispatch(setLoading(false));
  } catch (error) {
    if (axios.isCancel(error)) return;

    dispatch(setPage({ page: { items: [], limit: 100 }, error: error.toString() }));
    dispatch(setLoading(false));
  }
};

export const reloadWithSearch = (search, abortSignal) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().deviceOpenEvents;
  dispatch(updateSearch(search));
  dispatch(loadPage(0, limit, abortSignal));
};

export const reloadWithGroupBy = (groupedBy) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().deviceOpenEvents;
  dispatch(updateGroupedBy(groupedBy));
  dispatch(loadPage(0, limit));
};

export const reloadWithTimeFilter = (timeFilter) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().deviceOpenEvents;
  dispatch(updateTimeFilter(timeFilter));
  dispatch(loadPage(0, limit));
};

export const reload = () => (dispatch, getState) => {
  dispatch(reset());
  const {
    page: { limit },
  } = getState().deviceOpenEvents;
  dispatch(loadPage(0, limit));
};
