import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';
import store, { dependencies } from './app/store';
import App from './App';
import { ContainerContext } from './ContainerContext';
import { container } from './app/di';
import { ImageManagerContext } from './components/ImageUploadButton';

ReactDOM.render(
  <StoreProvider store={store}>
    <ContainerContext.Provider value={container}>
      <ImageManagerContext.Provider value={dependencies.imageManager}>
        <App />
      </ImageManagerContext.Provider>
    </ContainerContext.Provider>
  </StoreProvider>,
  document.getElementById('root'),
);
