import { AppEpic } from '../../app/store';
import { catchError, filter, from, map, of, switchMap, takeUntil, timer } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { listProductUpdates } from '../../api/gatewise_api';
import { setProductUpdates, setUserProfile } from './userSlice';
import { combineEpics } from 'redux-observable';
import { EpicActionFactory } from '../../app/EpicAction';
import { notifyError } from '../notifications/notificationsSlice';
import { isBlank, isPresent } from '../../utils/validation';
import { MIN } from '../../utils/time';

const productUpdateActionCreator = new EpicActionFactory('productUpdate');
const fetchUpdates = productUpdateActionCreator.create('fetchUpdates');

export const startProductUpdatesObservation: AppEpic = (action$) => {
  const setUserProfile$ = action$.pipe(filter(setUserProfile.match));
  const signIn$ = setUserProfile$.pipe(filter((p) => isPresent(p.payload)));
  const signOut$ = setUserProfile$.pipe(filter((p) => isBlank(p.payload)));
  return signIn$.pipe(
    switchMap(() => {
      return timer(0, 5 * MIN).pipe(
        takeUntil(signOut$),
        map(() => fetchUpdates.invoke(undefined)),
      );
    }),
  );
};

export const loadProductUpdates: AppEpic = (action$) => {
  return fetchUpdates.filter(action$).pipe(
    switchMap(() => {
      return of(null).pipe(
        takeUntil(fetchUpdates.filter(action$)),
        filter(() => !document.hidden), // Only fetch updates when the document is visible
        switchMap(() => {
          return fromPromise(listProductUpdates(0, 300000)).pipe(
            map(({ items }) => setProductUpdates(items)),
            catchError((err) => from([notifyError(err)])),
          );
        }),
      );
    }),
  );
};

export const productUpdateEpics = combineEpics(startProductUpdatesObservation, loadProductUpdates);
