import { Role } from '../../models/Role';

export type ScheduleTime = { weekday: number; weekday_start_time: string; duration: number };

export type AccessPointType =
  | 'car_gate'
  | 'laundry'
  | 'clubroom'
  | 'mailroom'
  | 'gym'
  | 'door'
  | 'pool'
  | 'elevator'
  | 'demo_apartment';

export interface DeviceInfo {
  ble: string;
  god: number;
  loe: number;
  rssi: number;
  sae: number;
  vin?: number;
  has_power?: number | boolean;
  version: string;
}

export interface AccessPointValue {
  iot_service: 'aws' | 'telit';
  gsm_to_phone_number: string;
  gsm_from_phone_number: string;
  god: number;
  fallback_method: string | null;
  support_keypad: boolean;
  allow_remote: boolean;
  show_in_app: boolean;
  snapshot_url: string;
  bluetooth_id: string | null;
  always_opened: boolean;
  gsm_id: string;
  name: string;
  type: AccessPointType;
  working_hours: ScheduleTime[];
  public_available_hours: ScheduleTime[];
  camera_sn: string | null;
  gsm_hardware_version: string;
  gsm_apn: string;
  device_info?: DeviceInfo;
  index: number;
  access_point_type_id: number;
  community_id: string;
}

export function isResettable(accessPoint: AccessPointValue): boolean {
  return (
    !!accessPoint.gsm_id &&
    accessPoint.gsm_hardware_version !== 'White gsm' &&
    accessPoint.gsm_hardware_version !== 'Blue gsm'
  );
}

export function hasSpecialSchedule(accessPoint: AccessPointValue): boolean {
  return accessPoint.public_available_hours.length > 0 || accessPoint.working_hours.length > 0;
}

const ALLOWED_REMOTE_ROLES = [Role.SuperAdmin, Role.Admin, Role.Manager, Role.Worker];

export function canOpen(accessPoint: AccessPointValue, userRole: string): boolean {
  if (!accessPoint.gsm_id) return false;
  if (!accessPoint.allow_remote) {
    return ALLOWED_REMOTE_ROLES.includes(userRole as Role);
  }

  return true;
}

export interface AccessPoint extends AccessPointValue {
  id: string;
}

export function getAlert(ap: AccessPoint): string | undefined {
  if (!hasPower(ap)) {
    return `The power to the Gatewise equipment is off at the access point named "${ap.name}".`;
  }

  return undefined;
}

export function hasPower({ device_info, gsm_id }: AccessPoint) {
  return !gsm_id || (gsm_id && !!device_info?.has_power);
}

export const isAlwaysOpen = (hours: ScheduleTime[]): boolean => {
  return hours.length === 7 && hours.every((h) => h.duration === 1440);
};

export enum AccessPointWorkingHours {
  Always = 'ALWAYS',
  Never = 'NEVER',
  Custom = 'CUSTOM',
}

// access point from server v3
export interface IAccessPointV3 {
  id: number;
  createdAt: Date;
  name: string;
  communityId: number;
  accessPointTypeId: number;
}
