import {
  fetchUnusedAccessPointsReport,
  fetchCommunityUsageReport,
  listDailyErrors,
  listGates,
  listGeneralStatistics,
  updateDailyReportItem,
} from '../api/gatewise_api';

export const deps = {
  listGeneralStatistics,
  listDailyErrors,
  listGates,
  updateDailyReportItem,
  fetchUnusedAccessPointsReport,
  fetchCommunityUsageReport,
};
