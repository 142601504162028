import React, { FC } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { ICameraRpi } from '../../../app/domain/ICameraRpi';
import { makeStyles } from '@material-ui/core/styles';
import WebrtcPlayer from '../../../components/WebrtcPlayer';

interface IProps {
  open: boolean;
  url: string;
  cameraRpi: ICameraRpi | null;
  onClose: () => void;
}

const CameraRpiLiveStreamDialog: FC<IProps> = (props) => {
  const { open, url, cameraRpi, onClose } = props;
  const classes = useStyles();

  return (
    <Dialog maxWidth={'lg'} onClose={onClose} open={open}>
      <DialogTitle className={classes.title}>{`${cameraRpi?.name}: Live Recording`}</DialogTitle>
      <IconButton className={classes.closeIconBtn} onClick={onClose}>
        <Close />
      </IconButton>
      <DialogContent>
        <Box className={classes.videoWrapper}>
          <WebrtcPlayer url={url} isProxied />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CameraRpiLiveStreamDialog;

const useStyles = makeStyles((theme) => ({
  title: {
    '& h2': {
      letterSpacing: '-0.025rem',
      lineHeight: '1rem',
      textTransform: 'capitalize',
      fontSize: '1.125rem',
    },
  },
  closeIconBtn: {
    position: 'absolute',
    right: 8,
    top: 1,
    color: theme.palette.grey[500],
  },
  videoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '800px',
    aspectRatio: '16 / 9',
  },
}));
