import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import { Button, Grid, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

export function Header({
  title,
  className,
  children,
}: PropsWithChildren<{
  title: string;
  className?: string;
}>): JSX.Element {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.root, className)}
      alignItems="flex-end"
      container
      justifyContent="space-between"
      spacing={3}
    >
      <Grid item>
        <Typography component="h1" variant="h3">
          {title}
        </Typography>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
}

export function HeaderButton({
  onClick,
  children,
  disabled,
}: PropsWithChildren<{ onClick: () => void; disabled?: boolean }>): JSX.Element {
  const classes = useStyles();

  return (
    <Button className={classes.action} onClick={onClick} color="primary" variant="contained" disabled={disabled}>
      {children}
    </Button>
  );
}

export function HeaderButtonLink({ to, children }: PropsWithChildren<{ to: string }>): JSX.Element {
  const classes = useStyles();
  return (
    <Button component={RouterLink} className={classes.action} to={to} color="primary" variant="contained">
      {children}
    </Button>
  );
}

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' },
  action: { marginLeft: theme.spacing(1) },
}));
