import React, { Fragment } from 'react';
import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import NavItem from '../components/NavItem';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getCustomToken } from '../features/user/selectors';

const Content = ({ navItems }) => {
  const classes = useStyles();

  const usersCustomToken = useSelector(getCustomToken);

  const onRedirectHandle = (e, to) => {
    if (!to.startsWith('http')) return;
    e.preventDefault();
    window.location.href = to + `?token=${encodeURIComponent(usersCustomToken)}`;
  };

  return (
    <div className={classes.root}>
      <nav className={classes.navigation}>
        <List>
          {navItems.map(({ divider, href, icon, label, title, error, shouldOpenNewTab }) => {
            return (
              <Fragment key={title}>
                {divider && <div className={classes.separator} key="separator" />}
                <NavItem
                  onClick={(e) => onRedirectHandle(e, href)}
                  href={href}
                  icon={icon}
                  key={href}
                  label={label}
                  title={title}
                  error={error}
                  shouldOpenNewTab={shouldOpenNewTab}
                />
              </Fragment>
            );
          })}
        </List>
      </nav>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  separator: {
    backgroundColor: 'black',
    opacity: 0.1,
    height: 1,
    margin: theme.spacing(1),
  },
}));

Content.propTypes = {
  navItems: PropTypes.array.isRequired,
};

export default Content;
