import moment from 'moment';
import { utils, writeFile } from 'xlsx';
import { ThirdPartyType } from '../../models/ThirdPartyType';

export const calcSyncLogTitle = (community) => `${community?.integration?.type ?? 'Sync'} Log`;

export const formatDate = (moveindate, format, invalidDateStr = '') => {
  const result = moment(moveindate).format(format || 'L');
  return result === 'Invalid date' ? invalidDateStr : result;
};

export const generateExcel = (logs, community) => {
  const wb = utils.book_new();
  const ws = utils.json_to_sheet(
    logs.map((log) => ({
      Name: log.name,
      Status: log.status,
      Phone: log.phone,
      'Move In Date': moment(log.moveindate).format('lll'),
      'Move Out Date': moment(log.moveoutdate).format('lll'),
      Unit: log.unit,
      Description: log.description,
    })),
    { header: ['Name', 'Status', 'Phone', 'Move In Date', 'Move Out Date', 'Unit', 'Description'] },
  );

  const workSheetName = (community.name || '').substring(0, 31);
  const filename = `${community.name} - ${
    community?.integration?.type === ThirdPartyType.Resman ? 'Resman' : 'RealPage'
  } Log`;
  utils.book_append_sheet(wb, ws, workSheetName);
  writeFile(wb, `${filename}.xlsx`);
};
