import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAppSettings, listGeneralStatistics } from '../../api/gatewise_api';
import { AppThunk } from '../../app/store';
import { getAccessPoint } from './selectors';
import { notifyError, notifySuccess } from '../notifications/notificationsSlice';
import { SortOptions } from '../../utils/sorting';
import { GateErrorStatItem, GateErrorStatItemValue } from './GateErrorStatItem';

export enum AccessPointIssueType {
  StoppedCommunicating = 'StoppedCommunicating',
  NotWorking = 'NotWorking',
  MagLockNotWorking = 'MagLockNotWorking',
  SporadicProblems = 'SporadicProblems',
  BadReception = 'BadReception',
  PowerIssue = 'PowerIssue',
  None = 'None',
}

type GateErrorStatistics = {
  opensCount: number;
  failuresCount: number;
  failuresPercent: number;
  accessPointsCount: number;
  usersCount: number;
};

type DailyErrorsState = {
  page: {
    items: GateErrorStatItem[];
    statistics: GateErrorStatistics;
    settings: any;
  };
  sortOptions: SortOptions<GateErrorStatItem>;
  error: string | null;
};

const initialState: DailyErrorsState = {
  page: {
    items: [],
    statistics: {
      opensCount: 0,
      failuresCount: 0,
      failuresPercent: 0,
      accessPointsCount: 0,
      usersCount: 0,
    },
    settings: {},
  },
  sortOptions: {
    field: 'comment_updated_at',
    direction: null,
  },
  error: null,
};

const { actions, reducer } = createSlice({
  name: 'dailyErrors',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    updateItem: (state, { payload }: PayloadAction<{ id: string; value: Partial<GateErrorStatItemValue> }>) => {
      state.page = {
        ...state.page,
        items: state.page.items.map((value) => (payload.id === value.id ? { ...value, ...payload.value } : value)),
      };
    },
    setSortOptions: (state, action: PayloadAction<SortOptions<GateErrorStatItem>>) => {
      state.sortOptions = action.payload;
    },
    setCommentUpdatedAt: (state, action: PayloadAction<{ id: string; updatedAt: number }>) => {
      const item = state.page.items.find(({ id }) => action.payload.id === id);
      if (!item) {
        throw new Error(`Entity not found ${action.payload.id}`);
      }
      item.comment_updated_at = action.payload.updatedAt;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setPage, setError, setSortOptions } = actions;

export default reducer;

export const loadPage =
  (): AppThunk =>
  async (dispatch, _, { gatewiseApi }) => {
    try {
      const settings = await getAppSettings();
      const items = await gatewiseApi.listDailyErrors(settings.failuresPercentThreshold || 20);
      const statistics = await listGeneralStatistics();
      dispatch(setPage({ items, statistics, settings }));
    } catch (error) {
      dispatch(
        setPage({
          items: [],
          total: 0,
          statistics: {},
        }),
      );
      dispatch(setError((error as any).toString()));
    }
  };

export const updateStatItem =
  (id: string, value: Partial<GateErrorStatItemValue>): AppThunk =>
  async (dispatch, getState, { gatewiseApi }) => {
    const oldComment = getAccessPoint(getState(), id);
    try {
      dispatch(actions.updateItem({ id, value }));
      dispatch(actions.updateItem({ id, value: await gatewiseApi.updateDailyReportItem(id, value) }));
      dispatch(notifySuccess('Saved'));
    } catch (e) {
      dispatch(actions.updateItem({ id, value: oldComment }));
      dispatch(notifyError(e));
    }
  };
