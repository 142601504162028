import { isSuperAdmin } from './features/user/userSlice';
import { RootState } from './app/store';
import { ICommunity } from './app/domain/ICommunity';

export const getCurrentCommunity = (state: RootState) => state.communityDetails.details;

export const getCurrentCommunityOrFail = (state: RootState): ICommunity => {
  const currentCommunity = getCurrentCommunity(state);
  if (!currentCommunity) {
    throw new Error('No current community');
  }
  return currentCommunity;
};

export const getCommunityThirdPartyType = (state: RootState) => getCurrentCommunityOrFail(state).integration?.type;

export const getProfile = (state: RootState) => state.user.profile;

export const getIsSuperAdmin = (state: RootState) => isSuperAdmin(state.user.profile);

export const getUserPermissions = (state: RootState) => {
  return Array.isArray((state.user.profile as any)?.permissions) ? (state.user.profile as any)?.permissions : [];
};
