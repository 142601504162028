import { ICameraRpi } from '../../../app/domain/ICameraRpi';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../../app/store';
import { notifyError, notifySuccess } from '../../notifications/notificationsSlice';
import { ICamerasRpiRepo, ICamerasRpiRepoKey } from '../../../app/repos/ICamerasRpiRepo';

interface ICamerasRpiState {
  camerasRpi: ICameraRpi[];
  camerasRpiLoading: boolean;
  hotspotDialog: {
    open: boolean;
    cameraRpi: ICameraRpi | null;
    hotspotPassword: string | null;
  };
  liveStreamDialog: {
    url: string;
    open: boolean;
    cameraRpi: ICameraRpi | null;
  };
  formDialog: {
    open: boolean;
    cameraRpi: ICameraRpi | null;
  };
  cameraRpiForRemove: ICameraRpi | null;
}

const initialState: ICamerasRpiState = {
  camerasRpi: [],
  camerasRpiLoading: false,
  hotspotDialog: {
    open: false,
    cameraRpi: null,
    hotspotPassword: null,
  },
  liveStreamDialog: {
    url: '',
    open: false,
    cameraRpi: null,
  },
  formDialog: {
    open: false,
    cameraRpi: null,
  },
  cameraRpiForRemove: null,
};

const { reducer, actions } = createSlice({
  name: 'camerasRpi',
  initialState,
  reducers: {
    setCamerasRpi: (state, action: PayloadAction<ICameraRpi[]>) => {
      state.camerasRpi = action.payload;
    },
    setCamerasRpiLoading: (state, action: PayloadAction<boolean>) => {
      state.camerasRpiLoading = action.payload;
    },
    setLiveStreamDialog: (state, action: PayloadAction<ICamerasRpiState['liveStreamDialog']>) => {
      state.liveStreamDialog = action.payload;
    },
    setFormDialog: (state, action: PayloadAction<ICamerasRpiState['formDialog']>) => {
      state.formDialog = action.payload;
    },
    createCameraRpi: (state, action: PayloadAction<ICameraRpi>) => {
      state.camerasRpi.push(action.payload);
    },
    updateCameraRpi: (state, action: PayloadAction<ICameraRpi>) => {
      const index = state.camerasRpi.findIndex((item) => item.id === action.payload.id);
      state.camerasRpi[index] = action.payload;
    },
    removeCameraRpi: (state, action: PayloadAction<string>) => {
      state.camerasRpi = state.camerasRpi.filter((item) => item.cameraId !== action.payload);
    },
    setCameraRpiForRemove: (state, action: PayloadAction<ICameraRpi | null>) => {
      state.cameraRpiForRemove = action.payload;
    },
    setHotspotDialog: (state, action: PayloadAction<ICamerasRpiState['hotspotDialog']>) => {
      state.hotspotDialog = action.payload;
    },
  },
});

export const { setCameraRpiForRemove } = actions;

export const loadCamerasRpiByCommunityId =
  (communityId: number): AppThunk<void> =>
  async (dispatch, _getState, { container }) => {
    try {
      dispatch(actions.setCamerasRpiLoading(true));
      const camerasRpiRepo = container.resolve<ICamerasRpiRepo>(ICamerasRpiRepoKey);
      const camerasRpi = await camerasRpiRepo.fetchCamerasRpi(communityId);
      dispatch(actions.setCamerasRpi(camerasRpi));
    } catch (e) {
      dispatch(notifyError(e));
    } finally {
      dispatch(actions.setCamerasRpiLoading(false));
    }
  };

export const startCameraRpiLiveStream =
  (cameraRpi: ICameraRpi): AppThunk<void> =>
  async (dispatch) => {
    if (!cameraRpi.cameraIp) {
      dispatch(notifyError(`Camera ${cameraRpi.name} has no IP address`));
      return;
    }
    dispatch(
      actions.setLiveStreamDialog({
        open: true,
        url: `wss://${process.env.CAMERA_RPI_PROXY_HOST}/live?remoteServerIp=${cameraRpi.cameraIp}`,
        cameraRpi,
      }),
    );
  };

export const stopCameraRpiLiveStream = (): AppThunk<void> => async (dispatch) => {
  dispatch(actions.setLiveStreamDialog({ open: false, url: '', cameraRpi: null }));
};

export const createCameraRpi =
  (communityId: number, cameraRpi: Partial<ICameraRpi>): AppThunk<void> =>
  async (dispatch, _getState, { container }) => {
    try {
      const camerasRpiRepo = container.resolve<ICamerasRpiRepo>(ICamerasRpiRepoKey);
      const createdCameraRpi = await camerasRpiRepo.createCameraRpi(communityId, cameraRpi);
      dispatch(actions.createCameraRpi(createdCameraRpi));
      dispatch(closeCameraRpiFormDialog());
      dispatch(notifySuccess('Camera created successfully'));
    } catch (e) {
      dispatch(notifyError(e));
    }
  };

export const updateCameraRpi =
  (communityId: number, cameraRpi: Partial<ICameraRpi>): AppThunk<void> =>
  async (dispatch, getState, { container }) => {
    try {
      const currentCameraRpi = getState().camerasRpi.formDialog.cameraRpi;
      if (!currentCameraRpi) {
        console.error('No cameraRpi to update');
        return;
      }
      const camerasRpiRepo = container.resolve<ICamerasRpiRepo>(ICamerasRpiRepoKey);
      await camerasRpiRepo.updateCameraRpi(communityId, cameraRpi);
      const updatedCameraRpi = JSON.parse(JSON.stringify(currentCameraRpi));
      Object.assign(updatedCameraRpi, cameraRpi);
      dispatch(actions.updateCameraRpi(updatedCameraRpi));
      dispatch(closeCameraRpiFormDialog());
      dispatch(notifySuccess('Camera updated successfully'));
    } catch (e) {
      dispatch(notifyError(e));
    }
  };

export const removeCameraRpi =
  (communityId: number): AppThunk<void> =>
  async (dispatch, getState, { container }) => {
    try {
      const cameraRpiSn = getState().camerasRpi.cameraRpiForRemove?.cameraId;
      if (!cameraRpiSn) {
        console.error('No cameraRpi to remove');
        return;
      }
      const camerasRpiRepo = container.resolve<ICamerasRpiRepo>(ICamerasRpiRepoKey);
      await camerasRpiRepo.removeCameraRpi(communityId, cameraRpiSn);
      dispatch(actions.removeCameraRpi(cameraRpiSn));
      dispatch(notifySuccess('Camera removed successfully'));
    } catch (e) {
      dispatch(notifyError(e));
    } finally {
      dispatch(actions.setCameraRpiForRemove(null));
    }
  };

export const openCameraRpiFormDialog =
  (cameraRpi: ICameraRpi | null = null): AppThunk<void> =>
  async (dispatch) => {
    dispatch(actions.setFormDialog({ open: true, cameraRpi }));
  };

export const closeCameraRpiFormDialog = (): AppThunk<void> => async (dispatch) => {
  dispatch(actions.setFormDialog({ open: false, cameraRpi: null }));
};

export const turnOnCameraRpiHotspot =
  (communityId: number, cameraRpi: ICameraRpi): AppThunk<void> =>
  async (dispatch, _getState, { container }) => {
    try {
      dispatch(actions.setHotspotDialog({ open: true, cameraRpi, hotspotPassword: null }));
      const hotspotPassword = generateHotspotPassword(8);
      const camerasRpiRepo = container.resolve<ICamerasRpiRepo>(ICamerasRpiRepoKey);
      await camerasRpiRepo.turnOnCameraRpiHotspot(communityId, cameraRpi.cameraId, hotspotPassword);
      dispatch(actions.setHotspotDialog({ open: true, cameraRpi, hotspotPassword }));
    } catch (e) {
      dispatch(actions.setHotspotDialog({ open: false, cameraRpi: null, hotspotPassword: null }));
      dispatch(notifyError(e));
    }
  };

export const closeCameraRpiHotspotDialog = (): AppThunk<void> => async (dispatch) => {
  dispatch(actions.setHotspotDialog({ open: false, cameraRpi: null, hotspotPassword: null }));
};

const generateHotspotPassword = (length: number): string => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

export default reducer;
