import { IInvitation, IResidentValue } from '../domain/IResident';
import { by, inject, keys } from '../../injectDecorators';
import { IAuthorizer, IAuthorizerKey } from '../../api/IAuthorizer';
import axios, { AxiosInstance } from 'axios';

export const IInvitationRepoKey = Symbol('IInvitationRepo');
export interface IInvitationRepo {
  updateInvitation(communityId: string, id: string, payload: Partial<IResidentValue>): Promise<Partial<IInvitation>>;

  fetchInvitationsByCommunity(communityId: string): Promise<IInvitation[]>;
}

@keys(IInvitationRepoKey)
export class InvitationRepo implements IInvitationRepo {
  constructor(@inject(by(IAuthorizerKey)) private authorizer: IAuthorizer) {}

  async fetchInvitationsByCommunity(communityId: string): Promise<IInvitation[]> {
    const httpClient = await this.createHttpClient();
    const response = await httpClient.get(`community/${communityId}/invite/all`);
    return response.data;
  }

  async updateInvitation(
    communityId: string,
    id: string,
    payload: Partial<IResidentValue>,
  ): Promise<Partial<IInvitation>> {
    const httpClient = await this.createHttpClient();
    const response = await httpClient.patch(`community/${communityId}/invite/${id}`, payload);
    return response.data;
  }

  private async createHttpClient(): Promise<AxiosInstance> {
    return axios.create({
      baseURL: process.env.API_URL_CLUSTER,
      headers: {
        Authorization: `Bearer ${await this.authorizer.getIdToken()}`,
      },
    });
  }
}
