import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { IntercomProvider } from 'react-use-intercom';
import { theme } from './theme';
import GoogleAnalytics from './components/GoogleAnalytics';
import { ScrollReset } from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
import './utils/moment';
import './assets/scss/main.scss';
import { Notifications } from './features/notifications/Notifications';
import { getIsPageLoading } from './features/user/selectors';
import { LoadingScreen } from './components/LoadingScreen';
import { monitorUserAuthentication } from './features/user/userSlice';
import { Root } from './Root';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import { CommunityDashboard } from './layouts/Dashboard/CommunityDashboard';
import AdminDashboard from './layouts/Admin';
import HomeLayout from './layouts/Home';
import { createBrowserHistory } from 'history';
import { communityTags, moduleTags } from './injectDecorators';
import { Scope } from './Scope';
import asyncComponent from './AsyncComponent';

const INTERCOM_APP_ID = 'mdbuhxww';
const LoginPage = asyncComponent(() => import('src/features/user/LoginPage'));
const ResetPasswordPage = asyncComponent(() => import('src/features/user/ResetPasswordPage'));
const ForgotPasswordPage = asyncComponent(() => import('src/features/user/ForgotPasswordPage'));

// Errors
const Error401 = asyncComponent(() => import('src/features/errors/Error401'));
const Error404 = asyncComponent(() => import('src/features/errors/Error404'));
const Error500 = asyncComponent(() => import('src/features/errors/Error500'));

// Admin
const Companies = asyncComponent(() => import('src/features/companies'));
const Communities = asyncComponent(() => import('src/features/communities/CommunitiesPage'));
const GlobalMembers = asyncComponent(() => import('src/features/global_members'));
const GlobalAccessLogs = asyncComponent(() => import('src/features/global_access_log'));
const Jobs = asyncComponent(() => import('src/features/jobs'));
const Devices = asyncComponent(() => import('src/features/devices'));
const GlobalRemotes = asyncComponent(() => import('src/features/admin/remotes'));
const ProductUpdates = asyncComponent(() => import('src/features/product_updates'));
const DeviceEvents = asyncComponent(() => import('src/features/device_events'));
const DeviceOpenEvents = asyncComponent(() => import('src/features/device_open_events'));
const DailyErrorsPage = asyncComponent(() => import('src/features/daily_errors/DailyErrorsPage'));
const UnusedAccessPointsReportPage = asyncComponent(
  () => import('src/features/unused_access_points_report/UnusedAccessPointsReportPage'),
);
const SettingsPage = asyncComponent(() => import('src/features/settings'));

const history = createBrowserHistory();
const App = () => {
  const direction = 'ltr';
  const dispatch = useDispatch();
  const isPageLoading = useSelector(getIsPageLoading);

  useEffect(() => {
    dispatch(monitorUserAuthentication());
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider direction={direction}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <ScrollReset />
            <GoogleAnalytics />
            <Notifications />
            <IntercomProvider appId={INTERCOM_APP_ID}>
              {isPageLoading ? (
                <LoadingScreen />
              ) : (
                <Switch>
                  <Route path="/" exact>
                    <Root />
                  </Route>
                  <Route path="/auth">
                    <AuthLayout>
                      <Switch>
                        <Route path="/auth/login">
                          <LoginPage />
                        </Route>
                        <Route path="/auth/reset-password">
                          <ResetPasswordPage />
                        </Route>
                        <Route path="/auth/forgot-password">
                          <ForgotPasswordPage />
                        </Route>
                        <Route path="*">
                          <Redirect to="/errors/error-404" />
                        </Route>
                      </Switch>
                    </AuthLayout>
                  </Route>
                  <Route path="/errors">
                    <ErrorLayout>
                      <Switch>
                        <Route path="/errors/error-401" exact>
                          <Error401 />
                        </Route>
                        <Route path="/errors/error-404" exact>
                          <Error404 />
                        </Route>
                        <Route path="/errors/error-500" exact>
                          <Error500 />
                        </Route>
                        <Route path="*">
                          <Redirect to="/errors/error-404" />
                        </Route>
                      </Switch>
                    </ErrorLayout>
                  </Route>
                  <Route path="/managements/communities/:id">
                    <Scope tags={communityTags}>
                      <CommunityDashboard />
                    </Scope>
                  </Route>
                  <Route path="/admin">
                    <Scope tags={moduleTags}>
                      <AdminDashboard>
                        <Switch>
                          <Route path="/admin/companies" exact>
                            <Companies />
                          </Route>
                          <Route path="/admin/communities" exact>
                            <Communities />
                          </Route>
                          <Route path="/admin/members" exact>
                            <GlobalMembers />
                          </Route>
                          <Route path="/admin/access_log" exact>
                            <GlobalAccessLogs />
                          </Route>
                          <Route path="/admin/jobs" exact>
                            <Jobs />
                          </Route>
                          <Route path="/admin/devices" exact>
                            <Devices />
                          </Route>
                          <Route path="/admin/remotes" exact>
                            <GlobalRemotes />
                          </Route>
                          <Route path="/admin/product_updates" exact>
                            <ProductUpdates />
                          </Route>
                          <Route path="/admin/device_events" exact>
                            <DeviceEvents />
                          </Route>
                          <Route path="/admin/device_open_events" exact>
                            <DeviceOpenEvents />
                          </Route>
                          <Route path="/admin/daily_errors" exact>
                            <DailyErrorsPage />
                          </Route>
                          <Route path="/admin/unused_access_points_report" exact>
                            <UnusedAccessPointsReportPage />
                          </Route>
                          <Route path="/admin/settings" exact>
                            <SettingsPage />
                          </Route>
                        </Switch>
                      </AdminDashboard>
                    </Scope>
                  </Route>
                  <Route>
                    <Scope tags={moduleTags}>
                      <HomeLayout>
                        <Switch>
                          <Route path="/managements/communities" exact>
                            <Communities />
                          </Route>
                        </Switch>
                      </HomeLayout>
                    </Scope>
                  </Route>
                  <Route path="*" exact>
                    <Redirect to="/errors/error-404" />
                  </Route>
                </Switch>
              )}
            </IntercomProvider>
          </Router>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
