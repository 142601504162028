import { utils, writeFile } from 'xlsx';
import { AccessLogItem } from './accessLogsSlice';
import moment from 'moment';
import { usFormat, usFormatSs } from '../../utils/dateFormats';

export const formatMemberName = (log: AccessLogItem) => {
  if (log.visitor_key_id !== null) {
    if (log.vendor) return `Vendor Access (${log.vendor_name})`;
    else if (log.prospect) return `Prospect Access (${log.prospect_name})`;
    else return `Visitor Access (${log.member_name})`;
  } else {
    let suffix = '';
    if (log.press_tool === 'remote') suffix = log.serial_number ? `(Remote: ${log.serial_number})` : '';
    else if (log.press_tool === 'keypad') suffix = '(keypad)';
    return `${log.member_name ?? log.serial_number} ${suffix}`;
  }
};

export const formatStatus = (pressTimestamp: number, successTimestamp: number, failReason: string) => {
  if (successTimestamp) {
    return `SUCCESS: ${(moment(successTimestamp).diff(pressTimestamp, 'milliseconds') / 1000).toFixed(1)} seconds`;
  }
  if (failReason) {
    return `FAIL: ${failReason}`;
  }
  return 'CANCELLED';
};

export const generateCommunityAccessLogExcel = (logs: AccessLogItem[], communityName: string) => {
  const wb = utils.book_new();
  const ws = utils.json_to_sheet(
    logs.map((log) => ({
      'Member Name': formatMemberName(log),
      Datetime: moment(log.created_at).format(usFormat),
      'Phone #': log.phone,
      'Access Point': log.access_point_name,
      'Press Tool': log.press_tool,
      SN: log.serial_number,
    })),
  );
  const workSheetName = (communityName || '').substring(0, 31);
  utils.book_append_sheet(wb, ws, workSheetName);
  writeFile(wb, `${communityName}.xlsx`);
};

export const generateGlobalAccessLogExcel = (logs: AccessLogItem[]) => {
  const wb = utils.book_new();
  const ws = utils.json_to_sheet(
    logs.map((log) => ({
      Member: formatMemberName(log),
      Community: log.community_name,
      Datetime: moment(log.created_at).format(usFormat),
      'Phone #': log.phone,
      'Access Point': log.access_point_name,
      'Press Tool': log.press_tool,
      'Press Time': moment(log.press_timestamp).format(usFormatSs),
      'BLE Status': formatStatus(log.press_timestamp, log.ble_success_timestamp, log.ble_fail_reason),
      'GSM Status': formatStatus(log.press_timestamp, log.gsm_success_timestamp, log.gsm_fail_reason),
      Device: `${log.device_hw} ${log.device_os ? '-' : ''} ${log.device_os}`,
    })),
  );
  utils.book_append_sheet(wb, ws, 'GlobalAccessLog');
  writeFile(wb, `Access Log.xlsx`);
};
