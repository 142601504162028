/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { listJobs, deleteJob, deleteRepeatableJob, scheduleAllJobs } from '../../api/gatewise_api';
import { notifyError, notifySuccess } from '../notifications/notificationsSlice';

const initialState = {
  items: [],
  loading: true,
  inProgressItems: [],
};

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    setJobs: (state, action) => {
      const { items, error } = action.payload;
      state.items = items;
      state.error = error;
    },
    reset: (state) => {
      state.items = [];
      state.loading = false;
      state.inProgressItems = [];
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setJobInProgress: (state, action) => {
      state.inProgressItems = state.inProgressItems.concat(action.payload.jobID);
    },
    removeJobInProgress: (state, action) => {
      state.inProgressItems = state.inProgressItems.filter((j) => j !== action.payload.jobID);
    },
  },
});

export const { reset, setJobs, setLoading, setJobInProgress, removeJobInProgress } = jobsSlice.actions;

export default jobsSlice.reducer;

export const loadJobs = () => (dispatch) => {
  dispatch(setLoading(true));
  dispatch(reset());
  listJobs()
    .then(({ items }) => {
      dispatch(setLoading(false));
      dispatch(
        setJobs({
          items,
          error: null,
        }),
      );
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(
        setJobs({
          items: [],
          error: error.toString(),
        }),
      );
    });
};

export const scheduleAll = () => (dispatch) => {
  scheduleAllJobs().then(() => {
    dispatch(loadJobs());
  });
};

export const removeJob = (jobID, repeatable) => (dispatch) => {
  dispatch(setJobInProgress({ jobID }));
  const promise = repeatable ? deleteRepeatableJob(jobID) : deleteJob(jobID);
  promise
    .then(() => {
      dispatch(notifySuccess('Job removed'));
    })
    .catch(() => {
      dispatch(notifyError('Failed to remove job'));
    })
    .finally(() => {
      dispatch(removeJobInProgress({ jobID }));
      dispatch(loadJobs());
    });
};
