import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppEpic, AppThunk, RootState } from '../../../app/store';
import {
  checkCameraLiveStreamAPI,
  fetchFirstCameraById,
  gateUsageReport,
  startCameraLiveStreamAPI,
} from '../../../api/gatewise_api';
import { notifyError } from '../../notifications/notificationsSlice';
import { TimeFrame } from '../../../utils/time';
import { subDays } from 'date-fns';
import { ICamera } from '../../../app/domain/ICamera';
import { getCommunityId, getItems } from '../../access_points/selectors';
import { filter, from, ignoreElements, interval, map, mergeMap, take, takeUntil, tap } from 'rxjs';
import actionCreatorFactory from 'typescript-fsa';
import { combineEpics } from 'redux-observable';
import { isPresent } from '../../../utils/validation';

export type GateUsageReportFilter = {
  dateRange: Partial<TimeFrame>;
};

export interface IAccessPointUsageReport {
  accessPointId: string;
  count: number;
}

interface ActivityAnalyticState {
  gateUsageReport: IAccessPointUsageReport[];
  filter: GateUsageReportFilter;
  camera?: ICamera;
  videoUrl: string | null;
}

const initialState: ActivityAnalyticState = {
  filter: {
    dateRange: {
      from: subDays(new Date(), 7).getTime(),
      to: Date.now(),
    },
  },
  gateUsageReport: [],
  videoUrl: null,
};

const { actions, reducer } = createSlice({
  name: 'activityAnalytic',
  initialState,
  reducers: {
    setGateUsageReport: (state, action: PayloadAction<IAccessPointUsageReport[]>) => {
      state.gateUsageReport = action.payload;
    },
    setCamera: (state, action: PayloadAction<ICamera | undefined>) => {
      state.camera = action.payload;
    },
    setVideoUrl: (state, action: PayloadAction<string | null>) => {
      state.videoUrl = action.payload;
    },
    updateFilter: (state, action: PayloadAction<Partial<GateUsageReportFilter>>) => {
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
    },
  },
});

const getActivityAnalytic = (state: RootState) => state.activityAnalytic;
export const getFilter = (state: RootState) => getActivityAnalytic(state).filter;
export const getCamera = (state: RootState) => getActivityAnalytic(state).camera;
export const getVideoUrl = (state: RootState) => getActivityAnalytic(state).videoUrl;
export const getGateUsageReport = (state: RootState) => {
  const { gateUsageReport } = getActivityAnalytic(state);
  const gates = getItems(state);
  return gateUsageReport
    .map(({ count, accessPointId }) => {
      const gate = gates.find((g) => g.id.toString() === accessPointId);
      if (!gate) {
        return undefined;
      }
      return {
        count: count,
        name: gate.name,
      };
    })
    .filter(isPresent);
};

export default reducer;

export const updateFilter = (value: Partial<GateUsageReportFilter>) => (dispatch: AppDispatch) => {
  dispatch(actions.updateFilter(value));
};

export const loadGateUsageReportByCommunityId =
  (communityId: string, filter: GateUsageReportFilter) => async (dispatch: AppDispatch) => {
    try {
      const data = await gateUsageReport(communityId, filter);
      dispatch(actions.setGateUsageReport(data));
    } catch (e) {
      dispatch(notifyError(e));
    }
  };

export const loadFirstCameraByCommunityId =
  (communityId: string): AppThunk<void> =>
  async (dispatch) => {
    try {
      const camera = await fetchFirstCameraById(communityId);
      dispatch(actions.setCamera(camera));
    } catch (e) {
      console.log(`Failed to get live stream url - ${e}`);
    }
  };

const actionCreator = actionCreatorFactory('activityAnalytic');
export const startVideo = actionCreator<{ serialNumber: string }>('startVideo');
export const stopVideo = actionCreator('stopVideo');

const startVideoComposingEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filter(startVideo.match),
    tap(({ payload }) => {
      startCameraLiveStreamAPI(getCommunityId(state$.value)!, payload.serialNumber);
    }),
    ignoreElements(),
  );
};

const loadVideoUrl = actionCreator.async<{ serialNumber: string }, string>('loadVideoUrl');

const pollVideoUrlEpic: AppEpic = (action$) => {
  const start$ = action$.pipe(filter(startVideo.match));
  const stop$ = action$.pipe(filter(stopVideo.match));
  return start$.pipe(
    mergeMap(({ payload }) =>
      interval(5000).pipe(
        take(10),
        takeUntil(stop$),
        map(() => loadVideoUrl.started(payload)),
      ),
    ),
  );
};

const loadVideoUrlEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filter(loadVideoUrl.started.match),
    mergeMap(({ payload }) => {
      return from(checkCameraLiveStreamAPI(getCommunityId(state$.value)!, payload.serialNumber)).pipe(
        map(({ url }) =>
          url
            ? loadVideoUrl.done({ params: payload, result: url })
            : loadVideoUrl.failed({ params: payload, error: 'Url is not ready' }),
        ),
      );
    }),
  );
};

const stopVideoUrlOnLoadEpic: AppEpic = (action$) => {
  return action$.pipe(
    filter(loadVideoUrl.done.match),
    map(() => stopVideo()),
  );
};

const updateVideoUrlOnLoadEpic: AppEpic = (action$) => {
  return action$.pipe(
    filter(loadVideoUrl.done.match),
    map(({ payload }) => {
      return actions.setVideoUrl(payload.result);
    }),
  );
};

export const epic = combineEpics(
  startVideoComposingEpic,
  pollVideoUrlEpic,
  loadVideoUrlEpic,
  stopVideoUrlOnLoadEpic,
  updateVideoUrlOnLoadEpic,
);
