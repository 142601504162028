import { read, utils, writeFile } from 'xlsx';
import { Role } from '../../models/Role';
import { ThirdPartyType } from '../../models/ThirdPartyType';
import { checkIfMemberExists } from '../../api/gatewise_api';

export const parseFile = (file, onDone) =>
  new Promise((resolve, reject) => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      try {
        /* Parse data */
        const bstr = e.target.result;
        const wb = read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const rawMembers = utils.sheet_to_json(ws, { header: ['name', 'phone', 'unit'], raw: false, defval: '' });
        const members = processMembers(rawMembers);
        resolve(members);
        if (onDone) {
          onDone();
        }
      } catch (err) {
        reject(err);
      }
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  });

const isHeaderValid = (header) => {
  const headerName = header.name?.replace(/\s/g, '');
  const headerUnit = header.unit?.replace(/\s/g, '');
  const headerPhone = header.phone?.replace(/\s/g, '');

  return headerName === 'name' && headerUnit === 'unit' && headerPhone === 'phone';
};

const processMembers = (rawMembers) => {
  if (!isHeaderValid(rawMembers[0])) {
    throw new Error('File should include the following columns: name, phone, unit');
  }

  rawMembers.shift(); // First line is the header of the CSV - skipping it
  return rawMembers.filter((member) => member.phone); // We keep only the one that has phone - the real filtering is made in the BE
};

export const generateExcel = (members, communityName) => {
  const wb = utils.book_new();
  const ws = utils.json_to_sheet(
    members.map((member) => ({
      name: member.name,
      phone: member.phone,
      unit: member.unit,
      status: member.invited ? 'Pending' : 'Member',
      type: member.role === 'resident' ? 'Resident' : 'Non Resident',
    })),
    { header: ['name', 'phone', 'unit', 'status', 'type'] },
  );

  const workSheetName = (communityName || '').substring(0, 31);
  utils.book_append_sheet(wb, ws, workSheetName);
  writeFile(wb, `${communityName}.xlsx`);
};

export const isAdminOrManager = (role) => role === 'admin' || role === 'manager';

export const isAdmin = (role) => role === 'admin';

export const getUserType = (role) => {
  switch (role) {
    case Role.Admin:
      return 'Full Portal Access';
    case Role.Manager:
      return 'Restricted Portal Access';
    case Role.Worker:
      return '24/7 Gates & Doors access';
    case Role.NonResident:
      return 'Non Resident';
    case Role.Installer:
      return 'Installer';
    case Role.Resident:
    default:
      return 'Resident';
  }
};

export const isSyncAllowed = (integration) => {
  if (!integration) {
    return false;
  }

  const { type } = integration;
  if (!type) {
    return false;
  }

  return type !== ThirdPartyType.None;
};

export const checkUserWithRetries = (communityId, email, retries, retryAfter) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    let currentAttempt = 0;
    let isStillAttempt = true;
    do {
      currentAttempt++;
      const signInMethods = await checkIfMemberExists(communityId, email);
      if (signInMethods.code === 200) {
        isStillAttempt = false;
        resolve();
        break;
      } else if (currentAttempt < retries) {
        await delay(retryAfter);
      } else {
        reject(new Error('User not found'));
        break;
      }
    } while (isStillAttempt);
  });
};
