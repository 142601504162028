import React, { PropsWithChildren } from 'react';
import { Grid, TextField } from '@material-ui/core';

export type ResmanCreds = { property_id: string; account_id: string };

export function ResmanForm({
  property_id,
  account_id,
  onChange,
}: PropsWithChildren<
  {
    onChange: (data: Partial<ResmanCreds>) => void;
  } & Partial<ResmanCreds>
>) {
  return (
    <>
      <Grid item md={6} xs={6}>
        <TextField
          fullWidth
          label="Property ID"
          onChange={({ target }) => onChange({ property_id: target.value })}
          value={property_id || ''}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={6}>
        <TextField
          fullWidth
          label="Account ID"
          onChange={({ target }) => onChange({ account_id: target.value })}
          value={account_id || ''}
          variant="outlined"
        />
      </Grid>
    </>
  );
}
