import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppSelector, GetState } from '../../app/store';
import { SortOptions, sortTwoFields } from '../../utils/sorting';
import { createSelector } from 'reselect';
import { notifyError, notifySuccess } from '../notifications/notificationsSlice';
import { fetchCommunityUsageReport, updateUnusedCommunitiesReport } from '../../api/gatewise_api';
import { UnusedCommunityReportItem, UnusedCommunityReportItemValue } from './unusedCommunityReportItem';

type UnusedCommunitiesReportState = {
  items: UnusedCommunityReportItem[];
  sortOptions: SortOptions<UnusedCommunityReportItem>;
};

const initialState: UnusedCommunitiesReportState = {
  items: [],
  sortOptions: {
    field: 'lastUsageDate',
    direction: 'desc',
  },
};

const { reducer, actions } = createSlice({
  name: 'unusedCommunitiesReport',
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload;
    },
    updateItem: (state, { payload }: PayloadAction<{ id: string; value: Partial<UnusedCommunityReportItemValue> }>) => {
      state.items = state.items.map((value) => (payload.id === value.id ? { ...value, ...payload.value } : value));
    },
    setSortOptions: (state, action: PayloadAction<SortOptions<UnusedCommunityReportItem>>) => {
      state.sortOptions = action.payload;
    },
  },
});

export const unusedCommunityReportReducer = reducer;
export const { setSortOptions } = actions;

const getReport: AppSelector<UnusedCommunityReportItem[]> = (state) => state.unusedCommunityReport.items;

export const getReportItem: AppSelector<UnusedCommunityReportItem> = (state, id: string) => {
  const item = state.unusedCommunityReport.items.find((item) => item.id === id);
  if (!item) {
    throw new Error(`Entity not found ${id}`);
  }
  return item;
};

export const getSortOptions: AppSelector<SortOptions<UnusedCommunityReportItem>> = (state) =>
  state.unusedCommunityReport.sortOptions;

export const getSortedReportItems = createSelector(getSortOptions, getReport, ({ direction, field }, items) => {
  if (!direction) {
    return items;
  }
  return [...items].sort((a, b) => {
    const fieldA = a[field];
    const fieldB = b[field];
    return sortTwoFields(fieldA, fieldB, direction);
  });
});

export const loadReport = (interval: number) => async (dispatch: AppDispatch, _: GetState) => {
  try {
    const items = await fetchCommunityUsageReport(interval);
    dispatch(actions.setItems(items));
  } catch (e) {
    dispatch(notifyError(`Cannot fetch report: ${e instanceof Error ? e.message : 'unknown error'}`));
  }
};

export const updateStatItem =
  (id: string, value: Partial<UnusedCommunityReportItemValue>) => async (dispatch: AppDispatch, getState: GetState) => {
    const oldValue = getReportItem(getState(), id);
    try {
      dispatch(actions.updateItem({ id, value }));
      dispatch(actions.updateItem({ id, value: await updateUnusedCommunitiesReport(id, value) }));
      dispatch(notifySuccess('Saved'));
    } catch (e) {
      dispatch(actions.updateItem({ id, value: oldValue }));
      notifyError(e);
    }
  };
