import {
  ECameraRpiEventLabel,
  ECameraRpiEventTag,
  ICameraRpiEvent,
  ICameraRpiEventPaginationResponse,
} from '../../../app/domain/ICameraRpiEvent';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../../app/store';
import { ICameraRpiEventsRepo, ICameraRpiEventsRepoKey } from '../../../app/repos/ICameraRpiEventsRepo';
import { notifyError, notifySuccess } from '../../notifications/notificationsSlice';
import { DAY } from '../../../utils/time';

export interface ICameraRpiFilter {
  cameraIds: number[];
  labels: ECameraRpiEventLabel[];
  tags: ECameraRpiEventTag[];
  timeFrame: {
    from: number;
    to: number;
  };
}

interface ICameraRpiEventsSliceState {
  cameraRpiEvents: ICameraRpiEvent[];
  cameraRpiEventsLoading: boolean;
  totalCameraRpiEvents: number;
  page: number;
  limit: number;
  search: string;
  filter: ICameraRpiFilter;
  eventStreamDialog: {
    url: string;
    open: boolean;
    event: ICameraRpiEvent | null;
    isDownloading: boolean;
  };
}

const DEFAULT_FROM = Date.now() - 31 * DAY;

const initialState: ICameraRpiEventsSliceState = {
  cameraRpiEvents: [],
  cameraRpiEventsLoading: false,
  totalCameraRpiEvents: 0,
  page: 0,
  limit: 10,
  search: '',
  filter: {
    cameraIds: [],
    labels: [],
    tags: [],
    timeFrame: {
      from: DEFAULT_FROM,
      to: Date.now(),
    },
  },
  eventStreamDialog: {
    url: '',
    open: false,
    event: null,
    isDownloading: false,
  },
};

const { reducer, actions } = createSlice({
  name: 'cameraRpiEvents',
  initialState,
  reducers: {
    setCameraRpiEvents: (state, action: PayloadAction<ICameraRpiEventPaginationResponse>) => {
      state.cameraRpiEvents = action.payload.items;
      state.totalCameraRpiEvents = action.payload.totalCount;
    },
    setCameraRpiEventsLoading: (state, action: PayloadAction<boolean>) => {
      state.cameraRpiEventsLoading = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setFilter: (state, action: PayloadAction<ICameraRpiEventsSliceState['filter']>) => {
      state.filter = action.payload;
    },
    changeSeenStatus: (state, action: PayloadAction<{ eventId: string; isSeen: boolean }>) => {
      const cameraRpiEvent = state.cameraRpiEvents.find((item) => item.eventId === action.payload.eventId);
      if (cameraRpiEvent) {
        cameraRpiEvent.tags.seen = action.payload.isSeen;
      }
    },
    changeImportantStatus: (state, action: PayloadAction<{ eventId: string; isImportant: boolean }>) => {
      const cameraRpiEvent = state.cameraRpiEvents.find((item) => item.eventId === action.payload.eventId);
      if (cameraRpiEvent) {
        cameraRpiEvent.tags.important = action.payload.isImportant;
      }
    },
    setEventStreamDialog: (state, action: PayloadAction<ICameraRpiEventsSliceState['eventStreamDialog']>) => {
      state.eventStreamDialog = action.payload;
    },
  },
});

export const loadCameraRpiEventsByCommunityId =
  (communityId: number): AppThunk =>
  async (dispatch, getState, { container }) => {
    dispatch(actions.setCameraRpiEventsLoading(true));
    try {
      const { page, limit, search, filter } = getState().cameraRpiEvents;
      const offset = page * limit;
      const cameraRpiEventsRepo = container.resolve<ICameraRpiEventsRepo>(ICameraRpiEventsRepoKey);
      const response = await cameraRpiEventsRepo.fetchCameraRpiEventsByCommunityId({
        communityId,
        limit,
        offset,
        search,
        cameraIds: filter.cameraIds,
        labels: filter.labels,
        tags: filter.tags,
        timeFrom: filter.timeFrame.from,
        timeTo: filter.timeFrame.to,
      });
      dispatch(actions.setCameraRpiEvents(response));
    } catch (e) {
      dispatch(notifyError(e));
    } finally {
      dispatch(actions.setCameraRpiEventsLoading(false));
    }
  };

export const filterCameraRpiEvents =
  (communityId: number, filter: ICameraRpiEventsSliceState['filter']): AppThunk<void> =>
  async (dispatch) => {
    dispatch(actions.setPage(0));
    dispatch(actions.setFilter(filter));
    dispatch(loadCameraRpiEventsByCommunityId(communityId));
  };

export const searchCameraRpiEvents =
  (communityId: number, search: string): AppThunk<void> =>
  async (dispatch) => {
    dispatch(actions.setPage(0));
    dispatch(actions.setSearch(search));
    dispatch(loadCameraRpiEventsByCommunityId(communityId));
  };

export const paginateCameraRpiEvents =
  (communityId: number, page: number, limit: number): AppThunk =>
  async (dispatch) => {
    dispatch(actions.setPage(page));
    dispatch(actions.setLimit(limit));
    dispatch(loadCameraRpiEventsByCommunityId(communityId));
  };

export const downloadCameraRpiEventVideo =
  (event: ICameraRpiEvent): AppThunk =>
  async (dispatch, getState) => {
    if (!event.cameraRpi.cameraIp) {
      dispatch(notifyError(`Camera ${event.cameraRpi.name} has no IP address`));
      return;
    }

    try {
      dispatch(actions.setEventStreamDialog({ ...getState().cameraRpiEvents.eventStreamDialog, isDownloading: true }));
      const url = `https://${process.env.CAMERA_RPI_PROXY_HOST}/event/${event.cameraRpi.cameraIp}/${event.eventId}/clip.mp4`;
      const link = document.createElement('a');
      link.href = url;
      const cameraName = event.cameraRpi.name.replace(/[^a-zA-Z0-9]/g, '_');
      const createdAtStr = event.createdAt.replace(/[^a-zA-Z0-9]+/g, '_');
      link.setAttribute('download', `${cameraName}_${createdAtStr}.mp4`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      dispatch(notifyError(e));
    } finally {
      dispatch(actions.setEventStreamDialog({ ...getState().cameraRpiEvents.eventStreamDialog, isDownloading: false }));
    }
  };

export const changeCameraRpiEventSeenStatus =
  (communityId: number, cameraId: string, eventId: string, isSeen: boolean): AppThunk =>
  async (dispatch, _getState, { container }) => {
    try {
      const cameraRpiEventsRepo = container.resolve<ICameraRpiEventsRepo>(ICameraRpiEventsRepoKey);
      await cameraRpiEventsRepo.updateCameraRpiEventSeenStatus(communityId, cameraId, eventId, isSeen);
      dispatch(actions.changeSeenStatus({ eventId, isSeen }));
      dispatch(notifySuccess(`Camera Rpi Event ${isSeen ? 'marked' : 'unmarked'} as seen`));
    } catch (e) {
      dispatch(notifyError(e));
    }
  };

export const changeCameraRpiEventImportantStatus =
  (communityId: number, cameraId: string, eventId: string, isImportant: boolean): AppThunk =>
  async (dispatch, _getState, { container }) => {
    try {
      const cameraRpiEventsRepo = container.resolve<ICameraRpiEventsRepo>(ICameraRpiEventsRepoKey);
      await cameraRpiEventsRepo.updateCameraRpiEventImportantStatus(communityId, cameraId, eventId, isImportant);
      dispatch(actions.changeImportantStatus({ eventId, isImportant }));
      dispatch(notifySuccess(`Camera Rpi Event ${isImportant ? 'marked' : 'unmarked'} as important`));
    } catch (e) {
      dispatch(notifyError(e));
    }
  };

export const startCameraRpiEventStream =
  (communityId: number, event: ICameraRpiEvent): AppThunk<void> =>
  async (dispatch, _getState, { container }) => {
    if (!event.cameraRpi.cameraIp) {
      dispatch(notifyError(`Camera ${event.cameraRpi.name} has no IP address`));
      return;
    }

    dispatch(
      actions.setEventStreamDialog({
        url: `https://${process.env.CAMERA_RPI_PROXY_HOST}/vod/${event.cameraRpi.cameraIp}/${event.eventId}/index.m3u8`,
        open: true,
        event,
        isDownloading: false,
      }),
    );
    if (!event.tags?.seen) {
      dispatch(actions.changeSeenStatus({ eventId: event.eventId, isSeen: true }));
      try {
        const cameraRpiEventsRepo = container.resolve<ICameraRpiEventsRepo>(ICameraRpiEventsRepoKey);
        await cameraRpiEventsRepo.updateCameraRpiEventSeenStatus(
          communityId,
          event.cameraRpi.cameraId,
          event.eventId,
          true,
        );
      } catch (e) {
        console.error(`Failed to mark event as seen: ${e}`);
      }
    }
  };

export const stopCameraRpiEventStream = (): AppThunk<void> => async (dispatch) => {
  dispatch(actions.setEventStreamDialog({ url: '', open: false, event: null, isDownloading: false }));
};

export default reducer;
