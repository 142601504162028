import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, colors, Toolbar, Button } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import { useDispatch, connect } from 'react-redux';
import { openNewCommunityModal } from 'src/features/communities/communitiesSlice';
import { signOut } from 'src/features/user/userSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  addCommunityButton: {
    margin: theme.spacing(2.5),
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900],
    },
  },
}));

function Topbar({ isSuperAdmin, className, signedIn }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(signOut());
  };

  return (
    <AppBar className={clsx(classes.root, className)} color="primary">
      <Toolbar style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <RouterLink to="/">
          <div
            style={{
              flexDirection: 'column',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'space-between',
            }}
          >
            <img style={{ marginBottom: 5 }} width={120} alt="Logo" src="/images/logos/gatewise_logo.png" />
            <img width={120} alt="Logo" src="/images/logos/gatewise_multi.png" />
          </div>
        </RouterLink>
        <div style={{ flexDirection: 'row', alignItems: 'center' }}>
          {isSuperAdmin && (
            <Button
              className={classes.addCommunityButton}
              onClick={() => {
                dispatch(openNewCommunityModal());
              }}
              variant="contained"
            >
              Add Community
            </Button>
          )}
          {signedIn && (
            <Button className={classes.logoutButton} color="inherit" onClick={handleLogout}>
              <InputIcon className={classes.logoutIcon} />
              Sign out
            </Button>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  className: PropTypes.string,
  isSuperAdmin: PropTypes.bool,
  signedIn: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { profile } = state.user;
  return {
    signedIn: profile != null,
    isSuperAdmin: profile && (profile.roles || []).indexOf('superadmin') >= 0,
  };
};

export default connect(mapStateToProps)(Topbar);
