import { AxiosError } from 'axios';

export class ServerError extends Error {
  name = 'ServerError';

  static fromAxios({ response }: AxiosError): ServerError {
    return new ServerError(response?.data.message);
  }

  constructor(message: string) {
    super(message);

    Object.setPrototypeOf(this, ServerError.prototype);
  }
}
