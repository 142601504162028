import React, { PropsWithChildren } from 'react';
import { Grid, TextField } from '@material-ui/core';

export type RealPageCreds = { site_id: string; pmc_id: string };

export function RealPageForm({
  site_id,
  pmc_id,
  onChange,
}: PropsWithChildren<
  {
    onChange: (data: Partial<RealPageCreds>) => void;
  } & Partial<RealPageCreds>
>): JSX.Element {
  return (
    <>
      <Grid item md={6} xs={6}>
        <TextField
          fullWidth
          label="Site ID"
          onChange={({ target }) => onChange({ site_id: target.value })}
          value={site_id || ''}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={6}>
        <TextField
          fullWidth
          label="PMC ID"
          onChange={({ target }) => onChange({ pmc_id: target.value })}
          value={pmc_id || ''}
          variant="outlined"
        />
      </Grid>
    </>
  );
}
