import { FirebaseApp } from 'firebase/app';
import {
  confirmPasswordReset,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  User,
} from 'firebase/auth';
import { IAuthorizer, IAuthorizerKey } from './IAuthorizer';
import { keys, singleton, scope } from '../injectDecorators';

@singleton
@scope('root')
@keys(IAuthorizerKey)
export class Authorizer implements IAuthorizer {
  constructor(private app: FirebaseApp, private appUrl: string) {}

  onAuthStateChanged(handler: (value: User | null) => void | Promise<unknown>) {
    return onAuthStateChanged(getAuth(this.app), handler);
  }

  signInWithEmailAndPassword(email: string, password: string) {
    return signInWithEmailAndPassword(getAuth(this.app), email, password);
  }

  sendPasswordResetEmail(email: string) {
    return sendPasswordResetEmail(getAuth(this.app), email, {
      // After password reset, the user will be give the ability to go back to this page.
      // safetyParam was added since without it I have trouble to parse the email later on
      url: `${this.appUrl}?email=${email}&safetyParam=nothing`,
      handleCodeInApp: false,
    });
  }

  confirmPasswordReset(newPassword: string, code: string) {
    return confirmPasswordReset(getAuth(this.app), code, newPassword);
  }

  signOut(): void {
    signOut(getAuth(this.app));
  }

  getIdToken(): Promise<string> {
    const { currentUser } = getAuth(this.app);
    if (!currentUser) {
      throw new Error('User is not logged in');
    }

    return currentUser.getIdToken();
  }

  isLoggedIn(): boolean {
    return !!getAuth(this.app).currentUser;
  }
}
