import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {
  url: string;
  isProxied?: boolean;
}

const WebrtcPlayer: FC<IProps> = (props) => {
  const classes = useStyles();

  const videoRef = useRef<HTMLVideoElement>(null);
  const pcRef = useRef<RTCPeerConnection | null>(null);
  const wsRef = useRef<WebSocket | null>(null);
  const [error, setError] = useState<string | null>(null);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const closeConnections = useCallback(() => {
    // Close existing RTCPeerConnection if any
    if (pcRef.current) {
      pcRef.current.ontrack = null;
      pcRef.current.oniceconnectionstatechange = null;
      pcRef.current.close();
      pcRef.current = null;
    }
    // Close existing WebSocket connection if any
    if (wsRef.current) {
      wsRef.current.onopen = null;
      wsRef.current.onmessage = null;
      wsRef.current.onclose = null;
      wsRef.current.onerror = null;
      wsRef.current.close();
      wsRef.current = null;
    }
    // Clear any existing reconnect timeout
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
      reconnectTimeoutRef.current = null;
    }
  }, []);

  const connect = useCallback(async () => {
    if (!props.url) {
      return;
    }

    setError(null);

    closeConnections();

    const pc = new RTCPeerConnection({
      iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
    });
    pcRef.current = pc;

    pc.addTransceiver('video', { direction: 'recvonly' });

    pc.ontrack = (event) => {
      if (videoRef.current) {
        videoRef.current.srcObject = event.streams[0];
      }
    };

    pc.oniceconnectionstatechange = () => {
      if (pc.iceConnectionState === 'disconnected' || pc.iceConnectionState === 'failed') {
        reconnectTimeoutRef.current = setTimeout(connect, 2000);
      }
    };

    const ws = new WebSocket(props.url);
    wsRef.current = ws;

    ws.onopen = () => {
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }

      pc.onicecandidate = (event) => {
        if (event.candidate) {
          const msg = {
            type: 'webrtc/candidate',
            value: event.candidate.candidate,
          };
          ws.send(JSON.stringify(msg));
        }
      };

      pc.createOffer()
        .then((offer) => pc.setLocalDescription(offer))
        .then(() => {
          const msg = {
            type: 'webrtc/offer',
            value: pc.localDescription?.sdp,
          };
          ws.send(JSON.stringify(msg));
        })
        .catch((error) => {
          console.error('Error creating offer:', error);
          setError('Error creating offer: ' + error.message);
        });
    };

    ws.onmessage = (event) => {
      const msg = JSON.parse(event.data);
      if (msg.type === 'webrtc/candidate') {
        if (props.isProxied) {
          const proxyHost = new URL(props.url).host;
          if (msg.value.includes(proxyHost) && msg.value.includes('tcp')) {
            pc.addIceCandidate({ candidate: msg.value, sdpMid: '0' }).catch((error) =>
              console.error('Error adding ICE candidate:', error),
            );
          }
        } else {
          pc.addIceCandidate({ candidate: msg.value, sdpMid: '0' }).catch((error) =>
            console.error('Error adding ICE candidate:', error),
          );
        }
      } else if (msg.type === 'webrtc/answer') {
        pc.setRemoteDescription({ type: 'answer', sdp: msg.value }).catch((error) => {
          console.error('Error setting remote description:', error);
          setError('Error setting remote description: ' + error.message);
        });
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      setError('WebSocket connection error');
      // Attempt to reconnect after 5 seconds
      reconnectTimeoutRef.current = setTimeout(connect, 5000);
    };
  }, [props.url, props.isProxied, closeConnections]);

  useEffect(() => {
    connect().then();

    return () => {
      closeConnections();
    };
  }, [connect, closeConnections, props.url]);

  return (
    <div className={classes.webrtcPlayerWrapper}>
      {error && <div className={classes.error}>{error}</div>}
      {!error && <video className={classes.webrtcPlayer} ref={videoRef} controls autoPlay muted playsInline />}
    </div>
  );
};

export default WebrtcPlayer;

const useStyles = makeStyles(() => ({
  webrtcPlayerWrapper: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  webrtcPlayer: {
    width: '100%',
    height: '100%',
  },
  error: {
    color: 'red',
  },
}));
