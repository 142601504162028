import { RootState } from '../../app/store';
import { getAlert } from '../../app/domain/AccessPoint';
import { isPresent } from '../../utils/validation';

const getAccessPoints = (state: RootState) => state.accessPoints;
const getCommunityDetails = (state: RootState) => state.communityDetails.details;

export const getCommunityId = (state: RootState) => getCommunityDetails(state)?.id;
export const getItems = (state: RootState) => getAccessPoints(state).items;
export const getUser = (state: RootState) => state.user;
export const getIsLoading = (state: RootState) => getAccessPoints(state).loading;
export const getOpenGateStatus = (state: RootState) => getAccessPoints(state).openGateStatus;
export const getCurrentGate = (state: RootState) => getAccessPoints(state).currentAccessPoint;
export const getAccessPointsWithKeypads = ({ accessPoints }: RootState) =>
  accessPoints.items.filter((it) => it.support_keypad);

export const getAccessPointAlerts = (state: RootState): string[] =>
  getAccessPoints(state)
    .items.map((ap) => getAlert(ap))
    .filter(isPresent);
