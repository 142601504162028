import {
  ICameraRpiEvent,
  ICameraRpiEventPaginationQuery,
  ICameraRpiEventPaginationResponse,
  ICameraRpiEventStreamResponse,
} from '../domain/ICameraRpiEvent';
import axios, { AxiosInstance } from 'axios';
import { by, inject, keys } from '../../injectDecorators';
import { IAuthorizer, IAuthorizerKey } from '../../api/IAuthorizer';

export const ICameraRpiEventsRepoKey = Symbol('ICameraRpiEventsRepo');

export interface ICameraRpiEventsRepo {
  fetchCameraRpiEventsByCommunityId(query: ICameraRpiEventPaginationQuery): Promise<ICameraRpiEventPaginationResponse>;

  downloadCameraRpiEventVideo(
    communityId: number,
    cameraId: string,
    eventId: string,
    beforeStartTime: number,
    afterEndTime: number,
  ): Promise<Blob>;

  updateCameraRpiEventSeenStatus(
    communityId: number,
    cameraId: string,
    eventId: string,
    status: boolean,
  ): Promise<void>;

  updateCameraRpiEventImportantStatus(
    communityId: number,
    cameraId: string,
    eventId: string,
    status: boolean,
  ): Promise<void>;

  startCameraRpiEventStream(
    communityId: number,
    cameraRpiSn: string,
    eventId: string,
  ): Promise<ICameraRpiEventStreamResponse>;
}

@keys(ICameraRpiEventsRepoKey)
export class CameraRpiEventsRepo implements ICameraRpiEventsRepo {
  constructor(@inject(by(IAuthorizerKey)) private authorizer: IAuthorizer) {}

  async fetchCameraRpiEventsByCommunityId(
    query: ICameraRpiEventPaginationQuery,
  ): Promise<ICameraRpiEventPaginationResponse> {
    const httpClient = await this.createHttpClient();
    const response = await httpClient.get(`community/${query.communityId}/camera-rpi/event`, {
      params: {
        limit: query.limit,
        offset: query.offset,
        search: query.search,
        cameraIds: query.cameraIds,
        labels: query.labels,
        tags: query.tags,
        timeFrom: query.timeFrom,
        timeTo: query.timeTo,
      },
    });

    const items = response.data.items.map((item: ICameraRpiEvent) => ({
      ...item,
      createdAt: formatDate(new Date(item.createdAt)),
      snapshot: 'camera-rpi-event-snapshot.jpeg',
    }));

    return { items, totalCount: response.data.totalCount };
  }

  async downloadCameraRpiEventVideo(
    communityId: number,
    cameraId: string,
    eventId: string,
    beforeStartTime: number,
    afterEndTime: number,
  ): Promise<Blob> {
    const httpClient = await this.createHttpClient();
    const response = await httpClient.get(
      `community/${communityId}/camera-rpi/${cameraId}/event/${eventId}/download-video`,
      { params: { beforeStartTime, afterEndTime }, responseType: 'blob' },
    );
    return response.data;
  }

  async updateCameraRpiEventSeenStatus(
    communityId: number,
    cameraId: string,
    eventId: string,
    status: boolean,
  ): Promise<void> {
    const httpClient = await this.createHttpClient();
    await httpClient.patch(`community/${communityId}/camera-rpi/${cameraId}/event/${eventId}/tags`, { seen: status });
  }

  async updateCameraRpiEventImportantStatus(
    communityId: number,
    cameraId: string,
    eventId: string,
    status: boolean,
  ): Promise<void> {
    const httpClient = await this.createHttpClient();
    await httpClient.patch(`community/${communityId}/camera-rpi/${cameraId}/event/${eventId}/tags`, {
      important: status,
    });
  }

  async startCameraRpiEventStream(
    communityId: number,
    cameraRpiSn: string,
    eventId: string,
  ): Promise<ICameraRpiEventStreamResponse> {
    const httpClient = await this.createHttpClient();
    const response = await httpClient.post(
      `community/${communityId}/camera-rpi/${cameraRpiSn}/event/${eventId}/start-stream`,
    );
    return response.data;
  }

  private async createHttpClient(): Promise<AxiosInstance> {
    return axios.create({
      baseURL: process.env.API_V3_URL,
      headers: {
        Authorization: `Bearer ${await this.authorizer.getIdToken()}`,
      },
    });
  }
}

const formatDate = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  return date.toLocaleString('en-US', options);
};
