import moment from 'moment';
import { isPresent } from './validation';

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const SEC = 1000;
export const MIN = 60 * SEC;
export const HOUR = 60 * MIN;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;

export type TimeFrame = { from: number; to: number };

export function isFullTimeFrame(value: Partial<TimeFrame>): value is TimeFrame {
  return isPresent(value.from) && isPresent(value.to);
}
export function durationInMonth({ from, to }: TimeFrame) {
  return moment(to).diff(moment(from), 'days');
}
