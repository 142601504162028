/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import axios from 'axios';

import {
  addCommunity as apiAdd,
  listCommunities,
  schedule3rdPartyCommunitySync,
  updateCommunity as apiUpdate,
  updateCommunityGeo,
  patchCommunity as apiPatch,
  getApiKeyCompany,
  setApiKeyForCommunityByCompanyId,
} from '../../api/gatewise_api';
import { notifyError, notifySuccess } from '../notifications/notificationsSlice';

const userSlice = createSlice({
  name: 'communities',
  initialState: {
    page: {
      items: [],
      offset: 0,
      limit: 3000,
      total: 0,
    },
    search: null,
    modal: {
      editedCommunity: null,
      isNew: false,
      communityImage: null,
      uploadingImage: false,
      submitting: false,
    },
    loading: true,
    error: null,
  },
  reducers: {
    setPage: (state, action) => {
      const { page, error } = action.payload;
      state.page = page;
      state.error = error;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    closeModal: (state) => {
      state.modal = { isNew: false, editedCommunity: null, communityImage: null };
    },
    openNewCommunityModal: (state) => {
      state.modal = { isNew: true, editedCommunity: null, communityImage: null };
    },
    openEditCommunityModal: (state, action) => {
      state.modal = { isNew: false, editedCommunity: action.payload };
    },
    setCommunityImage: (state, action) => {
      state.modal.communityImage = action.payload;
    },
    setUploadingImage: (state, action) => {
      state.modal.uploadingImage = action.payload;
    },
    setSubmitting: (state, action) => {
      state.modal.submitting = action.payload;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
    setCommunitySyncLoading: (state, action) => {
      const item = state.page.items.find((i) => i.id === action.payload.communityId);

      if (item) {
        item.syncLoading = action.payload.syncLoading;
      }
    },
  },
});

export const {
  updateSearch,
  setSubmitting,
  openEditCommunityModal,
  setUploadingImage,
  setCommunityImage,
  setPage,
  openNewCommunityModal,
  setLoading,
  closeModal,
  setCommunitySyncLoading,
} = userSlice.actions;

export default userSlice.reducer;

export const loadPage = (offset, limit, abortSignal) => (dispatch, getState) => {
  dispatch(setLoading(true));
  const { search } = getState().communities;

  listCommunities(offset, limit, search, abortSignal)
    .then((items) => {
      dispatch(setLoading(false));
      dispatch(setPage({ page: { items, offset, limit }, error: null }));
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        return; // not an actual error
      }

      dispatch(setLoading(false));
      dispatch(
        setPage({
          page: {
            items: [],
            offset: 0,
            limit: 3000,
            total: 0,
          },
          error: error.toString(),
        }),
      );
    });
};

export const addCommunity = (community) => async (dispatch, getState) => {
  dispatch(setSubmitting(true));

  try {
    let updatedGeo = null;
    if (community.address) {
      updatedGeo = await getGeoByAddress(community.address);
    }
    const { data } = await apiAdd({ ...community, geo: updatedGeo });
    if (data.management_company_id) {
      await setApiKeyForCommunityByCompanyId(data.management_company_id, data.id);
    }
    dispatch(closeModal());
    const { page } = getState().communities;
    dispatch(loadPage(page.offset, page.limit));
    dispatch(notifySuccess('Community added'));
  } catch (err) {
    dispatch(notifyError('Failed to add community'));
  } finally {
    dispatch(setSubmitting(false));
  }
};

export const updateCommunity = (community) => async (dispatch, getState) => {
  dispatch(setSubmitting(true));

  try {
    dispatch(setLoading(true));
    const { page } = getState().communities;
    const previous = page.items.filter((i) => i.id === community.id)[0];
    const { geo } = community;
    let updatedGeo = geo;
    if (!community.geo || community.address !== previous.address) {
      updatedGeo = await getGeoByAddress(community.address);
    }
    await apiUpdate(community.id, { ...community, geo: updatedGeo });
    if (community.management_company_id) {
      await setApiKeyForCommunityByCompanyId(community.management_company_id, community.id);
    }
    dispatch(closeModal());
    dispatch(loadPage(page.offset, page.limit));
    dispatch(notifySuccess('Community updated'));
  } catch (err) {
    dispatch(notifyError('Failed to update community'));
  } finally {
    dispatch(setSubmitting(false));
    dispatch(setLoading(false));
  }
};

export const updateCommunitisLocations = () => async (dispatch, getState) => {
  const { page } = getState().communities;
  dispatch(setLoading(true));
  try {
    page.items.map(async (item) => {
      if (!item.geo) {
        const geo = await getGeoByAddress(item.address);
        await updateCommunityGeo(item.id, { geo });
      }
    });
    dispatch(notifySuccess('Community locations updated'));
  } catch (err) {
    dispatch(notifyError('Failed to update locations'));
  } finally {
    dispatch(setLoading(false));
    dispatch(loadPage(page.offset, page.limit));
  }
};

const getGeoByAddress = async (address) => {
  const places = new window.google.maps.places.AutocompleteService();
  const { predictions } = await places.getPlacePredictions({
    input: address,
  });
  if (predictions && predictions[0]) {
    const results = await geocodeByAddress(predictions[0].description);
    const latLng = await getLatLng(results[0]);
    return { x: latLng.lng, y: latLng.lat };
  }
  return null;
};

export const uploadCommunityImage =
  (file) =>
  (dispatch, getState, { imageManager }) => {
    dispatch(setUploadingImage(true));
    imageManager
      .uploadImage(file)
      .then((url) => {
        dispatch(setCommunityImage(url));
      })
      .catch(() => {
        dispatch(notifyError('Failed to upload image'));
      })
      .finally(() => {
        dispatch(setUploadingImage(false));
      });
  };

export const syncCommunity = (community) => async (dispatch) => {
  dispatch(setCommunitySyncLoading({ communityId: community.id, syncLoading: true }));
  try {
    await schedule3rdPartyCommunitySync(community.id);
    dispatch(notifySuccess('Sync request was sent...'));
  } catch (error) {
    dispatch(notifyError(`Failed to sync community ${community.id}`));
  } finally {
    dispatch(setCommunitySyncLoading({ communityId: community.id, syncLoading: false }));
  }
};

export const reloadWithSearch = (search, abortSignal) => (dispatch, getState) => {
  const {
    page: { limit },
  } = getState().communities;
  dispatch(updateSearch(search));
  dispatch(loadPage(0, limit, abortSignal));
};

export const archiveCommunity = (communityId) => async (dispatch, getState) => {
  const { page } = getState().communities;
  dispatch(setSubmitting(true));
  try {
    await apiPatch(communityId, { archived: true });
    dispatch(notifySuccess('community is archived'));
    dispatch(loadPage(page.offset, page.limit));
  } catch (error) {
    dispatch(notifyError(`Failed to archive community ${communityId}`));
  } finally {
    dispatch(setSubmitting(false));
  }
};

export const unArchiveCommunity = (communityId) => async (dispatch, getState) => {
  const { page } = getState().communities;
  dispatch(setSubmitting(true));
  try {
    await apiPatch(communityId, { archived: false });
    dispatch(notifySuccess('community is unarchived'));
    dispatch(loadPage(page.offset, page.limit));
  } catch (error) {
    dispatch(notifyError(`Failed to unarchive community ${communityId}`));
  } finally {
    dispatch(setSubmitting(false));
  }
};

export const isCommunityEditingSelector = (state) =>
  state.communities.modal.isNew || state.communities.modal.editedCommunity != null;
