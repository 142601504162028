import React, { PropsWithChildren, useMemo, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Modal,
  TextField as MUITextField,
} from '@material-ui/core';
import { AutocompleteOptionSelector } from '../../../components/AutocompleteOptionSelector';
import { IResident } from '../../../app/domain/IResident';
import { IKeypadViewItemValue } from './IKeypadViewItem';
import { TextField } from '../../../components/TextField';
import joi, { AnySchema } from 'joi';

const pincode = joi.string().regex(/^\d{4}$/);

function useValidator<T>(validator: AnySchema, value: T) {
  return useMemo(() => validator.validate(value).error?.message, [validator, value]);
}

export interface IKeypadFormDialogResident {
  slug: string;
  name: string;
  id: string;
  pending: boolean;
}

export class KeypadFormDialogResident implements IKeypadFormDialogResident {
  public static isPending(slug: string): boolean {
    const [, type] = slug.split('-');
    return type === 'invitation';
  }

  id: string;
  name: string;
  slug: string;

  constructor(resident: IResident, public pending: boolean = false) {
    this.id = resident.id;
    this.name = resident.name;
    this.slug = `${resident.id}-${pending ? 'invitation' : 'resident'}`;
  }

  static getId(value: string): string {
    const [id] = value.split('-');
    return id;
  }
}

export function KeypadFormDialog({
  onClose,
  onSubmit,
  residents,
  disableCode,
  onGenerateCode,
}: PropsWithChildren<
  {
    onClose: () => void;
    onGenerateCode: () => Promise<string>;
    onSubmit: (keypad: string, id: string, pending?: boolean) => void;
    residents: IKeypadFormDialogResident[];
    disableCode?: boolean;
  } & Partial<IKeypadViewItemValue>
>): JSX.Element {
  const classes = useStyles();
  const [keypadCode, setCode] = useState('');
  const keypadError = useValidator(pincode, keypadCode);
  const [selectedResident, setResident] = useState<string | null>(null);
  const hasErrors = keypadError;
  return (
    <Modal open onClose={onClose}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (hasErrors) {
            return;
          }
          onSubmit(
            keypadCode,
            KeypadFormDialogResident.getId(selectedResident!),
            KeypadFormDialogResident.isPending(selectedResident!),
          );
        }}
      >
        <Card className={classes.root}>
          <CardHeader title="Add keypad" />
          <Divider />
          <CardContent>
            <Grid direction="row" container spacing={3}>
              <Grid item md={12} xs={12}>
                <AutocompleteOptionSelector
                  value={selectedResident}
                  groupBy={(option) => (option as any).type}
                  options={residents.map((r) => ({
                    id: r.slug,
                    label: r.name,
                    type: r.pending ? 'pending' : 'members',
                  }))}
                  onChange={(value) => setResident(value)}
                >
                  {(params) => <MUITextField {...params} required label="Resident" variant="outlined" />}
                </AutocompleteOptionSelector>
              </Grid>
            </Grid>
            <Grid direction="row" container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  label="Code"
                  required
                  error={keypadError}
                  disabled={disableCode}
                  value={keypadCode}
                  onChange={(value) => setCode(value)}
                />
                <Button
                  color="primary"
                  variant="contained"
                  disabled={disableCode}
                  onClick={async () => setCode(await onGenerateCode())}
                >
                  Generate
                </Button>
              </Grid>
            </Grid>
          </CardContent>

          <Divider />

          <CardActions className={classes.actions}>
            <Button onClick={onClose}>Close</Button>
            <Button type="submit">Submit</Button>
          </CardActions>
        </Card>
      </form>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));
