import { createSlice } from '@reduxjs/toolkit';
import { loadInvoices as loadInvoicesApi } from '../../api/gatewise_api';
import { getInvoicePaymentStatus, IInvoice, InvoicePaymentStatus } from './IInvoice';
import { AppThunk, RootState } from '../../app/store';
import { notifyError } from '../notifications/notificationsSlice';
import { currency } from '../../utils/intl';

type InvoicePageState = {
  page: {
    items: IInvoice[];
    page: number;
    limit: number;
    total: number;
  };
};

const initialState: InvoicePageState = {
  page: {
    items: [],
    page: 1,
    limit: 10,
    total: 0,
  },
};

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setPage: (state, action) => {
      const { page } = action.payload;
      state.page = page;
    },
  },
});

export const { setPage } = invoicesSlice.actions;

export default invoicesSlice.reducer;

export const loadInvoices =
  (communityId: string, page: number, limit: number): AppThunk<void> =>
  async (dispatch) => {
    try {
      const { items, total } = await loadInvoicesApi(communityId, (page - 1) * limit + 1, limit);
      dispatch(
        setPage({
          page: {
            items,
            page,
            limit,
            total,
          },
          loaded: true,
          error: null,
        }),
      );
    } catch (error) {
      notifyError(error);
    }
  };

export const selectBillingAlerts = (state: RootState) =>
  state.invoices.page.items
    .filter((it) => getInvoicePaymentStatus(it) === InvoicePaymentStatus.Unpaid)
    .map((it) => `Invoice ${currency(it.amount)} for ${it.date} is unpaid`);
