import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCommunityDetails, patchCommunity } from '../../api/gatewise_api';
import { notifyError, notifySuccess } from '../notifications/notificationsSlice';
import { errorToString } from '../../utils/errors';
import { AppDispatch } from '../../app/store';
import { ICommunity, ICommunityValue } from '../../app/domain/ICommunity';

interface ICommunityDetailsState {
  details?: ICommunity;
  loading: boolean;
  error: unknown | null;
}

const initialState: ICommunityDetailsState = {
  loading: false,
  error: null,
};

const { reducer, actions } = createSlice({
  name: 'communityDetails',
  initialState,
  reducers: {
    setCommunity: (state, action: PayloadAction<ICommunity | undefined>) => {
      state.details = action.payload;
    },
    updateCommunity: (state, action: PayloadAction<ICommunity>) => {
      state.details = {
        ...state.details,
        ...action.payload,
      };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

const { setCommunity, setLoading, setError } = actions;

export default reducer;

export const loadCommunityDetails = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const details = await getCommunityDetails(id);
    dispatch(setCommunity(details));
  } catch (e) {
    dispatch(setError(errorToString(e)));
    notifyError('Failed to load community details');
  } finally {
    dispatch(setLoading(false));
  }
};

export const resetCommunityDetails = () => async (dispatch: AppDispatch) => {
  dispatch(setCommunity(undefined));
};

export const updateCommunity = (id: string, payload: Partial<ICommunityValue>) => async (dispatch: AppDispatch) => {
  try {
    const details = await patchCommunity(id, payload);
    dispatch(actions.updateCommunity(details));
    dispatch(notifySuccess('Saved'));
  } catch (e) {
    dispatch(notifyError(e));
  }
};
