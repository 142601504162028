import { createSlice } from '@reduxjs/toolkit';
import { AppSelector } from '../../app/store';

type Notification = {
  type: 'success' | 'error';
  message: null | string;
};

type NotificationState = {
  last: Notification | null;
};

const initialState: NotificationState = {
  last: null,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    notifySuccess: (state, action) => {
      state.last = {
        type: 'success',
        message: action.payload,
      };
    },
    notifyError: (state, action) => {
      state.last = {
        type: 'error',
        message: action.payload.toString(),
      };
    },
    clearNotifications: (state) => {
      state.last = null;
    },
  },
});

export const { notifySuccess, notifyError, clearNotifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;

export const getLastNotification: AppSelector<Notification | null> = (state) => state.notifications.last;
