import LoadingOverlay from 'react-loading-overlay';
import CardMedia from '@material-ui/core/CardMedia';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import React, { createContext, PropsWithChildren, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useContextOrFail } from '../ContainerContext';
import { ImageManager } from '../api/ImageManager';
import clsx from 'clsx';

export const ImageManagerContext = createContext<ImageManager | undefined>(undefined);

// react-loading-overlay bug https://github.com/derrickpelletier/react-loading-overlay/pull/57
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
LoadingOverlay.propTypes = undefined;

export function ImageUploadButton({
  value: initialValue,
  onChange,
  className,
}: PropsWithChildren<{
  value: string | undefined;
  onChange: (url: string) => void;
  className?: string;
}>): JSX.Element {
  const classes = useStyles();
  const fileInput = useRef<HTMLInputElement>(null);
  const imageManager = useContextOrFail(ImageManagerContext);
  const [value, setValue] = useState<string | null>(initialValue ?? null);
  const [isUploading, setUploading] = useState(false);
  return (
    <LoadingOverlay active={isUploading} spinner text="Loading..." fadeSpeed={250}>
      <div className={clsx(classes.mediaContainer, className)}>
        <div className={classes.addIcon}>
          {value && <CardMedia className={classes.media} image={value} title="Community Image" />}
        </div>
        <div className={classes.addIcon} onClick={() => fileInput.current?.click()}>
          <AddCircleOutlineIcon fontSize="large" />
        </div>
      </div>
      <input
        onClick={(e) => {
          (e.target as HTMLInputElement).value = '';
        }}
        id="input"
        type="file"
        ref={fileInput}
        style={{ display: 'none' }}
        onChange={async ({ target }) => {
          try {
            setUploading(true);
            const url = await imageManager.uploadImage(target.files?.[0] as any);
            setValue(url);
            onChange(url);
          } finally {
            setUploading(false);
          }
        }}
        accept=".png, .jpeg, .jpg"
      />
    </LoadingOverlay>
  );
}

const useStyles = makeStyles(() => ({
  media: {
    height: 150,
    width: 650,
  },
  mediaContainer: {
    height: 150,
    display: 'flex',
    width: '100%',
  },
  addIcon: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    backgroundColor: 'transparent',
    height: 150,
    width: '100%',
  },
}));
