import { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';

import { IAvailabilitySchedule } from './ScheduleV2';
import { ITimeOption } from './ScheduleItem';

export const useOnEscape = (cb: () => void): void => {
  const onEsc = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        cb();
      }
    },
    [cb],
  );

  useEffect(() => {
    document.addEventListener('keydown', onEsc, false);
    return () => document.removeEventListener('keydown', onEsc);
  }, [onEsc]);
};

export function useAutoRefresh(time: number): [boolean, (value: boolean) => void, Date] {
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  useEffect(() => {
    if (autoRefresh) {
      const interval = setInterval(() => setCurrentTime(new Date()), time);
      return () => clearInterval(interval);
    }
    return () => {};
  }, [autoRefresh, time]);
  return [autoRefresh, setAutoRefresh, currentTime];
}

/** Schedule utils **/

const dayToDayString = (day: number): string => {
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return dayNames[day];
};

export const getOverlappingDays = (schedules: IAvailabilitySchedule[]): string[] => {
  const overlappingDays = new Set<string>();

  for (let i = 0; i < schedules.length; i++) {
    const schedule = schedules[i];

    for (let j = 0; j < schedule.days.length; j++) {
      const day = schedule.days[j];
      const [startDate, endDate] = getStartEndDate(day, schedule.hours.start, schedule.hours.durationMinutes);

      const nextSchedule = schedules[i + 1];
      if (!nextSchedule) return Array.from(overlappingDays);

      for (let k = 0; k < nextSchedule.days.length; k++) {
        const nextDay = nextSchedule.days[k];
        const isSameDay = nextDay === day;
        const isConsecutiveDays = (nextDay === 0 && day === 6) || day + 1 === nextDay;

        if (!isSameDay && !isConsecutiveDays) {
          // overlap can only be on the same day or the next day (since duration is up to 24 hours)
          continue;
        }

        const [nextStartDate, nextEndDate] = getStartEndDate(
          nextDay,
          nextSchedule.hours.start,
          nextSchedule.hours.durationMinutes,
        );

        if (startDate < nextEndDate && endDate > nextStartDate) {
          overlappingDays.add(dayToDayString(day));
        }
      }
    }
  }

  return Array.from(overlappingDays);
};

const getStartEndDate = (day: number, startHour: string, durationMinutes: number): [Date, Date] => {
  // 2023-10 because 1st of October 2023 is a Sunday - it makes it easier to compare them later on
  const startDate = new Date(`2023-10-0${day}T${startHour}`);
  const endDate = new Date(startDate.getTime() + durationMinutes * 60000);
  return [startDate, endDate];
};

export const isScheduleValid = (schedule: IAvailabilitySchedule): boolean => {
  return schedule.days.length > 0 && schedule.hours.start.length > 0 && schedule.hours.durationMinutes > 0;
};

export const getFormattedEndTime = (startTime: string, durationMinutes: number): string => {
  if (startTime && durationMinutes) {
    return format(new Date(new Date(`2023-01-01T${startTime}:00`).getTime() + durationMinutes * 60000), 'HH:mm');
  }
  return '';
};

export const getDaysRangeString = (days: number[]): string => {
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const theDays = days.map((day) => ({ value: day, label: dayNames[day] }));
  const sortedDays = theDays.sort((a, b) => dayNames.indexOf(a.label) - dayNames.indexOf(b.label));

  const ranges = [];
  for (let i = 0; i < sortedDays.length; i++) {
    const start = sortedDays[i];
    while (
      i < sortedDays.length - 1 &&
      dayNames.indexOf(sortedDays[i].label) + 1 === dayNames.indexOf(sortedDays[i + 1].label)
    ) {
      i++;
    }
    const end = sortedDays[i];
    if (start.label === end.label || dayNames.indexOf(start.label) + 1 === dayNames.indexOf(end.label)) {
      ranges.push(start.label);
      if (start.label !== end.label) {
        ranges.push(end.label);
      }
    } else {
      ranges.push(`${start.label} - ${end.label}`);
    }
  }
  return ranges.join(', ');
};

export const generateTimeOptions = (isEndTime: boolean, startTime: string): ITimeOption[] => {
  const times: ITimeOption[] = [];
  const nextDayTimes: ITimeOption[] = [];

  for (let hour = 0; hour < 24; hour++) {
    for (let min = 0; min < 60; min += 30) {
      const timeValue = `${hour.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}`;
      const formattedTime = format(new Date(`2023-01-01T${timeValue}:00`), 'h:mm a');
      let displayValue = `${isEndTime ? 'To' : 'From'} ${formattedTime}`;

      if (isEndTime && startTime && timeValue <= startTime) {
        displayValue += ' (next day)';
        nextDayTimes.push({ displayValue: displayValue, value: timeValue });
      } else {
        times.push({ displayValue: displayValue, value: timeValue });
      }
    }
  }

  return times.concat(nextDayTimes);
};

export const getInitialSchedule = (id: number): IAvailabilitySchedule => ({
  id,
  days: [],
  hours: { start: '', durationMinutes: 0 },
});
/** Schedule utils **/
